import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AddCandidateService from "../service/AddCandidateService";
import { IAddCandidateRepository } from "@/domain/interviewer/add-candidate/repository/IAddCandidateRepository";
import { AddCandidateRequestDto } from "@/domain/interviewer/add-candidate/dto/AddCandidateRequestDto";

const addCandidateService = new AddCandidateService();

export class AddCandidateRepository implements IAddCandidateRepository {
  async addCandidate(
    request: AddCandidateRequestDto
  ): Promise<Result<any>> {
    try {
      return await addCandidateService
        .invite(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.ADD_CANDIDATE))
      );
    }
  }
}
