import { BaseDomain } from "@/core/base/BaseDomain";

export class JobPostingDetailByInviteModel extends BaseDomain {
  key?: string;
  jobPostingTitle?: string;
  interviewTitle?: string;
  interviewQuestionCount?: number;
  candidateFullName?: string;
  candidateEmail?: string;

  constructor(
    key?: string,
    jobPostingTitle?: string,
    interviewTitle?: string,
    interviewQuestionCount?: number,
    candidateFullName?: string,
    candidateEmail?: string,
  ) {
    super();
    this.key = key;
    this.jobPostingTitle = jobPostingTitle;
    this.interviewTitle = interviewTitle;
    this.interviewQuestionCount = interviewQuestionCount;
    this.candidateFullName = candidateFullName;
    this.candidateEmail = candidateEmail;
  }
}
