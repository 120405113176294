import { BaseDomain } from "@/core/base/BaseDomain";

export class AddQuestionModel extends BaseDomain {
  interview?: number;
  questionText?: string;
  questionType?: string;
  interviewQuestionOptions?: { optionText: string }[];
  ok?: boolean;

  constructor(
    interview?: number,
    questionText?: string,
    questionType?: string,
    interviewQuestionOptions?: { optionText: string }[],
    ok?: boolean,
  ) {
    super();
    this.interview = interview;
    this.questionText = questionText;
    this.questionType = questionType;
    this.interviewQuestionOptions = interviewQuestionOptions;
    this.ok = ok;
  }
}
