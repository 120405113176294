import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { AboutUserResponseDto } from "@/domain/user/about-user/dto/AboutUserResponseDto";
import { AboutUserModel } from "@/domain/user/about-user/model/AboutUserModel";
import { TenantEstateMapper } from "../../tenant-estate/mapper/TenantEstateMapper";
import { TenantEstateModel } from "@/domain/user/tenant-estate/model/TenantEstateModel";

export class AboutUserMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AboutUserResponseDto): Result<AboutUserModel> {
    try {
      const tenantUserMapper: TenantEstateMapper = new TenantEstateMapper();
      const activeTenantEstate: TenantEstateModel = tenantUserMapper.mapToDomain(
        dto.data.attributes.activeTenantEstate
      ) as TenantEstateModel;
      const tenantEstates: TenantEstateModel[] = tenantUserMapper.mapToDomain(
        dto.data.attributes.tenantEstates
      ) as TenantEstateModel[];

      const aboutUserModel: AboutUserModel = {
        id: dto.data.id !== undefined ? dto.data.id : 0,
        username: dto.data.attributes.username,
        email: dto.data.attributes.email,
        name: dto.data.attributes.name,
        surname: dto.data.attributes.surname,
        phoneNumber: dto.data.attributes.phoneNumber,
        remainingQuota: dto.data.attributes.remainingQuota,
        activeTenantEstate: activeTenantEstate,
        tenantEstates: tenantEstates,
        countryCode: dto.data.attributes.countryCode.data.attributes.code
      };

      return Result.ok(aboutUserModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ABOUT_USER))
      );
    }
  }

  mapToDTO(domain: AboutUserModel): Result<AboutUserModel> {
    try {
      return Result.ok(domain);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ABOUT_USER))
      );
    }
  }
}
