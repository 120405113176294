import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { ProductListUseCase } from "@/application/product/product-list/usecase/ProductListUseCase";
import { ProductListModel } from "@/domain/product/product-list/model/ProductListModel";
import { BuyProductUsecase } from "@/application/product/buy-product/usecase/BuyProductUseCase";
import { BuyProductModel } from "@/domain/product/buy-product/model/BuyProductModel";
import { UpdateProductModelSettingUseCase } from "@/application/product/update-product-model-setting/usecase/UpdateProductModelSettingUseCase";
import { UpdateProductModelSettingModel } from "@/domain/product/update-product-model-setting/model/UpdateProductModelSettingModel";
import { GetPurchasedProductsUseCase } from "@/application/product/get-purchased-products/usecase/GetPurchasedProductsUseCase";
import { GetProductModel } from "@/domain/product/get-product/model/GetProductModel";
import { GetProductUseCase } from "@/application/product/get-product/usecase/GetProductUseCase";

export function createProductController() {
  const useCases = {
    productList: {
      useCase: new ProductListUseCase(),
      model: ProductListModel,
    },
    buyProduct: {
      useCase: new BuyProductUsecase(),
      model: BuyProductModel,
    },
    getProduct: {
      useCase: new GetProductUseCase(),
      model: GetProductModel,
    },
    updateProductModelSetting: {
      useCase: new UpdateProductModelSettingUseCase(),
      model: UpdateProductModelSettingModel,
    },
    getPurchasedProducts: {
      useCase: new GetPurchasedProductsUseCase(),
      model: ProductListModel,
    },
  };

  return new ProductController(useCases);
}

export class ProductController implements IController{
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
