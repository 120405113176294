import { BaseDTO } from "@/core/base/BaseDTO";

export class GenerateOTPRequestDto extends BaseDTO {
  phoneNumber?: string;
  countryCode?: number;
  locale?: string;

  constructor(phoneNumber?: string, countryCode?: number, locale?: string) {
    super();
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
    this.locale = locale;
  }

  toQueryString(): string {
    const queryString = `?locale=${this.locale}`;

    return queryString;
  }
}
