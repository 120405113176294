
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { UpdateQuestionRepository } from "../repository/UpdateQuestionRepository";
import { UpdateQuestionMapper } from "../mapper/UpdateQuestionMapper";
import { IUpdateQuestionUseCase } from "@/domain/interviewer/update-interview/updateQuestion/usecase/IUpdateQuestionUseCase";
import { IUpdateQuestionRepository } from "@/domain/interviewer/update-interview/updateQuestion/repository/IUpdateQuestionRepository";
import { UpdateQuestionModel } from "@/domain/interviewer/update-interview/updateQuestion/model/UpdateQuestionModel";

export class UpdateQuestionUseCase implements IUpdateQuestionUseCase {
  updateJobPostingRepository: IUpdateQuestionRepository = new UpdateQuestionRepository();
  updateJobPostingMapper = new UpdateQuestionMapper();

  constructor() {}

  async execute(model: UpdateQuestionModel): Promise<Result<UpdateQuestionModel>> {
    try {
      const dto = this.updateJobPostingMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateQuestionModel>(dto.getError());
      } else {
        const response = await this.updateJobPostingRepository.updateQuestion(dto);

        if (response.isSuccess) {
          const domain = this.updateJobPostingMapper.mapToDomain(response.getValue());

          return Result.ok<UpdateQuestionModel>(domain.getValue());
        } else {
          return Result.fail<UpdateQuestionModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateQuestionModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.UPDATE_INTERVIEW_QUESTION))
      );
    }
  }
}
