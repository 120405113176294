import { BaseDTO } from "@/core/base/BaseDTO";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";

export class GetPurchasedProductsRequestDto extends BaseDTO {
  pagination?: PaginationRequestDto;
  locale: string;

  constructor(
    locale: string,
    pagination?: PaginationRequestDto,
  ) {
    super();
    this.pagination = pagination;
    this.locale = locale
  }

  toQueryString(): string {
    let queryString = `?locale=${this.locale}`;

    if (this.pagination) {
      queryString += `&pagination[page]=${this.pagination.pagination.page}&pagination[pageSize]=${this.pagination.pagination.pageSize}`;
    }

    return queryString;
  }
}
