import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { CreateJobPostingModel } from "@/domain/interviewer/create-job-posting/model/CreateJobPostingModel";
import { CreateJobPostingRequestDto } from "@/domain/interviewer/create-job-posting/dto/CreateJobPostingRequestDto";

export class CreateJobPostingMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<CreateJobPostingModel> {
    try {
      const createJobPostingModel: CreateJobPostingModel = {
        ok: dto.ok,
      };

      return Result.ok(createJobPostingModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CREATE_JOBPOSTING))
      );
    }
  }

  mapToDTO(domain: CreateJobPostingModel): Result<CreateJobPostingRequestDto> {
    try {

      const createJobPostingRequest = new CreateJobPostingRequestDto(
        domain.title,
        domain.description,
        domain.jobPostingQualifications,
        domain.startDate,
        domain.endDate
      );

      return Result.ok(createJobPostingRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CREATE_JOBPOSTING))
      );
    }
  }
}
