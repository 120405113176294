import { BaseDTO } from "@/core/base/BaseDTO";

export class UpdateProductModelSettingRequestDto extends BaseDTO {
  data: {
    productModelSetting: number;
  };
  productId: number;

  constructor(productModelSetting: number, productId: number) {
    super();
    this.data = {
      productModelSetting,
    };
    this.productId = productId;
  }
}
