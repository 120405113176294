import { BaseDomain } from "@/core/base/BaseDomain";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";

export class UpdateJobPostingModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  interviews?: InterviewSequenceModel[];
  ok?: boolean;
  // completed?: boolean;

  constructor(
    interviews?: InterviewSequenceModel[],
    id?: number,
    title?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    ok?: boolean,
    // completed?: boolean
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.interviews = interviews;
    this.ok = ok;
    // this.completed = completed;
  }
}
