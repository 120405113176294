import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateUserRequestDto } from "@/domain/user/update-user/dto/UpdateUserRequestDto";
import { UpdateUserModel } from "@/domain/user/update-user/model/UpdateUserModel";

export class UpdateUserMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateUserModel> {
    try {
      const updateUserModel: UpdateUserModel = {
        ok: dto.ok,
      };

      return Result.ok(updateUserModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.UPDATE_USER))
      );
    }
  }

  mapToDTO(domain: UpdateUserModel): Result<UpdateUserRequestDto> {
    try {
      const updateUserRequest = new UpdateUserRequestDto(
        domain.name,
        domain.surname,
        domain.email,
        domain.tenantName
      );

      return Result.ok(updateUserRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.UPDATE_USER))
      );
    }
  }
}
