import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class StaffListModel extends BaseDomain {
  staff: {
    id?: number;
    name?: string;
    surname?: string;
    email?: string;
    birthdate?: Date;
    department?: string;
    position?: string;
    countryCodeNumber?: string;
    phoneNumber?: number;
    isActive?: boolean;
    role?: string;
  }
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  
  constructor(
    id?: number,
    name?: string,
    surname?: string,
    email?: string,
    birthdate?: Date,
    department?: string,
    position?: string,
    countryCodeNumber?: string,
    phoneNumber?: number,
    isActive?: boolean,
    role?: string,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.staff = {
      id,
      name,
      surname,
      email,
      birthdate,
      department,
      position,
      countryCodeNumber,
      phoneNumber,
      isActive,
      role
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
