import { BaseDTO } from "@/core/base/BaseDTO";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";

export class CreateInterviewRequestDto extends BaseDTO {
  data: {
    jobPosting?: number;
    title?: string;
    description?: string;
    interviewQuestions?: InterviewQuestionModel[];
    interviewSequences?: InterviewSequenceModel[];
  }
  constructor(
    jobPosting?: number,
    title?: string,
    description?: string,
    interviewQuestions?: InterviewQuestionModel[],
    interviewSequences?: InterviewSequenceModel[],
  ) {
    super();
    this.data = {
      jobPosting,
      title,
      description,
      interviewQuestions,
      interviewSequences
    }
  }
}
