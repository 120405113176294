import { BaseDTO } from "@/core/base/BaseDTO";

export class CreateJobPostingRequestDto extends BaseDTO {
  data: {
    title?: string;
    description?: string;
    jobPostingQualifications?: string[];
    startDate?: Date;
    endDate?: Date;
  }
  constructor(
    title?: string,
    description?: string,
    jobPostingQualifications?: string[],
    startDate?: Date,
    endDate?: Date,
  ) {
    super();
    this.data = {
      title,
      description,
      jobPostingQualifications,
      startDate,
      endDate
    }
  }
}
