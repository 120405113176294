import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import CountryListService from "../service/CountryListService";
import { ICountryListRepository } from "@/domain/country/country-list/repository/ICountryListRepository";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { CountryListResponseDto } from "@/domain/country/country-list/dto/CountryListResponseDto";
import { CountryListRequestDto } from "@/domain/country/country-list/dto/CountryListRequestDto";

const countryListService = new CountryListService();

export class CountryListRepository implements ICountryListRepository {
  async countryList(
    request: CountryListRequestDto
  ): Promise<Result<CountryListResponseDto>> {
    try {
      return await countryListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<CountryListResponseDto>(response.getValue());
          } else {
            return Result.fail<CountryListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<CountryListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.COUNTRY_LIST))
      );
    }
  }
}
