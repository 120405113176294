import { BadRequestCause } from "@/core/cause/BadRequestCause";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { Result } from "@/core/utility/ResultType";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ChangeTenantEstateRequestDto } from "@/domain/auth/change-tenant-estate/dto/ChangeTenantEstateRequestDto";
import { ChangeTenantEstateResponseDto } from "@/domain/auth/change-tenant-estate/dto/ChangeTenantEstateResponseDto";
import { IChangeTenantEstateService } from "@/domain/auth/change-tenant-estate/service/IChangeTenantEstateService";
import instance from "@/infrastructure/network/axios/AxiosService";
import i18n from "@/presentation/language/i18n";

export default class ChangeTenantEstateService
  implements IChangeTenantEstateService
{
  async changeTenantEstate(
    request: ChangeTenantEstateRequestDto
  ): Promise<Result<ChangeTenantEstateResponseDto>> {
    try {
      return await instance
        .post<ChangeTenantEstateResponseDto>(
          API_ROUTE.CHANGE_TENANT_ESTATE,
          request
        )
        .then((response) => {
          return Result.ok<ChangeTenantEstateResponseDto>(response.data);
        })
        .catch((error) => {
          return Result.fail<ChangeTenantEstateResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_CHANGE_TENANT_USER)))
          );
        });
    } catch (error) {
      return Result.fail<ChangeTenantEstateResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.CHANGE_TENANT_ESTATE))
      );
    }
  }
}
