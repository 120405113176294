export const COMPONENT_NAME = {
    APP: "app",
    BUILDER: "builder",
    LAYOUT: "Layout",
    TOPBAR: "topbar",
    MIXED: "widgets-mixed",
    STATISTICS: "widgets-statistics",
    TABLES: "widgets-tables",
    SEARCH: "search",
    UPGRADE_TO_PRO: "upgrade-to-pro",
    LOADER: "Loader",
    MODAL_CARD: "modal-card",
    INVOICES: "invoices",
    KT_EXPLORE: "kt-explore",
    KT_SCROLL_TOP: "KTScrollTop",
    KT_STICKY_TOOLBAR: "KTStickyToolbar",
    KT_FOOTER: "KTFooter",
    KT_HEADER: "KTHeader",
    KT_TOOLBAR: "KToolbar",
    KT_ASIDE: "KTAside",
    KT_ASIDE_MENU: "kt-menu",
    KT_MENU: "KTMenu",
    KT_ACTIVITY_DRAWER: "kt-activity-drawer",
    NOTIFICATIONS_MENU: "notifications-menu",
    KT_QUICK_LINKS_MENU: "kt-quick-links-menu",
    KT_USERS_MENU: "kt-user-menu",
  };