import { VALUE } from "@/domain/constants/Value";
import * as crypto from "crypto";
import { CryptoKeyService } from "./CryptoKeyService";

const algorithm = "aes-256-cbc"; // AES algoritması

export class AES256EncryptionService {
  static encrypt(text: string | null): string {
    const key = CryptoKeyService.getKeyFromStorage();
    const iv = CryptoKeyService.getIvFromStorage();

    if (text && key && iv) {
      const cipher = crypto.createCipheriv(algorithm, key, iv);
      let encrypted = cipher.update(text, "utf-8", "hex");
      encrypted += cipher.final("hex");
      return encrypted;
    }
    return VALUE.NULL_STRING;
  }

  static decrypt(encryptedText: string | null): string {
    const key = CryptoKeyService.getKeyFromStorage();
    const iv = CryptoKeyService.getIvFromStorage();

    if (encryptedText && key && iv) {
      try {
        const decipher = crypto.createDecipheriv(algorithm, key, iv);
        let decrypted = decipher.update(encryptedText, "hex", "utf-8");
        decrypted += decipher.final("utf-8");

        // Check if decrypted string is JSON formatted
        try {
          const parsedData = JSON.parse(decrypted);
          if (typeof parsedData === 'string') {
            return parsedData;
          }
        } catch (error) {
          console.error("Decryption parsing error:", error);
        }

        // If parsing fails or not JSON, return decrypted as is
        return decrypted;
      } catch (error) {
        console.error("Decryption error:", error);
        return VALUE.NULL_STRING;
      }
    }
    return VALUE.NULL_STRING;
  }
}
