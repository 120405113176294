
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IJobPostingDetailByInviteUseCase } from "@/domain/interviewer/job-posting-detail-by-invite-key/usecase/IJobPostingDetailByInviteUseCase";
import { IJobPostingDetailByInviteRepository } from "@/domain/interviewer/job-posting-detail-by-invite-key/repository/IJobPostingDetailByInviteRepository";
import { JobPostingDetailByInviteRepository } from "../repository/JobPostingDetailByInviteRepository";
import { JobPostingDetailByInviteMapper } from "../mapper/JobPostingDetailByInviteMapper";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";

export class JobPostingDetailByInviteUseCase implements IJobPostingDetailByInviteUseCase {
  getJobPostingDetailByInviteRepository : IJobPostingDetailByInviteRepository = new JobPostingDetailByInviteRepository();
  getJobPostingDetailByInviteMapper = new JobPostingDetailByInviteMapper();

  constructor() {
  }

  async execute(model: JobPostingDetailByInviteModel): Promise<Result<JobPostingDetailByInviteModel>> {
    try {
      const dto = this.getJobPostingDetailByInviteMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<JobPostingDetailByInviteModel>(dto.getError());
      } else {
        const response = await this.getJobPostingDetailByInviteRepository.getJobPostingDetailByInvite(dto);

        if (response.isSuccess) {
          const domain = this.getJobPostingDetailByInviteMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<JobPostingDetailByInviteModel>(domain.getValue());
        } else {
          return Result.fail<JobPostingDetailByInviteModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<JobPostingDetailByInviteModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.JOBPOSTING_DETAIL_BY_INVITE))
      );
    }
  }
}
