import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { ICandidateCvAnalysisService } from "@/domain/interviewer/candidate-cv-analysis/service/ICandidateCvAnalysisService";
import { CandidateCvAnalysisRequestDto } from "@/domain/interviewer/candidate-cv-analysis/dto/CandidateCvAnalysisRequestDto";

export default class CandidateCvAnalysisService implements ICandidateCvAnalysisService {
  async create(
    request: CandidateCvAnalysisRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .post(API_ROUTE.CANDIDATE_CV_ANALYSIS, request)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_CV_ANALYSIS)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.CANDIDATE_CV_ANALYSIS))
      );
    }
  }
}
