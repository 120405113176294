import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IPollResponseListService } from "@/domain/pollster/poll-statistic/poll-response-list/service/IPollResponseListService";
import { PollResponseListRequestDto } from "@/domain/pollster/poll-statistic/poll-response-list/dto/PollResponseListRequestDto";
import { PollResponseListResponseDto } from "@/domain/pollster/poll-statistic/poll-response-list/dto/PollResponseListResponseDto";

export default class PollResponseListService
  implements IPollResponseListService
{
  async get(
    request: PollResponseListRequestDto
  ): Promise<Result<PollResponseListResponseDto>> {
    try {
      return await instance
        .get<PollResponseListResponseDto>(
          API_ROUTE.SURVEYS_SESSIONS + request.id + API_ROUTE.RESPONSES
        )
        .then((response) => {
          return Result.ok<PollResponseListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<PollResponseListResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(
                  SWAL_MESSAGES.ERROR.ERROR_POLL_STATISTICS.RESPONSE_LIST
                )
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<PollResponseListResponseDto>(
        new ServiceException(
          new UnknownCause(SERVICE.POLLSTER.STATISTIC.POLL_RESPONSE_LIST)
        )
      );
    }
  }
}
