import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import UpdateInterviewService from "../service/UpdateInterviewService";
import { IUpdateInterviewRepository } from "@/domain/interviewer/update-interview/repository/IUpdateInterviewRepository";
import { UpdateInterviewRequestDto } from "@/domain/interviewer/update-interview/dto/UpdateInterviewRequestDto";

const updateInterviewService = new UpdateInterviewService();

export class UpdateInterviewRepository implements IUpdateInterviewRepository {
  async updateInterview(request: UpdateInterviewRequestDto): Promise<Result<any>> {
    try {
      return await updateInterviewService
        .update(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.UPDATE_INTERVIEW)));
    }
  }
}
