import { BaseDomain } from "@/core/base/BaseDomain";

export class PaginationModel extends BaseDomain {
  page?: number;
  pageSize?: number;
  constructor(page?: number, pageSize?: number) {
    super();
    this.page = page;
    this.pageSize = pageSize;
  }
}
