import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { AxiosInstance } from "axios";

const jwtTokenManager = new JwtTokenManager();

export const AuthInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      if (config.headers?.disableAuth) {
        delete config.headers.disableAuth; 
        return config; 
      }

      const token = await jwtTokenManager.getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
