
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { CandidateCvAnalysisMapper } from "../mapper/CandidateCvAnalysisMapper";
import { CandidateCvAnalysisRepository } from "../repository/CandidateCvAnalysisRepository";
import { ICandidateCvAnalysisUseCase } from "@/domain/interviewer/candidate-cv-analysis/usecase/ICandidateCvAnalysisUsecase";
import { ICandidateCvAnalysisRepository } from "@/domain/interviewer/candidate-cv-analysis/repository/ICandidateCvAnalysisRepository";
import { CandidateCvAnalysisModel } from "@/domain/interviewer/candidate-cv-analysis/model/CandidateCvAnalysisModel";

export class CandidateCvAnalysisUsecase implements ICandidateCvAnalysisUseCase {
  candidateCvAnalysisRepository : ICandidateCvAnalysisRepository = new CandidateCvAnalysisRepository();
  candidateCvAnalysisMapper = new CandidateCvAnalysisMapper();

  constructor() {
  }

  async execute(model: CandidateCvAnalysisModel): Promise<Result<CandidateCvAnalysisModel>> {

    try {
      const dto = this.candidateCvAnalysisMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<CandidateCvAnalysisModel>(dto.getError());
      } else {
        const response = await this.candidateCvAnalysisRepository.candidateCvAnalysis(dto);

        if (response.isSuccess) {
          const domain = this.candidateCvAnalysisMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CandidateCvAnalysisModel>(domain.getValue());
        } else {
          return Result.fail<CandidateCvAnalysisModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateCvAnalysisModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_CV_ANALYSIS))
      );
    }
  }
}
