import { UpdatePollRepository } from "../repository/UpdatePollRepository";
import { UpdatePollMapper } from "../mapper/UpdatePollMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IUpdatePollUseCase } from "@/domain/pollster/update-poll/usecase/IUpdatePollUseCase";
import { UpdatePollModel } from "@/domain/pollster/update-poll/model/UpdatePollModel";

export class UpdatePollUseCase implements IUpdatePollUseCase {
  updatePollRepository: UpdatePollRepository = new UpdatePollRepository();
  updatePollMapper = new UpdatePollMapper();

  constructor() {}

  async execute(model: UpdatePollModel): Promise<Result<UpdatePollModel>> {
    try {
      const dto = this.updatePollMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdatePollModel>(dto.getError());
      } else {
        const response = await this.updatePollRepository.updatePoll(dto);

        if (response.isSuccess) {
          const domain = this.updatePollMapper.mapToDomain(response.getValue());

          return Result.ok<UpdatePollModel>(domain.getValue());
        } else {
          return Result.fail<UpdatePollModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdatePollModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.UPDATE_POLL))
      );
    }
  }
}
