import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IGetPurchasedProductsRepository } from "@/domain/product/get-purchased-products/repository/IGetPurchasedProductsRepository";
import { GetPurchasedProductsRequestDto } from "@/domain/product/get-purchased-products/dto/GetPurchasedProductsRequestDto";
import { GetPurchasedProductsResponseDto } from "@/domain/product/get-purchased-products/dto/GetPurchasedProductsResponseDto";
import GetPurchasedProductsService from "../service/GetPurchasedProductsService";

const purchasedProductsService = new GetPurchasedProductsService();

export class GetPurchasedProductsRepository implements IGetPurchasedProductsRepository {
  async purchasedProducts(
    request: GetPurchasedProductsRequestDto
  ): Promise<Result<GetPurchasedProductsResponseDto>> {
    try {
      return await purchasedProductsService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<GetPurchasedProductsResponseDto>(response.getValue());
          } else {
            return Result.fail<GetPurchasedProductsResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<GetPurchasedProductsResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.PRODUCT.GET_PURCHASED_PRODUCT))
      );
    }
  }
}
