import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { PollSessionDetailResponseDto } from "@/domain/pollster/poll-statistic/poll-session-detail/dto/PollSessionDetailResponseDto";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";
import { PollSessionDetailRequestDto } from "@/domain/pollster/poll-statistic/poll-session-detail/dto/PollSessionDetailRequestDto";

export class PollSessionDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: PollSessionDetailResponseDto): Result<PollSessionDetailModel> {
    try {
      const pollModel: PollSessionDetailModel = { 
        id: dto.data.id,
        surveyTitle: dto.data.attributes.surveyTitle,
        surveyDescription: dto.data.attributes.surveyDescription,        
        startDate: dto.data.attributes.startDate,
        endDate: dto.data.attributes.endDate,        
        sessionDuration: dto.data.attributes.sessionDuration,
        sessionCompletedPercentage: dto.data.attributes.sessionCompletedPercentage,
       };
      return Result.ok(pollModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_SESSION_DETAIL))
      );
    }
  }

  mapToDTO(domain: PollSessionDetailModel): Result<PollSessionDetailRequestDto> {
    try {
      const pollRequest: PollSessionDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(pollRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_SESSION_DETAIL))
      );
    }
  }
}
