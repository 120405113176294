import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import RemoveQualificationService from "../service/RemoveQualificationService";
import { IRemoveQualificationRepository } from "@/domain/interviewer/qualification/remove-qualification/repository/IRemoveQualificationRepository";
import { RemoveQualificationRequestDto } from "@/domain/interviewer/qualification/remove-qualification/dto/RemoveQualificationRequestDto";

const removeQualificationService = new RemoveQualificationService();

export class RemoveQualificationRepository implements IRemoveQualificationRepository {
  async removeQualification(request: RemoveQualificationRequestDto): Promise<Result<any>> {
    try {
      return await removeQualificationService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.REMOVE_QUALIFICATION)));
    }
  }
}
