import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import BuyProductService from "../service/BuyProductService";
import { IBuyProductRepository } from "@/domain/product/buy-product/repository/IBuyProductRepository";
import { BuyProductRequestDto } from "@/domain/product/buy-product/dto/BuyProductRequestDto";
import { BuyProductResponseDto } from "@/domain/product/buy-product/dto/BuyProductResponseDto";

const buyProductService = new BuyProductService();

export class BuyProductRepository implements IBuyProductRepository {
  async buyProduct(
    request: BuyProductRequestDto
  ): Promise<Result<BuyProductResponseDto>> {
    try {
      return await buyProductService
        .buy(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<BuyProductResponseDto>(response.getValue());
          } else {
            return Result.fail<BuyProductResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<BuyProductResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<BuyProductResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.PRODUCT.BUY_PRODUCT))
      );
    }
  }
}
