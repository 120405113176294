import { BaseDTO } from "@/core/base/BaseDTO";

export class PaginationRequestDto extends BaseDTO {
  pagination: {
    page: number;
    pageSize: number;
  };

  constructor(pagination: any) {
    super();
    this.pagination = {
      page: pagination.page,
      pageSize: pagination.pageSize > 50 ? 50 : pagination.pageSize,
    };
  }

  toQueryString(): string {
    return `?pagination[page]=${this.pagination.page}&pagination[pageSize]=${this.pagination.pageSize}`;
  }
}
