import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { ISendMessageService } from "@/domain/hotel-management/send-message/service/ISendMessageService";
import { SendMessageRequestDto } from "@/domain/hotel-management/send-message/dto/SendMessageRequestDto";
import { SendMessageResponseDto } from "@/domain/hotel-management/send-message/dto/SendMessageResponseDto";

export default class SendMessageService implements ISendMessageService {
  async generate(
    request: SendMessageRequestDto
  ): Promise<Result<SendMessageResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.HOTEL_REVIEW_MODULE, request)
        .then((response) => {
          return Result.ok<SendMessageResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<SendMessageResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_SEND_MESSAGE)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<SendMessageResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.SEND_MESSAGE))
      );
    }
  }
}
