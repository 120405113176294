import { BuyProductRepository } from "../repository/BuyProductRepository";
import { BuyProductMapper } from "../mapper/BuyProductMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IBuyProductUseCase } from "@/domain/product/buy-product/usecase/IBuyProductUsecase";
import { BuyProductModel } from "@/domain/product/buy-product/model/BuyProductModel";
import { IBuyProductRepository } from "@/domain/product/buy-product/repository/IBuyProductRepository";

export class BuyProductUsecase implements IBuyProductUseCase {
  buyProductRepository : IBuyProductRepository = new BuyProductRepository();
  buyProductMapper = new BuyProductMapper();

  constructor() {
  }

  async execute(model: BuyProductModel): Promise<Result<BuyProductModel>> {
    try {
      const dto = this.buyProductMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<BuyProductModel>(dto.getError());
      } else {
        const response = await this.buyProductRepository.buyProduct(dto);

        if (response.isSuccess) {
          const domain = this.buyProductMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<BuyProductModel>(domain.getValue());
        } else {
          return Result.fail<BuyProductModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<BuyProductModel>(
        new UseCaseException(new UnknownCause(USECASE.PRODUCT.BUY_PRODUCT))
      );
    }
  }
}
