import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { ICandidateListByInterviewService } from "@/domain/interviewer/candidate-list-by-interview/service/ICandidateListByInterviewService";
import { CandidateListByInterviewRequestDto } from "@/domain/interviewer/candidate-list-by-interview/dto/CandidateListByInterviewRequestDto";
import { CandidateListByInterviewResponseDto } from "@/domain/interviewer/candidate-list-by-interview/dto/CandidateListByInterviewResponseDto";

export default class CandidateListByInterviewService implements ICandidateListByInterviewService {
  async getList(
    request: CandidateListByInterviewRequestDto
  ): Promise<Result<CandidateListByInterviewResponseDto>> {
    try {
      return await instance
        .get(
          API_ROUTE.CANDIDATE +
            API_ROUTE.INTERVIEW +
            request.interviewId +
            request.toQueryString()
        )
        .then((response) => {
          return Result.ok<CandidateListByInterviewResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<CandidateListByInterviewResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_CANDIDATE_LIST)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.CANDIDATE_LIST_BY_INTERVIEW))
      );
    }
  }
}
