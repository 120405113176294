import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { DeleteQuestionModel } from "@/domain/interviewer/update-interview/deleteQuestion/model/DeleteQuestionModel";
import { DeleteQuestionRequestDto } from "@/domain/interviewer/update-interview/deleteQuestion/dto/DeleteQuestionRequestDto";

export class DeleteQuestionMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeleteQuestionModel> {
    try {
        const deleteQuestionModel: DeleteQuestionModel = {
          ok: dto.ok,
        };
        return Result.ok(deleteQuestionModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_INTERVIEW_QUESTION))
      );
    }
  }

  mapToDTO(domain: DeleteQuestionModel): Result<DeleteQuestionRequestDto> {
    try {
      const deleteQuestionRequest: DeleteQuestionRequestDto = {
        id: domain.id,
      };

      return Result.ok(deleteQuestionRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_INTERVIEW_QUESTION))
      );
    }
  }
}
