import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { InterviewDetailResponseDto } from "@/domain/interviewer/interview-detail/dto/InterviewDetailResponseDto";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";
import { InterviewDetailRequestDto } from "@/domain/interviewer/interview-detail/dto/InterviewDetailRequestDto";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingQualificationModel } from "@/domain/interviewer/job-posting-list/model/JobPostingQualificationModel";

export class InterviewDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: InterviewDetailResponseDto): Result<InterviewDetailModel> {
    try {
      if (dto.data) {
        const interviewQuestions: InterviewQuestionModel[] = [];
        if (
          dto.data.attributes.interviewQuestions &&
          dto.data.attributes.interviewQuestions.data
        ) {
          dto.data.attributes.interviewQuestions.data.forEach((question) => {
            const id = question.id;
            const questionText = question.attributes.questionText;
            const questionType = question.attributes.questionType;

            const interviewQuestionOptions: any[] = [];
            if (
              question.attributes.interviewQuestionOptions &&
              question.attributes.interviewQuestionOptions.data &&
              question.attributes.interviewQuestionOptions.data.length > 0
            ) {
              question.attributes.interviewQuestionOptions.data.forEach(
                (option) => {
                  const optionId = option.id;
                  const optionText = option.attributes.optionText;

                  if (optionText) {
                    interviewQuestionOptions.push({ id: optionId, optionText });
                  }
                }
              );
            }

            if (questionText && questionType) {
              interviewQuestions.push({
                id,
                questionText,
                questionType,
                interviewQuestionOptions,
              });
            }
          });
        }

        const jobPostingDetailModel: JobPostingDetailModel = {};

        if (
          dto.data.attributes.jobPosting &&
          dto.data.attributes.jobPosting.data
        ) {
          jobPostingDetailModel.id = dto.data.attributes.jobPosting.data.id;
          jobPostingDetailModel.title =
            dto.data.attributes.jobPosting.data.attributes.title;
          jobPostingDetailModel.description =
            dto.data.attributes.jobPosting.data.attributes.description;
          jobPostingDetailModel.startDate =
            dto.data.attributes.jobPosting.data.attributes.startDate;
          jobPostingDetailModel.endDate =
            dto.data.attributes.jobPosting.data.attributes.endDate;
          jobPostingDetailModel.status =
            dto.data.attributes.jobPosting.data.attributes.status;
          jobPostingDetailModel.candidateCount =
            dto.data.attributes.jobPosting.data.attributes.candidateCount;

          const jobPostingQualifications: JobPostingQualificationModel[] = [];
          if (
            dto.data.attributes.jobPosting.data.attributes
              .jobPostingQualifications &&
            dto.data.attributes.jobPosting.data.attributes
              .jobPostingQualifications.data
          ) {
            dto.data.attributes.jobPosting.data.attributes.jobPostingQualifications.data.forEach(
              (qualification) => {
                const text = qualification.attributes.text;
                const id = qualification.id;
                if (text && id) {
                  jobPostingQualifications.push({ id, text });
                }
              }
            );
          }
          jobPostingDetailModel.jobPostingQualifications =
            jobPostingQualifications;
        }
        const interviewDetailModel: InterviewDetailModel = {
          id: dto.data.id,
          title: dto.data.attributes.title,
          description: dto.data.attributes.description,
          sequence: dto.data.attributes.sequence,
          hasCompletedInterviewSession:
            dto.data.attributes.hasCompletedInterviewSession,
          jobPosting: jobPostingDetailModel,
          interviewQuestions,
        };

        return Result.ok(interviewDetailModel);
      } else {
        const interviewDetailModel: InterviewDetailModel = {};
        return Result.ok(interviewDetailModel);
      }
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_DETAIL)
        )
      );
    }
  }

  mapToDTO(domain: InterviewDetailModel): Result<InterviewDetailRequestDto> {
    try {
      const interviewRequest: InterviewDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(interviewRequest);
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_DETAIL)
        )
      );
    }
  }
}
