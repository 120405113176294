import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { CountryListUseCase } from "@/application/country/country-list/usecase/CountryListUseCase";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";

export function createCountryController() {
  const useCases = {
    countryList: {
      useCase: new CountryListUseCase(),
      model: CountryListModel,
    },
  };

  return new CountryController(useCases);
}

export class CountryController implements IController {
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
