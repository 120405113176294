import { BaseDomain } from "@/core/base/BaseDomain";

export class RemoveQualificationModel extends BaseDomain {
  jobPostingId?: number;
  qualificationId?: number;
  ok?: boolean;

  constructor(    jobPostingId?: number,
    qualificationId?: number, ok?: boolean) {
    super();
    this.jobPostingId = jobPostingId;
    this.qualificationId = qualificationId;
    this.ok = ok;
  }
}
