
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { StatisticPollListRepository } from "../repository/PollListRepository";
import { StatisticPollListMapper } from "../mapper/PollListMapper";
import { IStatisticPollListUseCase } from "@/domain/pollster/poll-statistic/poll-list/usecase/IPollListUsecase";
import { IStatisticPollListRepository } from "@/domain/pollster/poll-statistic/poll-list/repository/IPollListRepository";
import { StatisticPollListModel } from "@/domain/pollster/poll-statistic/poll-list/model/PollListModel";

export class StatisticPollListUseCase implements IStatisticPollListUseCase {
  statisticPollListRepository : IStatisticPollListRepository = new StatisticPollListRepository();
  statisticPollListMapper = new StatisticPollListMapper();

  constructor() {
  }

  async execute(model: StatisticPollListModel): Promise<Result<StatisticPollListModel>> {
    try {
      const dto = this.statisticPollListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<StatisticPollListModel>(dto.getError());
      } else {
        const response = await this.statisticPollListRepository.getPollList(dto);

        if (response.isSuccess) {
          const domain = this.statisticPollListMapper.mapToDomain(response.getValue());
          return Result.ok<StatisticPollListModel>(domain.getValue());
        } else {
          return Result.fail<StatisticPollListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<StatisticPollListModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.STATISTIC.POLL_LIST))
      );
    }
  }
}
