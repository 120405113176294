import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateCandidateModel } from "@/domain/interviewer/update-candidate/model/UpdateCandidateModel";
import { UpdateCandidateRequestDto } from "@/domain/interviewer/update-candidate/dto/UpdateCandidateRequestDto";

export class UpdateCandidateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateCandidateModel> {
    try {
        const updateCandidateModel: UpdateCandidateModel = {
          ok: dto.ok,
        };
        return Result.ok(updateCandidateModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_CANDIDATE))
      );
    }
  }

  mapToDTO(domain: UpdateCandidateModel): Result<UpdateCandidateRequestDto> {
    try {
      const updateCandidate: UpdateCandidateRequestDto = {
        id: domain.id,
        data: {
          candidateStatus: domain.candidateStatus,
        },
        cv: domain.cv
      };

      return Result.ok(updateCandidate);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_CANDIDATE))
      );
    }
  }
}
