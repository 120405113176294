export const ROUTE_PAGES = {
  SIGN_IN: "login",
  LANDING_PAGE: "landingPage",
  ACCEPT_INVITE: "acceptInvite",
  DASHBOARD: "dashboard",
  SET_PROFILE_INFO:"setProfileInfo",
  ERROR: "404",
  FORBIDDEN: "500",
  POLLSTER: "doPoll",
  DO_INTERVIEW: "doInterview",
  START_INTERVIEW: "startToInterview",
  INTERVIEW_RESULT: "interviewResult",
  INTERVIEW_FAILED: "interviewFailed",
  DO_POLL: "doPoll",
};
