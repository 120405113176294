import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AddQualificationService from "../service/AddQualificationService";
import { IAddQualificationRepository } from "@/domain/interviewer/qualification/add-qualification/repository/AddQualificationRepository";
import { AddQualificationRequestDto } from "@/domain/interviewer/qualification/add-qualification/dto/AddQualificationRequestDto";

const addQualificationService = new AddQualificationService();

export class AddQualificationRepository implements IAddQualificationRepository {
  async addQualification(request: AddQualificationRequestDto): Promise<Result<any>> {
    try {
      return await addQualificationService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.ADD_QUALIFICATION)));
    }
  }
}
