import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { PollDetailResponseDto } from "@/domain/pollster/poll-detail/dto/PollDetailResponseDto";
import { PollDetailRequestDto } from "@/domain/pollster/poll-detail/dto/PollDetailRequestDto";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";

export class PollDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: PollDetailResponseDto): Result<PollDetailModel> {
    try {
      if (dto.data) {
        const pollModel: PollDetailModel = {
          id: dto.data.id,
          title: dto.data.attributes.title,
          description: dto.data.attributes.description,
          status: dto.data.attributes.status,
          startDate: dto.data.attributes.startDate,
          endDate: dto.data.attributes.endDate,
          isPrivate: dto.data.attributes.isPrivate,
          sessionCount: dto.data.attributes.sessionCount,
          surveyQuestions: dto.data.attributes.surveyQuestions.data.map(
            (item) => ({
              surveyQuestionOptions: item.attributes.surveyQuestionOptions.data.map((item) => ({
                optionText: item.attributes.optionText
              })),
              questionText: item.attributes.questionText,
              questionType: item.attributes.questionType,
            })
          ),
        };

        return Result.ok(pollModel);
      } else {
        const pollModel: PollDetailModel = { surveyQuestions: [] };
        return Result.ok(pollModel);
      }
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.POLL_DETAIL))
      );
    }
  }

  mapToDTO(domain: PollDetailModel): Result<PollDetailRequestDto> {
    try {
      const pollRequest: PollDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(pollRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.POLL_DETAIL))
      );
    }
  }
}
