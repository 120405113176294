import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { ProductListRequestDto } from "@/domain/product/product-list/dto/ProductListRequestDto";
import { IProductListService } from "@/domain/product/product-list/service/IProductListService";
import { ProductListResponseDto } from "@/domain/product/product-list/dto/ProductListResponseDto";

export default class ProductListService implements IProductListService {
  async getList(
    request: ProductListRequestDto
  ): Promise<Result<ProductListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.PRODUCTS + request.toQueryString())
        .then((response) => {
          return Result.ok<ProductListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<ProductListResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_PRODUCT_LIST))
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.PRODUCT.PRODUCT_LIST))
      );
    }
  }
}
