import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import InterviewCountService from "../service/InterviewCountService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IInterviewCountRepository } from "@/domain/interviewer/interview-counts/repository/IInterviewCountRepository";
import { InterviewCountRequestDto } from "@/domain/interviewer/interview-counts/dto/InterviewCountRequestDto";
import { InterviewCountResponseDto } from "@/domain/interviewer/interview-counts/dto/InterviewCountResponseDto";

const tenantUsersInterviewCountService = new InterviewCountService();

export class InterviewCountRepository implements IInterviewCountRepository {
  async getInterviewCounts(
    request: InterviewCountRequestDto
  ): Promise<Result<InterviewCountResponseDto>> {
    try {
      return await tenantUsersInterviewCountService
        .getCounts(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<InterviewCountResponseDto>(response.getValue());
          } else {
            return Result.fail<InterviewCountResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<InterviewCountResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.INTERVIEW_COUNTS))
      );
    }
  }
}
