import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { JobPostingListResponseDto } from "@/domain/interviewer/job-posting-list/dto/JobPostingListResponseDto";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { JobPostingListRequestDto } from "@/domain/interviewer/job-posting-list/dto/JobPostingListRequestDto";
import { JobPostingQualificationModel } from "@/domain/interviewer/job-posting-list/model/JobPostingQualificationModel";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";

const paginationMapper = new PaginationMapper();

export class JobPostingListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: JobPostingListResponseDto): Result<JobPostingListModel[]> {
    try {
      const jobPostingList: JobPostingListModel[] = [];
      if (dto.data)
        if (dto.data.length > 0) {
          for (const item of dto.data) {
            const jobPostingQualifications: JobPostingQualificationModel[] = [];
            if (
              item.attributes.jobPostingQualifications &&
              item.attributes.jobPostingQualifications.data
            ) {
              item.attributes.jobPostingQualifications.data.forEach(
                (qualification) => {
                  const text = qualification.attributes.text;
                  const id = qualification.id;
                  if (text && id) {
                    jobPostingQualifications.push({ id, text });
                  }
                }
              );
            }

            const jobPostingInterviews: JobPostingInterviewModel[] = [];
            if (item.attributes.interviews && item.attributes.interviews.data) {
              item.attributes.interviews.data.forEach((interview) => {
                const id = interview.id;
                const title = interview.attributes.title;
                const description = interview.attributes.description;
                const sequence = interview.attributes.sequence;

                const jobPostingInterview = new JobPostingInterviewModel(
                  id,
                  title,
                  description,
                  sequence
                );
                jobPostingInterviews.push(jobPostingInterview);
              });
            }

            const jobPostingListModel: JobPostingListModel = {
              jobPosting: {
                jobPostingId: Number(item.id),
                title: item.attributes.title,
                description: item.attributes.description,
                startDate: item.attributes.startDate,
                endDate: item.attributes.endDate,
                status: item.attributes.status,
                candidateCount: item.attributes.candidateCount,
                jobPostingQualifications: jobPostingQualifications,
                hasCompletedInterviewSession:
                  item.attributes.hasCompletedInterviewSession,
                hasCvAnalysis: item.attributes.hasCvAnalysis,
                interviews: jobPostingInterviews,
              },
              page: 0,
              pageSize: 0,
              pagination: dto.meta?.pagination,
            };

            jobPostingList.push(jobPostingListModel);
          }
          return Result.ok(jobPostingList);
        }
      return Result.ok(jobPostingList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.JOBPOSTING_LIST))
      );
    }
  }

  mapToDTO(domain: JobPostingListModel): Result<JobPostingListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const jobPostingListRequest = new JobPostingListRequestDto(
        pagiRequest,
        domain.jobPosting.status
      );

      return Result.ok(jobPostingListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.JOBPOSTING_LIST))
      );
    }
  }
}
