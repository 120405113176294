import { BaseDomain } from "@/core/base/BaseDomain";

export class VerifyOTPModel extends BaseDomain {
  jwt?: string;
  phoneNumber?: string;
  countryCode?: number;
  otp?: string;
  id?: number;
  username?: string;
  email?: string;
  name?: string;
  surname?: string;
  remainingQuota?: number;
  roleCode?: string;
  roleName?: string;

  constructor(
    jwt?: string,
    phoneNumber?: string,
    countryCode?: number,
    otp?: string,
    id?: number,
    username?: string,
    email?: string,
    name?: string,
    surname?: string,
    remainingQuota?: number,
    roleCode?: string,
    roleName?: string
  ) {
    super();
    this.jwt = jwt;
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
    this.otp = otp;
    this.id = id;
    this.username = username;
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.remainingQuota = remainingQuota;
    this.roleCode = roleCode;
    this.roleName = roleName;
  }
}
