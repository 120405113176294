import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { PollDetailRequestDto } from "@/domain/pollster/poll-detail/dto/PollDetailRequestDto";
import { PollDetailResponseDto } from "@/domain/pollster/poll-detail/dto/PollDetailResponseDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IPollDetailService } from "@/domain/pollster/poll-detail/service/IPollDetailService";

export default class PollDetailService implements IPollDetailService {
  async get(
    request: PollDetailRequestDto
  ): Promise<Result<PollDetailResponseDto>> {
    try {
      return await instance
        .get<PollDetailResponseDto>(API_ROUTE.SURVEYS + request.id)
        .then((response) => {
          return Result.ok<PollDetailResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<PollDetailResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_POLL_DETAIL)))
          );
        });
    } catch (error) {
      return Result.fail<PollDetailResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.POLLSTER.POLL_DETAIL))
      );
    }
  }
}
