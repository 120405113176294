import { BaseDomain } from "@/core/base/BaseDomain";
import { FileResponseDto } from "../../multiple-candidate-template/dto/FileResponseDto";
import { CandidateInterviewModel } from "./CandidateInterviewModel";

export class CandidateDetailModel extends BaseDomain {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  cv?: FileResponseDto;
  cvUrl?: string;
  candidateStatus?: string;
  hasInterviewSession?: boolean;
  jobPostingId?: number;
  jobPostingTitle?: string;
  interviews?: CandidateInterviewModel[];
  CvAnalysisScore?: number;
  CvAnalysisStatus?: string;
  positionSuitability?: number;

  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    email?: string,
    cv?: FileResponseDto,
    cvUrl?: string,
    candidateStatus?: string,
    hasInterviewSession?: boolean,
    jobPostingId?: number,
    jobPostingTitle?: string,
    interviews?: CandidateInterviewModel[],
    CvAnalysisScore?: number,
    CvAnalysisStatus?: string,
    positionSuitability?: number,
  ) {
    super();
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.fullName = fullName;
    this.email = email;
    this.cv = cv;
    this.cvUrl = cvUrl;
    this.candidateStatus = candidateStatus;
    this.hasInterviewSession = hasInterviewSession;
    this.jobPostingId = jobPostingId;
    this.jobPostingTitle = jobPostingTitle;
    this.interviews = interviews;
    this.CvAnalysisScore = CvAnalysisScore;
    this.CvAnalysisStatus = CvAnalysisStatus;
    this.positionSuitability = positionSuitability;
  }
}
