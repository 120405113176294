import { BaseDomain } from "@/core/base/BaseDomain";

export class InviteStaffModel extends BaseDomain {
  phoneNumber?: string;
  countryCode?: number;
  department?: string;
  position?: string;
  ok?: boolean;
  locale?: string;

  constructor(
    phoneNumber?: string,
    countryCode?: number,
    department?: string,
    position?: string,
    ok?: boolean,
    locale?: string,
  ) {
    super();
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
    this.department = department;
    this.position = position;
    this.ok = ok;
    this.locale = locale;
  }
}
