import { JobPostingDetailRepository } from "../repository/JobPostingDetailRepository";
import { JobPostingDetailMapper } from "../mapper/JobPostingDetailMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IJobPostingDetailUseCase } from "@/domain/interviewer/job-posting-detail/usecase/IJobPostingDetailUseCase";
import { IJobPostingDetailRepository } from "@/domain/interviewer/job-posting-detail/repository/IJobPostingDetailRepository";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";

export class JobPostingDetailUseCase implements IJobPostingDetailUseCase {
  getJobPostingDetailRepository : IJobPostingDetailRepository = new JobPostingDetailRepository();
  getJobPostingDetailMapper = new JobPostingDetailMapper ();

  constructor() {
  }

  async execute(model: JobPostingDetailModel): Promise<Result<JobPostingDetailModel>> {
    try {
      const dto = this.getJobPostingDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<JobPostingDetailModel>(dto.getError());
      } else {
        const response = await this.getJobPostingDetailRepository.getJobPostingDetail(dto);

        if (response.isSuccess) {
          const domain = this.getJobPostingDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<JobPostingDetailModel>(domain.getValue());
        } else {
          return Result.fail<JobPostingDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<JobPostingDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.JOBPOSTING_DETAIL))
      );
    }
  }
}
