import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IBuyProductService } from "@/domain/product/buy-product/service/IBuyProductService";
import { BuyProductRequestDto } from "@/domain/product/buy-product/dto/BuyProductRequestDto";
import { BuyProductResponseDto } from "@/domain/product/buy-product/dto/BuyProductResponseDto";

export default class BuyProductService implements IBuyProductService {
  async buy(
    request: BuyProductRequestDto
  ): Promise<Result<BuyProductResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.BUY_PRODUCT_PACKAGE, request)
        .then((response) => {
          return Result.ok<BuyProductResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<BuyProductResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_BUY_PRODUCT)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<BuyProductResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.PRODUCT.BUY_PRODUCT))
      );
    }
  }
}
