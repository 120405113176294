import { BaseDTO } from "@/core/base/BaseDTO";

export class ChangeTenantEstateRequestDto extends BaseDTO {
  data: {
    tenantEstate: number;
  };

  constructor(tenantEstate: number) {
    super();
    this.data = {
      tenantEstate,
    };
  }
}
