import { BaseDomain } from "@/core/base/BaseDomain";

export class ProductFAQModel extends BaseDomain {
  id?: number
  question?: string;
  answer?: string;

  constructor(id?: number, question?: string, answer?: string) {
    super();
    this.id = id;
    this.question = question;
    this.answer = answer;
  }
}
