import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { JobPostingListUseCase } from "@/application/interviewer/job-posting-list/usecase/JobPostingListUseCase";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import { DeleteJobPostingUseCase } from "@/application/interviewer/delete-job-posting/usecase/DeleteJobPostingUseCase";
import { CreateJobPostingModel } from "@/domain/interviewer/create-job-posting/model/CreateJobPostingModel";
import { CreateJobPostingUsecase } from "@/application/interviewer/create-job-posting/usecase/CreateJobPostingUseCase";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { UpdateJobPostingUseCase } from "@/application/interviewer/update-job-posting/usecase/UpdateJobPostingUseCase";
import { RemoveQualificationUseCase } from "@/application/interviewer/qualification/remove-qualification/usecase/RemoveQualificationUseCase";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";
import { AddQualificationUseCase } from "@/application/interviewer/qualification/add-qualification/usecase/AddQualificationUseCase";
import { CreateInterviewModel } from "@/domain/interviewer/create-interview/model/CreateInterviewModel";
import { CreateInterviewUsecase } from "@/application/interviewer/create-interview/usecase/CreateInterviewUseCase";
import { CandidateListUseCase } from "@/application/interviewer/candidate-list/usecase/CandidateListUseCase";
import { AddCandidateUsecase } from "@/application/interviewer/add-candidate/usecase/AddCandidateUseCase";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";
import { AddCandidateModel } from "@/domain/interviewer/add-candidate/model/AddCandidateModel";
import { GetMultipleCandidateTemplateUsecase } from "@/application/interviewer/multiple-candidate-template/usecase/GetMultipleCandidateTemplateUseCase";
import { UpdateJobPostingStatusUseCase } from "@/application/interviewer/update-job-posting-status/usecase/UpdateJobPostingStatusUseCase";
import { UpdateJobPostingStatusModel } from "@/domain/interviewer/update-job-posting-status/model/UpdateJobPostingStatusModel";
import { UpdateInterviewUseCase } from "@/application/interviewer/update-interview/usecase/UpdateInterviewUseCase";
import { UpdateInterviewModel } from "@/domain/interviewer/update-interview/model/UpdateInterviewModel";
import { AddQuestionUsecase } from "@/application/interviewer/update-interview/addQuestion/usecase/AddQuestionUseCase";
import { AddQuestionOptionUsecase } from "@/application/interviewer/update-interview/addQuestionOption/usecase/AddQuestionOptionUseCase";
import { UpdateQuestionUseCase } from "@/application/interviewer/update-interview/updateQuestion/usecase/UpdateQuestionUseCase";
import { DeleteQuestionUseCase } from "@/application/interviewer/update-interview/deleteQuestion/usecase/DeleteQuestionUseCase";
import { DeleteQuestionOptionUseCase } from "@/application/interviewer/update-interview/deleteQuestionOption/usecase/DeleteQuestionOptionUseCase";
import { InterviewDetailUseCase } from "@/application/interviewer/interview-detail/usecase/InterviewDetailUseCase";
import { AddQuestionModel } from "@/domain/interviewer/update-interview/addQuestion/model/AddQuestionModel";
import { AddQuestionOptionModel } from "@/domain/interviewer/update-interview/addQuestionOption/model/AddQuestionOptionModel";
import { UpdateQuestionModel } from "@/domain/interviewer/update-interview/updateQuestion/model/UpdateQuestionModel";
import { DeleteQuestionModel } from "@/domain/interviewer/update-interview/deleteQuestion/model/DeleteQuestionModel";
import { DeleteQuestionOptionModel } from "@/domain/interviewer/update-interview/deleteQuestionOption/model/DeleteQuestionOptionModel";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";
import { JobPostingDetailUseCase } from "@/application/interviewer/job-posting-detail/usecase/JobPostingDetailUseCase";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { DeleteInterviewUseCase } from "@/application/interviewer/delete-interview/usecase/DeleteInterviewUseCase";
import { DeleteInterviewModel } from "@/domain/interviewer/delete-interview/model/DeleteInterviewModel";
import { UpdateCandidateUseCase } from "@/application/interviewer/update-candidate/usecase/UpdateCandidateUseCase";
import { DeleteCandidateUseCase } from "@/application/interviewer/delete-candidate/usecase/DeleteCandidateUseCase";
import { UpdateCandidateModel } from "@/domain/interviewer/update-candidate/model/UpdateCandidateModel";
import { DeleteCandidateModel } from "@/domain/interviewer/delete-candidate/model/DeleteCandidateModel";
import { InterviewCountUseCase } from "@/application/interviewer/interview-counts/usecase/InterviewCountUseCase";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { CandidateDetailUseCase } from "@/application/interviewer/candidate-detail/usecase/CandidateDetailUseCase";
import { InterviewInviteUsecase } from "@/application/interviewer/interview-invite/usecase/InterviewInviteUseCase";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";
import { CandidateActionListModel } from "@/domain/interviewer/candidate-action-list/model/CandidateActionListModel";
import { CandidateActionListUseCase } from "@/application/interviewer/candidate-action-list/usecase/CandidateActionListUseCase";
import { CandidateCvAnalysisUsecase } from "@/application/interviewer/candidate-cv-analysis/usecase/CandidateCvAnalysisUseCase";
import { CandidateCvAnalysisModel } from "@/domain/interviewer/candidate-cv-analysis/model/CandidateCvAnalysisModel";
import { JobPostingDetailByInviteUseCase } from "@/application/interviewer/job-posting-detail-by-invite-key/usecase/JobPostingDetailByInviteUseCase";
import { RevokeInterviewInviteUsecase } from "@/application/interviewer/revoke-interview-invite/usecase/RevokeInterviewInviteUseCase";
import { CandidateListByInterviewUseCase } from "@/application/interviewer/candidate-list-by-interview/usecase/CandidateListByInterviewUseCase";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";
import { CandidateListByInterviewModel } from "@/domain/interviewer/candidate-list-by-interview/model/CandidateListByInterviewModel";
import { CandidateInterviewLogsModel } from "@/domain/interviewer/candidate-interview-logs/model/CandidateInterviewLogsModel";
import { CandidateInterviewLogsUsecase } from "@/application/interviewer/candidate-interview-logs/usecase/CandidateInterviewLogsUseCase";
import { CandidateInterviewResponsesUsecase } from "@/application/interviewer/candidate-interview-responses/usecase/CandidateInterviewResponsesUseCase";
import { CandidateInterviewResponsesModel } from "@/domain/interviewer/candidate-interview-responses/model/CandidateInterviewResponsesModel";

export function createInterviewerController() {
  const useCases = {
    createJobPosting: {
      useCase: new CreateJobPostingUsecase(),
      model: CreateJobPostingModel,
    },
    updateJobPosting: {
      useCase: new UpdateJobPostingUseCase(),
      model: UpdateJobPostingModel,
    },
    jobPostingList: {
      useCase: new JobPostingListUseCase(),
      model: JobPostingListModel,
    },
    jobPostingDetail: {
      useCase: new JobPostingDetailUseCase(),
      model: JobPostingDetailModel,
    },
    deleteJobPosting: {
      useCase: new DeleteJobPostingUseCase(),
      model: DeleteJobPostingModel,
    },
    removeQualification: {
      useCase: new RemoveQualificationUseCase(),
      model: RemoveQualificationModel,
    },
    addQualification: {
      useCase: new AddQualificationUseCase(),
      model: AddQualificationModel,
    },
    createInterview: {
      useCase: new CreateInterviewUsecase(),
      model: CreateInterviewModel,
    },
    candidateList: {
      useCase: new CandidateListUseCase(),
      model: CandidateListModel,
    },
    addCandidate: {
      useCase: new AddCandidateUsecase(),
      model: AddCandidateModel,
    },
    getMultipleCandidateTemplate: {
      useCase: new GetMultipleCandidateTemplateUsecase(),
    },
    updateJobPostingStatus: {
      useCase: new UpdateJobPostingStatusUseCase(),
      model: UpdateJobPostingStatusModel,
    },
    updateInterview: {
      useCase: new UpdateInterviewUseCase(),
      model: UpdateInterviewModel,
    },
    addQuestion: {
      useCase: new AddQuestionUsecase(),
      model: AddQuestionModel,
    },
    addQuestionOption: {
      useCase: new AddQuestionOptionUsecase(),
      model: AddQuestionOptionModel,
    },
    updateQuestion: {
      useCase: new UpdateQuestionUseCase(),
      model: UpdateQuestionModel,
    },
    deleteQuestion: {
      useCase: new DeleteQuestionUseCase(),
      model: DeleteQuestionModel,
    },
    deleteQuestionOption: {
      useCase: new DeleteQuestionOptionUseCase(),
      model: DeleteQuestionOptionModel,
    },
    getInterviewDetail: {
      useCase: new InterviewDetailUseCase(),
      model: InterviewDetailModel,
    },
    deleteInterview: {
      useCase: new DeleteInterviewUseCase(),
      model: DeleteInterviewModel,
    },
    updateCandidate: {
      useCase: new UpdateCandidateUseCase(),
      model: UpdateCandidateModel,
    },
    deleteCandidate: {
      useCase: new DeleteCandidateUseCase(),
      model: DeleteCandidateModel,
    },
    getInterviewCounts: {
      useCase: new InterviewCountUseCase(),
      model: InterviewCountModel,
    },
    candidateDetail: {
      useCase: new CandidateDetailUseCase(),
      model: CandidateDetailModel,
    },
    interviewInvite: {
      useCase: new InterviewInviteUsecase(),
      model: InterviewInviteModel,
    },
    getCandidateActionList: {
      useCase: new CandidateActionListUseCase(),
      model: CandidateActionListModel,
    },
    candidateCvAnalysis: {
      useCase: new CandidateCvAnalysisUsecase(),
      model: CandidateCvAnalysisModel,
    },
    jobPostingDetailByInvite: {
      useCase: new JobPostingDetailByInviteUseCase(),
      model: JobPostingDetailByInviteModel,
    },
    revokeInterviewInvite: {
      useCase: new RevokeInterviewInviteUsecase(),
      model: RevokeInterviewInviteModel,
    },
    candidateListByInterview: {
      useCase: new CandidateListByInterviewUseCase(),
      model: CandidateListByInterviewModel,
    },
    candidateInterviewLogs: {
      useCase: new CandidateInterviewLogsUsecase(),
      model: CandidateInterviewLogsModel,
    },
    candidateInterviewResponses: {
      useCase: new CandidateInterviewResponsesUsecase(),
      model: CandidateInterviewResponsesModel,
    },
  };

  return new InterviewerController(useCases);
}

export class InterviewerController implements IController{
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model?: new (...args: any[]) => any | null }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];

            if (model) {
              const modelInstance = Object.assign(new model(), data);
              data = modelInstance;
            }

            const response = await useCase.execute(data);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
