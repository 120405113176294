import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IJobPostingDetailByInviteService } from "@/domain/interviewer/job-posting-detail-by-invite-key/service/IJobPostingDetailByInviteService";
import { JobPostingDetailByInviteRequestDto } from "@/domain/interviewer/job-posting-detail-by-invite-key/dto/JobPostingDetailByInviteRequestDto";
import { JobPostingDetailByInviteResponseDto } from "@/domain/interviewer/job-posting-detail-by-invite-key/dto/JobPostingDetailByInviteResponseDto";

export default class JobPostingDetailByInviteService implements IJobPostingDetailByInviteService {
  async get(
    request: JobPostingDetailByInviteRequestDto
  ): Promise<Result<JobPostingDetailByInviteResponseDto>> {
    try {
      return await instance
      .get<JobPostingDetailByInviteResponseDto>(
        API_ROUTE.JOB_POSTINGS + API_ROUTE.INVITE_KEY + request.key,
        {
          headers: {
            disableAuth: true, 
          },
        }
      )
        .then((response) => {
          return Result.ok<JobPostingDetailByInviteResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<JobPostingDetailByInviteResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_JOBPOSTING_DETAIL)))
          );
        });
    } catch (error) {
      return Result.fail<JobPostingDetailByInviteResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.JOBPOSTING_DETAIL_BY_INVITE))
      );
    }
  }
}
