import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import CandidateCvAnalysisService from "../service/CandidateCvAnalysisService";
import { ICandidateCvAnalysisRepository } from "@/domain/interviewer/candidate-cv-analysis/repository/ICandidateCvAnalysisRepository";
import { CandidateCvAnalysisRequestDto } from "@/domain/interviewer/candidate-cv-analysis/dto/CandidateCvAnalysisRequestDto";

const candidateCvAnalysisService = new CandidateCvAnalysisService();

export class CandidateCvAnalysisRepository implements ICandidateCvAnalysisRepository {
  async candidateCvAnalysis(
    request: CandidateCvAnalysisRequestDto
  ): Promise<Result<any>> {
    try {
      return await candidateCvAnalysisService
        .create(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.CANDIDATE_CV_ANALYSIS))
      );
    }
  }
}
