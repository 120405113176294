import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IUpdateUserService } from "@/domain/user/update-user/service/IUpdateUserService";
import { UpdateUserRequestDto } from "@/domain/user/update-user/dto/UpdateUserRequestDto";

export default class UpdateUserService implements IUpdateUserService {
  async update(request: UpdateUserRequestDto): Promise<Result<any>> {
    try {
      return await instance
        .put(API_ROUTE.USER_DETAILS, request)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_UPDATE_USER)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.UPDATE_USER))
      );
    }
  }
}
