import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import DeleteQuestionService from "../service/DeleteQuestionService";
import { IDeleteQuestionRepository } from "@/domain/interviewer/update-interview/deleteQuestion/repository/IDeleteQuestionRepository";
import { DeleteQuestionRequestDto } from "@/domain/interviewer/update-interview/deleteQuestion/dto/DeleteQuestionRequestDto";

const deleteQuestionService = new DeleteQuestionService();

export class DeleteQuestionRepository implements IDeleteQuestionRepository {
  async deleteQuestion(request: DeleteQuestionRequestDto): Promise<Result<any>> {
    try {
      return await deleteQuestionService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.DELETE_INTERVIEW_QUESTION)));
    }
  }
}
