import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { InviteStaffRequestDto } from "@/domain/staff/invite-staff/dto/InviteStaffRequestDto";
import { InviteStaffModel } from "@/domain/staff/invite-staff/model/InviteStaffModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";

export class InviteStaffMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<InviteStaffModel> {
    try {
        const inviteStaffModel: InviteStaffModel = {
          ok: dto.ok,
        };
        return Result.ok(inviteStaffModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.INVITE_STAFF))
      );
    }
  }

  mapToDTO(domain: InviteStaffModel): Result<InviteStaffRequestDto> {
    try {
      const inviteUserRequest = new InviteStaffRequestDto(
        domain.phoneNumber,
        domain.countryCode,
        domain.department,
        domain.position,
        domain.locale
      );

      return Result.ok(inviteUserRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.INVITE_STAFF))
      );
    }
  }
}
