import { BaseDTO } from "@/core/base/BaseDTO";

export class CandidateInterviewLogsRequestDto extends BaseDTO {
  data: {
    candidate?: number;
    interview?: number;
  }
  constructor(
    candidate?: number,
    interview?: number,
  ) {
    super();
    this.data = {
      candidate,
      interview
    }
  }
}
