import { BaseDomain } from "@/core/base/BaseDomain";

export class InterviewInviteModel extends BaseDomain {
  interview?: number;
  candidate?: number;
  expirationDate?: Date;
  ok?: boolean;
  locale?: string;

  constructor(
    interview?: number,
    candidate?: number,
    expirationDate?: Date,
    ok?: boolean,
    locale?: string,
  ) {
    super();
    this.interview = interview;
    this.candidate = candidate;
    this.expirationDate = expirationDate;
    this.ok = ok;
    this.locale = locale;
  }
}
