import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { RetryAnswerRequestDto } from "@/domain/hotel-management/retry-answer/dto/RetryAnswerRequestDto";
import { RetryAnswerModel } from "@/domain/hotel-management/retry-answer/model/RetryAnswerModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { SendMessageResponseDto } from "@/domain/hotel-management/send-message/dto/SendMessageResponseDto";

export class RetryAnswerMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: SendMessageResponseDto): Result<RetryAnswerModel> {
    try {
      const messages = dto.data.attributes.productSessionMessages.data;
      const lastMessage = messages[messages.length - 1];
      
      const retryAnswerModel = new RetryAnswerModel(
        Number(lastMessage.id),
        lastMessage.attributes.owner,
        lastMessage.attributes.text,
        new Date(lastMessage.attributes.createdAt),
        lastMessage.attributes.isLiked,
        lastMessage.attributes.emotionAnalysis
      );

      return Result.ok(retryAnswerModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.RETRY_ANSWER))
      );
    }
  }

  mapToDTO(domain: RetryAnswerModel): Result<RetryAnswerRequestDto> {
    try {
      const retryAnswerRequest: RetryAnswerRequestDto = {
        id: domain.messageId,
      };

      return Result.ok(retryAnswerRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.RETRY_ANSWER))
      );
    }
  }
}
