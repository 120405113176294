
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IUpdateJobPostingStatusUseCase } from "@/domain/interviewer/update-job-posting-status/usecase/IUpdateJobPostingStatusUseCase";
import { UpdateJobPostingStatusRepository } from "../repository/UpdateJobPostingStatusRepository";
import { UpdateJobPostingStatusMapper } from "../mapper/UpdateJobPostingStatusMapper";
import { IUpdateJobPostingStatusRepository } from "@/domain/interviewer/update-job-posting-status/repository/IUpdateJobPostingStatusRepository";
import { UpdateJobPostingStatusModel } from "@/domain/interviewer/update-job-posting-status/model/UpdateJobPostingStatusModel";

export class UpdateJobPostingStatusUseCase implements IUpdateJobPostingStatusUseCase {
  updateJobPostingStatusRepository: IUpdateJobPostingStatusRepository = new UpdateJobPostingStatusRepository();
  updateJobPostingStatusMapper = new UpdateJobPostingStatusMapper();

  constructor() {}

  async execute(model: UpdateJobPostingStatusModel): Promise<Result<UpdateJobPostingStatusModel>> {
    try {
      const dto = this.updateJobPostingStatusMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateJobPostingStatusModel>(dto.getError());
      } else {
        const response = await this.updateJobPostingStatusRepository.updateJobPostingStatus(dto);

        if (response.isSuccess) {
          const domain = this.updateJobPostingStatusMapper.mapToDomain(response.getValue());

          return Result.ok<UpdateJobPostingStatusModel>(domain.getValue());
        } else {
          return Result.fail<UpdateJobPostingStatusModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateJobPostingStatusModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.UPDATE_JOBPOSTING))
      );
    }
  }
}
