import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { CandidateListByInterviewResponseDto } from "@/domain/interviewer/candidate-list-by-interview/dto/CandidateListByInterviewResponseDto";
import { CandidateListByInterviewModel } from "@/domain/interviewer/candidate-list-by-interview/model/CandidateListByInterviewModel";
import { CandidateListByInterviewRequestDto } from "@/domain/interviewer/candidate-list-by-interview/dto/CandidateListByInterviewRequestDto";

const paginationMapper = new PaginationMapper();

export class CandidateListByInterviewMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CandidateListByInterviewResponseDto): Result<CandidateListByInterviewModel[]> {
    try {
      const candidateList: CandidateListByInterviewModel[] = [];
      if (dto.data)
        if (dto.data.length > 0) {
          for (const item of dto.data) {
            const candidateListModel: CandidateListByInterviewModel = {
              candidate: {
                candidateId: Number(item.id),
                firstName: item.attributes.firstName,
                lastName: item.attributes.lastName,
                fullName: item.attributes.fullName,
                email: item.attributes.email,
                candidateStatus: item.attributes.candidateStatus,
                candidateInterviewStatus: item.attributes.candidateInterviewStatus,
                lastActionAt: item.attributes.lastActionAt,
              },
              page: 0,
              pageSize: 0,
              pagination: dto.meta?.pagination,
            };

            candidateList.push(candidateListModel);
          }
          return Result.ok(candidateList);
        }
      return Result.ok(candidateList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_LIST_BY_INTERVIEW))
      );
    }
  }

  mapToDTO(domain: CandidateListByInterviewModel): Result<CandidateListByInterviewRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const candidateListRequest = new CandidateListByInterviewRequestDto(
        pagiRequest,
        domain.candidate.interviewId,
        domain.searchQuery,
        domain.candidate.candidateStatus,
        domain.sort
      );

      return Result.ok(candidateListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_LIST_BY_INTERVIEW))
      );
    }
  }
}
