import { MAPPER } from "@/application/constants/Mapper";
import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { StatisticResponseDto } from "@/domain/statistic/dto/StatisticResponseDto";
import { StatisticModel } from "@/domain/statistic/model/StatisticModel";

export class StatisticMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: StatisticResponseDto): Result<StatisticModel> {
    try {
      const statisticModel: StatisticModel = new StatisticModel(
        dto.data.attributes.weeklyTransactionSummary,
        dto.data.attributes.totalTransactionCount,
        dto.data.attributes.todayTransactionCount,
        dto.data.attributes.activeModuleCount,
        dto.data.attributes.tenantUserCount
      );

      return Result.ok(statisticModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STATISTIC))
      );
    }
  }

  mapToDTO(domain: StatisticModel): Result<StatisticModel> {
    return Result.ok(domain);
  }
}
