import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import RevokeInterviewInviteService from "../service/RevokeInterviewInviteService";
import { IRevokeInterviewInviteRepository } from "@/domain/interviewer/revoke-interview-invite/repository/IRevokeInterviewInviteRepository";
import { RevokeInterviewInviteRequestDto } from "@/domain/interviewer/revoke-interview-invite/dto/RevokeInterviewInviteRequestDto";

const sendLinkService = new RevokeInterviewInviteService();

export class RevokeInterviewInviteRepository implements IRevokeInterviewInviteRepository {
  async interviewInvite(
    request: RevokeInterviewInviteRequestDto
  ): Promise<Result<any>> {
    try {
      return await sendLinkService
        .invite(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.REVOKE_INTERVIEW_INVITE))
      );
    }
  }
}
