export const MAPPER = {
  LOGIN: "LoginMapper",
  RESET_PASSWORD: "ResetPasswordMapper",
  FORGOT_PASSWORD: "ForgotPasswordMapper",
  GENERATE_OTP: "GenerateOTPMapper",
  VERIFY_OTP: "VerifyOTPMapper",
  COUNTRY_LIST: "CountryListMapper",
  UPDATE_USER: "UpdateUserMapper",
  ABOUT_USER: "AboutUserMapper",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateMapper",
  SEND_MESSAGE: "SendMessageMapper",
  RETRY_ANSWER: "RetryAnswerMapper",
  SESSION_LIST: "SessionListMapper",
  TENANT_USERS_SESSION_LIST: "TenantUsersSessionListMapper",
  FILE_UPLOAD: "FileUploadMapper",

  STAFF: {
    STAFF_LIST: "StaffListMapper",
    ADD_STAFF: "AddStaffMapper",
    DELETE_STAFF: "DeleteStaffMapper",
    INVITE_STAFF: "InviteStaffMapper",
    ACCEPT_INVITE: "AcceptInviteMapper",
  },
  PRODUCT: {
    PRODUCT_LIST: "ProductListMapper",
    BUY_PRODUCT: "BuyProductMapper",
    UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingMapper",
    GET_PRODUCT: "GetProductMapper",
    GET_PURCHASED_PRODUCT: "GetPurchasedProductMapper",
  },
  STATISTIC: "StatisticMapper",
  POLLSTER: {
    CREATE_POLL: "CreatePollMapper",
    POLL_LIST: "PollListMapper",
    STATISTIC: {
      POLL_LIST: "StatisticPollListMapper",
      POLL_MESSAGE_LIST: "StatisticPollMessageListMapper",
      POLL_RESPONSE_LIST: "StatisticPollResponseListMapper",
      POLL_SESSION_DETAIL: "StatisticPollSessionDetailMapper",
    },
    POLL_DETAIL: "PollDetailMapper",
    DELETE_POLL: "DeletePollMapper",
    UPDATE_POLL: "UpdatePollMapper",
    INVITE_USER: "InviteUserMapper",
  },
  INTERVIEW: {
    CREATE_INTERVIEW: "CreateJobPostingMapper",
    CREATE_JOBPOSTING: "CreateJobPostingMapper",
    JOBPOSTING_LIST: "JobPostingListMapper",
    INTERVIEW_LIST: "InterviewListMapper",
    CANDIDATE_LIST: "CandidateListMapper",
    CANDIDATE_ACTION_LIST: "CandidateActionListMapper",
    JOBPOSTING_DETAIL: "JobPostingDetailMapper",
    INTERVIEW_DETAIL: "InterviewDetailMapper",
    CANDIDATE_DETAIL: "CandidateDetailMapper",
    DELETE_JOBPOSTING: "DeleteJobPostingMapper",
    DELETE_INTERVIEW: "DeleteInterviewMapper",
    UPDATE_JOBPOSTING: "UpdateJobPostingMapper",
    UPDATE_JOBPOSTING_STATUS: "UpdateJobPostingStatusMapper",
    UPDATE_INTERVIEW: "UpdateInterviewMapper",
    ADD_CANDIDATE: "AddCandidateMapper",
    DELETE_CANDIDATE: "DeleteCandidateMapper",
    UPDATE_CANDIDATE: "UpdateCandidateMapper",
    GET_MULTI_CANDIDATE_TEMPLATE: "GetMultipleCandidateTemplateMapper",
    ADD_QUALIFICATION: "AddQualificationMapper",
    REMOVE_QUALIFICATION: "RemoveQualificationMapper",
    ADD_INTERVIEW_QUESTION: "AddQuestionMapper",
    ADD_INTERVIEW_QUESTION_OPTION: "AddQuestionOptionMapper",
    UPDATE_INTERVIEW_QUESTION: "UpdateQuestionMapper",
    DELETE_INTERVIEW_QUESTION: "DeleteQuestionMapper",
    DELETE_INTERVIEW_QUESTION_OPTION: "DeleteQuestionOptionMapper",
    INTERVIEW_COUNTS: "InterviewCountMapper",
    INTERVIEW_INVITE: "InterviewInviteMapper",
    REVOKE_INTERVIEW_INVITE: "RevokeInterviewInviteMapper",
    CANDIDATE_LIST_BY_INTERVIEW: "CandidateListByInterviewMapper",
    JOBPOSTING_DETAIL_BY_INVITE: "JobPostingDetailByInviteKeyMapper",
    CANDIDATE_CV_ANALYSIS: "CandidateCvAnalysisMapper",
    CANDIDATE_INTERVIEW_RESPONSES: "CandidateInterviewResponsesMapper",
    CANDIDATE_INTERVIEW_LOGS: "CandidateInterviewLogMapper",
  }
};
