import { BaseDomain } from "@/core/base/BaseDomain";
import { SurveyQuestionsModel } from "../../create-poll/model/SurveyQuestionsModel";

export class PollDetailModel extends BaseDomain {
  id?: number;
  title?: string;
  description?: string;
  status?: string;
  startDate?: Date;
  endDate?: Date;
  isPrivate?: boolean;
  sessionCount?: number;
  surveyQuestions: SurveyQuestionsModel[];
  
  constructor(
    id?: number,
    title?: string,
    description?: string,
    status?: string,
    startDate?: Date,
    endDate?: Date,
    isPrivate?: boolean,
    sessionCount?: number,
    surveyQuestions?: SurveyQuestionsModel[]
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.status = status;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isPrivate = isPrivate;
    this.sessionCount = sessionCount;
    if (surveyQuestions) this.surveyQuestions = surveyQuestions;
    else {
      this.surveyQuestions = [];
    }
  }
}
