import { BaseDTO } from "@/core/base/BaseDTO";

export class VerifyOTPRequestDto extends BaseDTO {
  phoneNumber?: string;
  countryCode?: number;
  otp?: string;

  constructor(phoneNumber?: string, countryCode?: number, otp?: string) {
    super();
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
    this.otp = otp;
  }
}
