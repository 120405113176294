import { BaseDTO } from "@/core/base/BaseDTO";

export class InviteStaffRequestDto extends BaseDTO {
  data: {
    phoneNumber?: string;
    countryCode?: number;
    department?: string;
    position?: string;
  }
  locale?: string;
  
  constructor(
    phoneNumber?: string,
    countryCode?: number,
    department?: string,
    position?: string,
    locale?: string
  ) {
    super();
    this.data = {
      phoneNumber,
      countryCode,
      department,
      position
    }
    this.locale = locale;
  }
  
  toQueryString(): string {
    const queryString = `?locale=${this.locale}`;

    return queryString;
  }
}
