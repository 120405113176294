import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import GenerateOTPService from "../service/GenerateOTPService";
import { IGenerateOTPRepository } from "@/domain/auth/generate-otp/repository/IGenerateOTPRepository";
import { GenerateOTPRequestDto } from "@/domain/auth/generate-otp/dto/GenerateOTPRequestDto";
import { GenerateOTPResponseDto } from "@/domain/auth/generate-otp/dto/GenerateOTPResponseDto";

const generateOTPService = new GenerateOTPService();

export class GenerateOTPRepository implements IGenerateOTPRepository {
  async generateOTP(
    request: GenerateOTPRequestDto
  ): Promise<Result<GenerateOTPResponseDto>> {
    try {
      return await generateOTPService
        .generate(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<GenerateOTPResponseDto>(response.getValue());
          } else {
            return Result.fail<GenerateOTPResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<GenerateOTPResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<GenerateOTPResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.GENERATE_OTP))
      );
    }
  }
}
