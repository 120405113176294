import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { IRetryAnswerRepository } from "@/domain/hotel-management/retry-answer/repository/IRetryAnswerRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import RetryAnswerService from "../service/RetryAnswerService";
import { RetryAnswerRequestDto } from "@/domain/hotel-management/retry-answer/dto/RetryAnswerRequestDto";
import { RetryAnswerResponseDto } from "@/domain/hotel-management/retry-answer/dto/RetryAnswerResponseDto";

const retryAnswerService = new RetryAnswerService();

export class RetryAnswerRepository implements IRetryAnswerRepository {
  async retryAnswer(request: RetryAnswerRequestDto): Promise<Result<RetryAnswerResponseDto>> {
    try {
      return await retryAnswerService
        .retry(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<RetryAnswerResponseDto>(response.getValue());
          } else {
            return Result.fail<RetryAnswerResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.RETRY_ANSWER)));
    }
  }
}
