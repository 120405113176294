import { BaseDTO } from "@/core/base/BaseDTO";

export class CandidateCvAnalysisRequestDto extends BaseDTO {
  data: {
    candidate?: number;
  }
  constructor(
    candidate?: number,
  ) {
    super();
    this.data = {
      candidate,
    }
  }
}
