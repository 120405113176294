import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { IUpdatePollRepository } from "@/domain/pollster/update-poll/repository/IUpdatePollRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import UpdatePollService from "../service/UpdatePollService";
import { UpdatePollRequestDto } from "@/domain/pollster/update-poll/dto/UpdatePollRequestDto";

const updatePollService = new UpdatePollService();

export class UpdatePollRepository implements IUpdatePollRepository {
  async updatePoll(request: UpdatePollRequestDto): Promise<Result<any>> {
    try {
      return await updatePollService
        .update(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.UPDATE_POLL)));
    }
  }
}
