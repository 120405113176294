
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { CandidateDetailMapper } from "../mapper/CandidateDetailMapper";
import { ICandidateDetailUseCase } from "@/domain/interviewer/candidate-detail/usecase/ICandidateDetailUseCase";
import { CandidateDetailRepository } from "../repository/CandidateDetailRepository";
import { ICandidateDetailRepository } from "@/domain/interviewer/candidate-detail/repository/ICandidateDetailRepository";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";

export class CandidateDetailUseCase implements ICandidateDetailUseCase {
  getCandidateDetailRepository : ICandidateDetailRepository = new CandidateDetailRepository();
  getCandidateDetailMapper = new CandidateDetailMapper ();

  constructor() {
  }

  async execute(model: CandidateDetailModel): Promise<Result<CandidateDetailModel>> {
    try {
      const dto = this.getCandidateDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<CandidateDetailModel>(dto.getError());
      } else {
        const response = await this.getCandidateDetailRepository.getCandidateDetail(dto);

        if (response.isSuccess) {
          const domain = this.getCandidateDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CandidateDetailModel>(domain.getValue());
        } else {
          return Result.fail<CandidateDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_DETAIL))
      );
    }
  }
}
