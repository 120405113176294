import { BaseDTO } from "@/core/base/BaseDTO";

export class InterviewCountRequestDto extends BaseDTO {
  jobPostingId?: number;
  interviewId?: number;

  constructor(  jobPostingId?: number,
    interviewId?: number,
  ) {
    super();
    this.jobPostingId = jobPostingId;
    this.interviewId = interviewId;
  }

  toQueryString(): string {
    if(this.jobPostingId)
    {
      let queryString = `?jobPostingId=${this.jobPostingId}`;
      return queryString;
    }
    else {
      let queryString = `?interviewId=${this.interviewId}`;
      return queryString;
    }
  }
}
