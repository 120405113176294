import { InviteUserRepository } from "../repository/InviteUserRepository";
import { InviteUserMapper } from "../mapper/InviteUserMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IInviteUserUseCase } from "@/domain/pollster/Invite-user/usecase/IInviteUserUsecase";
import { InviteUserModel } from "@/domain/pollster/Invite-user/model/InviteUserModel";
import { IInviteUserRepository } from "@/domain/pollster/Invite-user/repository/IInviteUserRepository";

export class InviteUserUsecase implements IInviteUserUseCase {
  inviteUserRepository : IInviteUserRepository = new InviteUserRepository();
  inviteUserMapper = new InviteUserMapper();

  constructor() {
  }

  async execute(model: InviteUserModel): Promise<Result<InviteUserModel>> {
    try {
      const dto = this.inviteUserMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<InviteUserModel>(dto.getError());
      } else {
        const response = await this.inviteUserRepository.inviteUser(dto);

        if (response.isSuccess) {
          const domain = this.inviteUserMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<InviteUserModel>(domain.getValue());
        } else {
          return Result.fail<InviteUserModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<InviteUserModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.INVITE_USER))
      );
    }
  }
}
