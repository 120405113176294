
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { DeleteQuestionRepository } from "../repository/DeleteQuestionRepository";
import { DeleteQuestionMapper } from "../mapper/DeleteQuestionMapper";
import { IDeleteQuestionUseCase } from "@/domain/interviewer/update-interview/deleteQuestion/usecase/IDeleteQuestionUseCase";
import { IDeleteQuestionRepository } from "@/domain/interviewer/update-interview/deleteQuestion/repository/IDeleteQuestionRepository";
import { DeleteQuestionModel } from "@/domain/interviewer/update-interview/deleteQuestion/model/DeleteQuestionModel";

export class DeleteQuestionUseCase implements IDeleteQuestionUseCase {
  deleteQuestionRepository : IDeleteQuestionRepository = new DeleteQuestionRepository();
  deleteQuestionMapper = new DeleteQuestionMapper();

  constructor() {
  }

  async execute(model: DeleteQuestionModel): Promise<Result<DeleteQuestionModel>> {
    try {
      const dto = this.deleteQuestionMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteQuestionModel>(dto.getError());
      } else {
        const response = await this.deleteQuestionRepository.deleteQuestion(dto);

        if (response.isSuccess) {
          const domain = this.deleteQuestionMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteQuestionModel>(domain.getValue());
        } else {
          return Result.fail<DeleteQuestionModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteQuestionModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.DELETE_INTERVIEW_QUESTION))
      );
    }
  }
}
