import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import JobPostingDetailByInviteService from "../service/JobPostingDetailByInviteService";
import { IJobPostingDetailByInviteRepository } from "@/domain/interviewer/job-posting-detail-by-invite-key/repository/IJobPostingDetailByInviteRepository";
import { JobPostingDetailByInviteResponseDto } from "@/domain/interviewer/job-posting-detail-by-invite-key/dto/JobPostingDetailByInviteResponseDto";
import { JobPostingDetailByInviteRequestDto } from "@/domain/interviewer/job-posting-detail-by-invite-key/dto/JobPostingDetailByInviteRequestDto";
const jobPostingDetailService = new JobPostingDetailByInviteService();

export class JobPostingDetailByInviteRepository implements IJobPostingDetailByInviteRepository {
  async getJobPostingDetailByInvite(request: JobPostingDetailByInviteRequestDto): Promise<Result<JobPostingDetailByInviteResponseDto>> {
    try {
      return await jobPostingDetailService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<JobPostingDetailByInviteResponseDto>(response.getValue());
          } else {
            return Result.fail<JobPostingDetailByInviteResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.JOBPOSTING_DETAIL_BY_INVITE)));
    }
  }
}
