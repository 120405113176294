import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import FileUploadService from "../service/FileUploadService";
import { IFileUploadRepository } from "@/domain/file-upload/repository/IFileUploadRepository";
import { FileUploadRequestDto } from "@/domain/file-upload/dto/FileUploadRequestDto";
import { FileUploadResponseDto } from "@/domain/file-upload/dto/FileUploadResponseDto";

const fileUploadService = new FileUploadService();

export class FileUploadRepository implements IFileUploadRepository {
  async fileUpload(
    request: FileUploadRequestDto
  ): Promise<Result<FileUploadResponseDto>> {
    try {
      return await fileUploadService
        .upload(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<FileUploadResponseDto>(response.getValue());
          } else {
            return Result.fail<FileUploadResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<FileUploadResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<FileUploadResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.FILE_UPLOAD))
      );
    }
  }
}
