
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { CandidateInterviewResponsesMapper } from "../mapper/CandidateInterviewResponsesMapper";
import { CandidateInterviewResponsesRepository } from "../repository/CandidateInterviewResponsesRepository";
import { ICandidateInterviewResponsesUseCase } from "@/domain/interviewer/candidate-interview-responses/usecase/ICandidateInterviewResponsesUsecase";
import { ICandidateInterviewResponsesRepository } from "@/domain/interviewer/candidate-interview-responses/repository/ICandidateInterviewResponsesRepository";
import { CandidateInterviewResponsesModel } from "@/domain/interviewer/candidate-interview-responses/model/CandidateInterviewResponsesModel";

export class CandidateInterviewResponsesUsecase implements ICandidateInterviewResponsesUseCase {
  candidateCvAnalysisRepository : ICandidateInterviewResponsesRepository = new CandidateInterviewResponsesRepository();
  candidateCvAnalysisMapper = new CandidateInterviewResponsesMapper();

  constructor() {
  }

  async execute(model: CandidateInterviewResponsesModel): Promise<Result<CandidateInterviewResponsesModel>> {

    try {
      const dto = this.candidateCvAnalysisMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<CandidateInterviewResponsesModel>(dto.getError());
      } else {
        const response = await this.candidateCvAnalysisRepository.candidateCvAnalysis(dto);

        if (response.isSuccess) {
          const domain = this.candidateCvAnalysisMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CandidateInterviewResponsesModel>(domain.getValue());
        } else {
          return Result.fail<CandidateInterviewResponsesModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateInterviewResponsesModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_INTERVIEW_RESPONSES))
      );
    }
  }
}
