import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";

export class CandidateListByInterviewModel extends BaseDomain {
  candidate: {
    interviewId?: number;
    candidateId?: number;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    candidateStatus?: string;
    candidateInterviewStatus?: string;
    lastActionAt?: Date;
  };
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  searchQuery?: string;
  sort?: string;

  constructor(
    interviewId?: number,
    candidateId?: number,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    email?: string,
    candidateStatus?: string,
    candidateInterviewStatus?: string,
    lastActionAt?: Date,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto,
    searchQuery?: string,
    sort?: string,
  ) {
    super();
    this.candidate = {
      interviewId,
      candidateId,
      firstName,
      lastName,
      fullName,
      email,
      candidateStatus,
      candidateInterviewStatus,
      lastActionAt,
    };
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
    this.searchQuery = searchQuery;
    this.sort = sort;
  }
}
