import { BaseDomain } from "@/core/base/BaseDomain";

export class AddCandidateModel extends BaseDomain {
  ok?: boolean;
  jobPosting?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  cv?: File;
  status?: string;

  constructor(
    jobPosting?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    cv?: File,
    ok?: boolean,
    status?: string,
  ) {
    super();
    this.jobPosting = Number(jobPosting);
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.ok = ok;
    this.cv = cv;
    this.status = status;
  }
}
