import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import UpdateQuestionService from "../service/UpdateQuestionService";
import { IUpdateQuestionRepository } from "@/domain/interviewer/update-interview/updateQuestion/repository/IUpdateQuestionRepository";
import { UpdateQuestionRequestDto } from "@/domain/interviewer/update-interview/updateQuestion/dto/UpdateQuestionRequestDto";

const updateJobPostingService = new UpdateQuestionService();

export class UpdateQuestionRepository implements IUpdateQuestionRepository {
  async updateQuestion(request: UpdateQuestionRequestDto): Promise<Result<any>> {
    try {
      return await updateJobPostingService
        .update(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.UPDATE_INTERVIEW_QUESTION)));
    }
  }
}
