
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IUpdateCandidateUseCase } from "@/domain/interviewer/update-candidate/usecase/IUpdateCandidateUseCase";
import { IUpdateCandidateRepository } from "@/domain/interviewer/update-candidate/repository/IUpdateCandidateRepository";
import { UpdateCandidateRepository } from "../repository/UpdateCandidateRepository";
import { UpdateCandidateMapper } from "../mapper/UpdateCandidateMapper";
import { UpdateCandidateModel } from "@/domain/interviewer/update-candidate/model/UpdateCandidateModel";

export class UpdateCandidateUseCase implements IUpdateCandidateUseCase {
  updateCandidateRepository: IUpdateCandidateRepository = new UpdateCandidateRepository();
  updateCandidateMapper = new UpdateCandidateMapper();

  constructor() {}

  async execute(model: UpdateCandidateModel): Promise<Result<UpdateCandidateModel>> {
    try {
      const dto = this.updateCandidateMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateCandidateModel>(dto.getError());
      } else {
        const response = await this.updateCandidateRepository.updateCandidate(dto);

        if (response.isSuccess) {
          const domain = this.updateCandidateMapper.mapToDomain(response.getValue());

          return Result.ok<UpdateCandidateModel>(domain.getValue());
        } else {
          return Result.fail<UpdateCandidateModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateCandidateModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.UPDATE_CANDIDATE))
      );
    }
  }
}
