import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IPollSessionDetailService } from "@/domain/pollster/poll-statistic/poll-session-detail/service/IPollSessionDetailService";
import { PollSessionDetailRequestDto } from "@/domain/pollster/poll-statistic/poll-session-detail/dto/PollSessionDetailRequestDto";
import { PollSessionDetailResponseDto } from "@/domain/pollster/poll-statistic/poll-session-detail/dto/PollSessionDetailResponseDto";

export default class PollSessionDetailService
  implements IPollSessionDetailService
{
  async get(
    request: PollSessionDetailRequestDto
  ): Promise<Result<PollSessionDetailResponseDto>> {
    try {
      return await instance
        .get<PollSessionDetailResponseDto>(
          API_ROUTE.SURVEYS_SESSIONS + request.id + API_ROUTE.META_DATA
        )
        .then((response) => {
          return Result.ok<PollSessionDetailResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<PollSessionDetailResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(
                  SWAL_MESSAGES.ERROR.ERROR_POLL_STATISTICS.SESSION_DETAIL
                )
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<PollSessionDetailResponseDto>(
        new ServiceException(
          new UnknownCause(SERVICE.POLLSTER.STATISTIC.POLL_SESSION_DETAIL)
        )
      );
    }
  }
}
