import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { GetProductRequestDto } from "@/domain/product/get-product/dto/GetProductRequestDto";
import { GetProductModel } from "@/domain/product/get-product/model/GetProductModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { GetProductResponseDto } from "@/domain/product/get-product/dto/GetProductResponseDto";
import { ProductPackageModel } from "@/domain/product/product-list/model/ProductPackageModel";
import { ProductModelSettingModel } from "@/domain/product/product-list/model/ProductModelSettingModel";
import { ProductPurchaseModel } from "@/domain/product/product-list/model/ProductPurchaseModel";
import { ProductFAQModel } from "@/domain/product/product-list/model/ProductFAQModel";

export class GetProductMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: GetProductResponseDto): Result<GetProductModel> {
    try {
      const productPackages: ProductPackageModel[] =
        dto.data.attributes.productPackages?.data.map((pkg) => {
          const productModelSettings: ProductModelSettingModel[] =
            pkg.attributes.productModelSettings?.data.map((setting) => {
              return new ProductModelSettingModel(
                Number(setting.id),
                setting.attributes.name,
                setting.attributes.description,
                setting.attributes.selected
              );
            });

          const productPurchaseDetail: ProductPurchaseModel = {};

          if (
            pkg.attributes.purchase &&
            pkg.attributes.purchase.data &&
            pkg.attributes.purchase?.data.attributes
          ) {
            productPurchaseDetail.endDate =
              pkg.attributes.purchase?.data.attributes.endDate;
            productPurchaseDetail.startDate =
              pkg.attributes.purchase?.data.attributes.startDate;
            productPurchaseDetail.remainingQuota =
              pkg.attributes.purchase?.data.attributes.remainingQuota;
          }

          return new ProductPackageModel(
            productModelSettings,
            Number(pkg.id),
            pkg.attributes.name,
            Number(pkg.attributes.price),
            Number(pkg.attributes.currency),
            pkg.attributes.description,
            Number(pkg.attributes.period),
            Number(pkg.attributes.quota),
            pkg.attributes.purchased,
            productPurchaseDetail
          );
        });

      const productFAQs: ProductFAQModel[] =
        dto.data.attributes.productFAQs?.data.map((pfaq) => {
          return new ProductFAQModel(
            Number(pfaq.id),
            pfaq.attributes.question,
            pfaq.attributes.answer
          );
        });

      const getProductModel: GetProductModel = {
        id: Number(dto.data.id),
        shortCode: dto.data.attributes.shortCode,
        name: dto.data.attributes.name,
        description: dto.data.attributes.description,
        productPackages: productPackages,
        productFAQs: productFAQs,
      };

      return Result.ok(getProductModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.GET_PRODUCT))
      );
    }
  }

  mapToDTO(domain: GetProductModel): Result<GetProductRequestDto> {
    try {
      const getProductRequest = new GetProductRequestDto(
        domain.shortCode,
        domain.locale
      );

      return Result.ok(getProductRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.GET_PRODUCT))
      );
    }
  }
}
