import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { AxiosInstance } from "axios";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import i18n from "@/presentation/language/i18n";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
const jwtTokenManager = new JwtTokenManager();

export default function ResponseInterceptor(
  axiosInstance: AxiosInstance
): void {
  const swalNotification = new SwalNotification();

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        jwtTokenManager.destroyToken();
        window.location.href = "/" + ROUTE_PAGES.SIGN_IN;

        swalNotification.error(
          i18n.global.t(SWAL_MESSAGES.ERROR.SESSION_TIME_OUT),
          i18n.global.t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      }
      return Promise.reject(error);
    }
  );
}
