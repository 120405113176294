
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IUpdateJobPostingUseCase } from "@/domain/interviewer/update-job-posting/usecase/IUpdateJobPostingUseCase";
import { UpdateJobPostingRepository } from "../repository/UpdateJobPostingRepository";
import { UpdateJobPostingMapper } from "../mapper/UpdateJobPostingMapper";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { IUpdateJobPostingRepository } from "@/domain/interviewer/update-job-posting/repository/IUpdateJobPostingRepository";

export class UpdateJobPostingUseCase implements IUpdateJobPostingUseCase {
  updateJobPostingRepository: IUpdateJobPostingRepository = new UpdateJobPostingRepository();
  updateJobPostingMapper = new UpdateJobPostingMapper();

  constructor() {}

  async execute(model: UpdateJobPostingModel): Promise<Result<UpdateJobPostingModel>> {
    try {
      const dto = this.updateJobPostingMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateJobPostingModel>(dto.getError());
      } else {
        const response = await this.updateJobPostingRepository.updateJobPosting(dto);

        if (response.isSuccess) {
          const domain = this.updateJobPostingMapper.mapToDomain(response.getValue());

          return Result.ok<UpdateJobPostingModel>(domain.getValue());
        } else {
          return Result.fail<UpdateJobPostingModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateJobPostingModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.UPDATE_JOBPOSTING))
      );
    }
  }
}
