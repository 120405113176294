import { UpdateUserRepository } from "../repository/UpdateUserRepository";
import { UpdateUserMapper } from "../mapper/UpdateUserMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IUpdateUserUseCase } from "@/domain/user/update-user/usecase/IUpdateUserUsecase";
import { UpdateUserModel } from "@/domain/user/update-user/model/UpdateUserModel";
import { IUpdateUserRepository } from "@/domain/user/update-user/repository/IUpdateUserRepository";

export class UpdateUserUsecase implements IUpdateUserUseCase {
  updateUserRepository: IUpdateUserRepository = new UpdateUserRepository();
  updateUserMapper = new UpdateUserMapper();

  constructor() {
  }

  async execute(model: UpdateUserModel): Promise<Result<UpdateUserModel>> {
    try {
      const dto = this.updateUserMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<UpdateUserModel>(dto.getError());
      } else {
        const response = await this.updateUserRepository.updateUser(dto);

        if (response.isSuccess) {
          const domain = this.updateUserMapper.mapToDomain(response.getValue());
          return Result.ok<UpdateUserModel>(domain.getValue());
        } else {
          return Result.fail<UpdateUserModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateUserModel>(
        new UseCaseException(new UnknownCause(USECASE.UPDATE_USER))
      );
    }
  }
}
