import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { PollListUseCase } from "@/application/pollster/poll-list/usecase/PollListUseCase";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import { CreatePollUsecase } from "@/application/pollster/create-poll/usecase/CreatePollUseCase";
import { CreatePollModel } from "@/domain/pollster/create-poll/model/CreatePollModel";
import { DeletePollModel } from "@/domain/pollster/delete-poll/model/DeletePollModel";
import { DeletePollUseCase } from "@/application/pollster/delete-poll/usecase/DeletePollUseCase";
import { UpdatePollModel } from "@/domain/pollster/update-poll/model/UpdatePollModel";
import { UpdatePollUseCase } from "@/application/pollster/update-poll/usecase/UpdatePollUseCase";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";
import { PollDetailUseCase } from "@/application/pollster/poll-detail/usecase/PollDetailUseCase";
import { InviteUserModel } from "@/domain/pollster/Invite-user/model/InviteUserModel";
import { InviteUserUsecase } from "@/application/pollster/invite-user/usecase/InviteUserUseCase";
import { StatisticPollListUseCase } from "@/application/pollster/poll-statistic/poll-list/usecase/PollListUseCase";
import { StatisticPollListModel } from "@/domain/pollster/poll-statistic/poll-list/model/PollListModel";
import { PollSessionDetailUseCase } from "@/application/pollster/poll-statistic/poll-session-detail/usecase/PollSessionDetailUseCase";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";
import { PollMessageListUseCase } from "@/application/pollster/poll-statistic/poll-message-list/usecase/PollMessageListUseCase";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { PollResponseListUseCase } from "@/application/pollster/poll-statistic/poll-response-list/usecase/PollResponseListUseCase";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";

export function createPollController() {
  const useCases = {
    pollList: {
      useCase: new PollListUseCase(),
      model: PollListModel,
    },
    createPoll: {
      useCase: new CreatePollUsecase(),
      model: CreatePollModel,
    },
    deletePoll: {
      useCase: new DeletePollUseCase(),
      model: DeletePollModel,
    },
    updatePoll: {
      useCase: new UpdatePollUseCase(),
      model: UpdatePollModel,
    },
    getPollDetail: {
      useCase: new PollDetailUseCase(),
      model: PollDetailModel,
    },
    inviteUser: {
      useCase: new InviteUserUsecase(),
      model: InviteUserModel,
    },
    statisticPollList: {
      useCase: new StatisticPollListUseCase(),
      model: StatisticPollListModel,
    },
    pollSessionDetail: {
      useCase: new PollSessionDetailUseCase(),
      model: PollSessionDetailModel,
    },
    pollSessionMessages: {
      useCase: new PollMessageListUseCase(),
      model: PollMessageListModel,
    },
    pollResponseList: {
      useCase: new PollResponseListUseCase(),
      model: PollResponseListModel,
    },
  };

  return new PollController(useCases);
}

export class PollController implements IController {
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}
