import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { JobPostingDetailResponseDto } from "@/domain/interviewer/job-posting-detail/dto/JobPostingDetailResponseDto";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingDetailRequestDto } from "@/domain/interviewer/job-posting-detail/dto/JobPostingDetailRequestDto";
import { JobPostingQualificationModel } from "@/domain/interviewer/job-posting-list/model/JobPostingQualificationModel";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";

export class JobPostingDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: JobPostingDetailResponseDto): Result<JobPostingDetailModel> {
    try {
      if (dto.data) {
        const jobPostingQualifications: JobPostingQualificationModel[] = [];
        if (
          dto.data.attributes.jobPostingQualifications &&
          dto.data.attributes.jobPostingQualifications.data
        ) {
          dto.data.attributes.jobPostingQualifications.data.forEach(
            (qualification) => {
              const text = qualification.attributes.text;
              const id = qualification.id;
              if (text && id) {
                jobPostingQualifications.push({ id, text });
              }
            }
          );
        }

        const jobPostingInterviews: JobPostingInterviewModel[] = [];
        if (
          dto.data.attributes.interviews &&
          dto.data.attributes.interviews.data
        ) {
          dto.data.attributes.interviews.data.forEach((interview) => {
            const id = interview.id;
            const title = interview.attributes.title;
            const description = interview.attributes.description;
            const sequence = interview.attributes.sequence;

            const jobPostingInterview = new JobPostingInterviewModel(
              id,
              title,
              description,
              sequence
            );
            jobPostingInterviews.push(jobPostingInterview);
          });
        }

        const jobpostingDetailModel: JobPostingDetailModel = {
          id: dto.data.id,
          title: dto.data.attributes.title,
          description: dto.data.attributes.description,
          status: dto.data.attributes.status,
          startDate: dto.data.attributes.startDate,
          endDate: dto.data.attributes.endDate,
          candidateCount: dto.data.attributes.candidateCount,
          jobPostingQualifications: jobPostingQualifications,
          hasCompletedInterviewSession:
            dto.data.attributes.hasCompletedInterviewSession,
          hasCvAnalysis: dto.data.attributes.hasCvAnalysis,
          interviews: jobPostingInterviews,
        };

        return Result.ok(jobpostingDetailModel);
      } else {
        const jobpostingDetailModel: JobPostingDetailModel = {};
        return Result.ok(jobpostingDetailModel);
      }
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.JOBPOSTING_DETAIL)
        )
      );
    }
  }

  mapToDTO(domain: JobPostingDetailModel): Result<JobPostingDetailRequestDto> {
    try {
      const interviewRequest: JobPostingDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(interviewRequest);
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.INTERVIEW.JOBPOSTING_DETAIL)
        )
      );
    }
  }
}
