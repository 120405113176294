import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { SessionListUseCase } from "@/application/hotel-management/session-list/usecase/SessionListUseCase";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { SendMessageUsecase } from "@/application/hotel-management/send-message/usecase/SendMessageUseCase";
import { SendMessageModel } from "@/domain/hotel-management/send-message/model/SendMessageModel";
import { RetryAnswerModel } from "@/domain/hotel-management/retry-answer/model/RetryAnswerModel";
import { RetryAnswerUseCase } from "@/application/hotel-management/retry-answer/usecase/RetryAnswerUseCase";
import { TenantUsersSessionListModel } from "@/domain/hotel-management/tenant-users-session-list/model/TenantUsersSessionListModel";
import { TenantUsersSessionListUseCase } from "@/application/hotel-management/tenant-users-session-list/usecase/TenantUsersSessionListUseCase";

export function createSessionController() {
  const useCases = {
    sessionList: {
      useCase: new SessionListUseCase(),
      model: SessionListModel,
    },
    tenantUsersSessionList: {
      useCase: new TenantUsersSessionListUseCase(),
      model: TenantUsersSessionListModel,
    },
    sendMessage: {
      useCase: new SendMessageUsecase(),
      model: SendMessageModel,
    },
    retryAnswer: {
      useCase: new RetryAnswerUseCase(),
      model: RetryAnswerModel,
    },
  };

  return new SessionController(useCases);
}

export class SessionController implements IController{
  private proxy: any;

  constructor(
    private useCases: Record<
      string,
      { useCase: any; model: new (...args: any[]) => any }
    >
  ) {
    this.proxy = new Proxy(this, {
      get(target, prop) {
        if (prop in target.useCases) {
          return async (data: any) => {
            const { useCase, model } = target.useCases[prop as string];
            const modelInstance = Object.assign(new model(), data);
            const response = await useCase.execute(modelInstance);
            if (response.isSuccess) {
              return response;
            } else {
              return Result.fail(response.getError());
            }
          };
        }
        return target[prop];
      },
    });

    return this.proxy;
  }
}