import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IStatisticPollListRepository } from "@/domain/pollster/poll-statistic/poll-list/repository/IPollListRepository";
import { StatisticPollListRequestDto } from "@/domain/pollster/poll-statistic/poll-list/dto/PollListRequestDto";
import { StatisticPollListResponseDto } from "@/domain/pollster/poll-statistic/poll-list/dto/PollListResponseDto";
import StatisticPollListService from "../service/PollListService";

const statisticPollListService = new StatisticPollListService

export class StatisticPollListRepository implements IStatisticPollListRepository {
  async getPollList(
    request: StatisticPollListRequestDto
  ): Promise<Result<StatisticPollListResponseDto>> {
    try {
      return await statisticPollListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<StatisticPollListResponseDto>(response.getValue());
          } else {
            return Result.fail<StatisticPollListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<StatisticPollListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.STATISTIC.POLL_LIST))
      );
    }
  }
}
