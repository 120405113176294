import { BaseDomain } from "@/core/base/BaseDomain";
import { TenantEstateModel } from "../../tenant-estate/model/TenantEstateModel";

export class AboutUserModel extends BaseDomain {
  id?: number;
  username?: string;
  email?: string;
  name?: string;
  surname?: string;
  phoneNumber?: string;
  remainingQuota?: number;
  activeTenantEstate?: TenantEstateModel;
  tenantEstates?: TenantEstateModel[];
  countryCode?: string;

  constructor(
    id?: number,
    username?: string,
    email?: string,
    name?: string,
    surname?: string,
    phoneNumber?: string,
    remainingQuota?: number,
    activeTenantEstate?: TenantEstateModel,
    tenantEstates?: TenantEstateModel[],
    countryCode?: string,
  ) {
    super();
    this.id = id;
    this.username = username;
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.phoneNumber = phoneNumber;
    this.remainingQuota = remainingQuota;
    this.activeTenantEstate = activeTenantEstate;
    this.tenantEstates = tenantEstates;
    this.countryCode = countryCode;
  }
}
