import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IInviteUserRepository } from "@/domain/pollster/Invite-user/repository/IInviteUserRepository";
import { InviteUserRequestDto } from "@/domain/pollster/Invite-user/dto/InviteUserRequestDto";
import { InviteUserResponseDto } from "@/domain/pollster/Invite-user/dto/InviteUserResponseDto";
import InviteUserService from "../service/InviteUserService";

const inviteUserService = new InviteUserService();

export class InviteUserRepository implements IInviteUserRepository {
  async inviteUser(
    request: InviteUserRequestDto
  ): Promise<Result<InviteUserResponseDto>> {
    try {
      return await inviteUserService
        .invite(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<InviteUserResponseDto>(response.getValue());
          } else {
            return Result.fail<InviteUserResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<InviteUserResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<InviteUserResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.INVITE_USER))
      );
    }
  }
}
