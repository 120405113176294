
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICreatePollUseCase } from "@/domain/pollster/create-poll/usecase/ICreatePollUsecase";
import { CreatePollRepository } from "../repository/CreatePollRepository";
import { CreatePollMapper } from "../mapper/CreatePollMapper";
import { CreatePollModel } from "@/domain/pollster/create-poll/model/CreatePollModel";
import { ICreatePollRepository } from "@/domain/pollster/create-poll/repository/ICreatePollRepository";

export class CreatePollUsecase implements ICreatePollUseCase {
  createPollsterRepository : ICreatePollRepository = new CreatePollRepository();
  createPollsterMapper = new CreatePollMapper();

  constructor() {
  }

  async execute(model: CreatePollModel): Promise<Result<CreatePollModel>> {
    try {
      const dto = this.createPollsterMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<CreatePollModel>(dto.getError());
      } else {
        const response = await this.createPollsterRepository.createPollster(dto);

        if (response.isSuccess) {
          const domain = this.createPollsterMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CreatePollModel>(domain.getValue());
        } else {
          return Result.fail<CreatePollModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CreatePollModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.CREATE_POLL))
      );
    }
  }
}
