import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { DeletePollRequestDto } from "@/domain/pollster/delete-poll/dto/DeletePollRequestDto";
import { IDeletePollService } from "@/domain/pollster/delete-poll/service/IDeletePollService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";

export default class DeletePollService implements IDeletePollService {
  async delete(
    request: DeletePollRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .delete<any>(API_ROUTE.SURVEYS + request.id)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_DELETE_POLL)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.POLLSTER.DELETE_POLL))
      );
    }
  }
}
