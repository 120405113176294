import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IFileUploadService } from "@/domain/file-upload/service/IFileUploadService";
import { FileUploadRequestDto } from "@/domain/file-upload/dto/FileUploadRequestDto";
import { FileUploadResponseDto } from "@/domain/file-upload/dto/FileUploadResponseDto";

export default class FileUploadService implements IFileUploadService {
  async upload(
    request: FileUploadRequestDto
  ): Promise<Result<FileUploadResponseDto>> {
    try {
      // const formData = new FormData()
      // formData.append("file", request.file)

      //---------------------
      // const file = request.formData.get("file");

      // if (file instanceof Blob) {
      //   const url = URL.createObjectURL(file);

      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download =
      //     file instanceof File && file.name ? file.name : "downloaded_file";

      //   document.body.appendChild(a);
      //   a.click();

      //   URL.revokeObjectURL(url);
      // }
      //---------------------
      
      //   formData.forEach((value, key) => {
      //     console.log(key, value);

      //     if (value instanceof File) {
      //         const reader = new FileReader();
      //         reader.onload = function(e) {
      //           if(e.target)
      //             console.log("e.target.result", e.target.result);
      //         };
      //         reader.readAsText(value);
      //     }
      // });

      return await instance
        .post<FileUploadResponseDto>(
          API_ROUTE.FILE_UPLOAD,
          request.formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // response.data.data.attributes.file = request.formData.get("files");
          return Result.ok<FileUploadResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<FileUploadResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_FILE_UPLOAD)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<FileUploadResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.FILE_UPLOAD))
      );
    }
  }
}
