import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { RevokeInterviewInviteModel } from "@/domain/interviewer/revoke-interview-invite/model/RevokeInterviewInviteModel";
import { RevokeInterviewInviteRequestDto } from "@/domain/interviewer/revoke-interview-invite/dto/RevokeInterviewInviteRequestDto";

export class RevokeInterviewInviteMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<RevokeInterviewInviteModel> {
    try {
      const createInterviewModel: RevokeInterviewInviteModel = {
        ok: dto.ok,
      };

      return Result.ok(createInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_INVITE))
      );
    }
  }

  mapToDTO(domain: RevokeInterviewInviteModel): Result<RevokeInterviewInviteRequestDto> {
    try {
      const createInterviewRequest = new RevokeInterviewInviteRequestDto(
        domain.interview,
        domain.candidate,
      );

      return Result.ok(createInterviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.REVOKE_INTERVIEW_INVITE))
      );
    }
  }
}
