import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import DeleteCandidateService from "../service/DeleteCandidateService";
import { IDeleteCandidateRepository } from "@/domain/interviewer/delete-candidate/repository/IDeleteCandidateRepository";
import { DeleteCandidateRequestDto } from "@/domain/interviewer/delete-candidate/dto/DeleteCandidateRequestDto";

const deleteCandidateService = new DeleteCandidateService();

export class DeleteCandidateRepository implements IDeleteCandidateRepository {
  async deleteInterview(request: DeleteCandidateRequestDto): Promise<Result<any>> {
    try {
      return await deleteCandidateService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.DELETE_CANDIDATE)));
    }
  }
}
