import { BaseDomain } from "@/core/base/BaseDomain";
import { SurveyQuestionsModel } from "@/domain/pollster/create-poll/model/SurveyQuestionsModel";

export class PollSessionDetailModel extends BaseDomain {
  id?: number;
  surveyTitle?: string;
  surveyDescription?: string;
  startDate?: Date;
  endDate?: Date;
  sessionDuration?: any;
  sessionCompletedPercentage?: number;
  status?: boolean;
  
  constructor(
    id?: number,
    surveyTitle?: string,
    surveyDescription?: string,
    startDate?: Date,
    endDate?: Date,
    sessionDuration?: any,
    sessionCompletedPercentage?: number,
    status?: boolean,
  ) {
    super();
    this.id = id;
    this.surveyTitle = surveyTitle;
    this.surveyDescription = surveyDescription;
    this.startDate = startDate;
    this.endDate = endDate;
    this.sessionDuration = sessionDuration;
    this.sessionCompletedPercentage = sessionCompletedPercentage;
    this.status = status;
  }
}
