import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IRevokeInterviewInviteService } from "@/domain/interviewer/revoke-interview-invite/service/IRevokeInterviewInviteService";
import { RevokeInterviewInviteRequestDto } from "@/domain/interviewer/revoke-interview-invite/dto/RevokeInterviewInviteRequestDto";

export default class RevokeInterviewInviteService implements IRevokeInterviewInviteService {
  async invite(
    request: RevokeInterviewInviteRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .post(API_ROUTE.REVOKE_INTERVIEW_INVITE, request)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INTERVIEW_INVITE)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.REVOKE_INTERVIEW_INVITE))
      );
    }
  }
}
