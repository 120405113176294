import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateJobPostingStatusModel } from "@/domain/interviewer/update-job-posting-status/model/UpdateJobPostingStatusModel";
import { UpdateJobPostingStatusRequestDto } from "@/domain/interviewer/update-job-posting-status/dto/UpdateJobPostingStatusRequestDto";

export class UpdateJobPostingStatusMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateJobPostingStatusModel> {
    try {
        const updateJobPostingStatusModel: UpdateJobPostingStatusModel = {
          ok: dto.ok,
        };
        return Result.ok(updateJobPostingStatusModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_JOBPOSTING))
      );
    }
  }

  mapToDTO(domain: UpdateJobPostingStatusModel): Result<UpdateJobPostingStatusRequestDto> {
    try {
      const updateJobPostingStatus: UpdateJobPostingStatusRequestDto = {
        id: domain.id,
        data: {
          isActive: domain.isActive,
        }
      };

      return Result.ok(updateJobPostingStatus);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_JOBPOSTING))
      );
    }
  }
}
