import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import GetMultipleCandidateTemplateService from "../service/GetMultipleCandidateTemplateService";
import { IGetMultipleCandidateTemplateRepository } from "@/domain/interviewer/multiple-candidate-template/repository/IGetMultipleCandidateTemplateRepository";

const createInterviewService = new GetMultipleCandidateTemplateService();

export class GetMultipleCandidateTemplateRepository implements IGetMultipleCandidateTemplateRepository {
  async getTemplate(
  ): Promise<Result<any>> {
    try {
      return await createInterviewService
        .get()
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.GET_MULTI_CANDIDATE_TEMPLATE))
      );
    }
  }
}
