import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { CandidateInterviewLogsModel } from "@/domain/interviewer/candidate-interview-logs/model/CandidateInterviewLogsModel";
import { CandidateInterviewLogsRequestDto } from "@/domain/interviewer/candidate-interview-logs/dto/CandidateInterviewLogsRequestDto";
import { CandidateInterviewLogsResponseDto } from "@/domain/interviewer/candidate-interview-logs/dto/CandidateInterviewLogsResponseDto";

export class CandidateInterviewLogsMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CandidateInterviewLogsResponseDto): Result<CandidateInterviewLogsModel> {
    try {
      const candidateInterviewLogsModel: CandidateInterviewLogsModel[] = [];

      if (dto.data && dto.data.length > 0) {
        for (const item of dto.data) {
          const candidateInterviewLogModel: CandidateInterviewLogsModel = {
            message: {
              messageId: Number(item.id),
              owner: item.attributes.owner,
              text: item.attributes.text,
              isLiked: item.attributes.isLiked,
              createdAt: item.attributes.createdAt,
              voice: item.attributes.voice,
            },
          };

          candidateInterviewLogsModel.push(candidateInterviewLogModel);
        }
        return Result.ok(candidateInterviewLogsModel);
      }
      return Result.ok(candidateInterviewLogsModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_INTERVIEW_LOGS))
      );
    }
  }

  mapToDTO(domain: CandidateInterviewLogsModel): Result<CandidateInterviewLogsRequestDto> {
    try {
      const candidateCvAnalysisRequest = new CandidateInterviewLogsRequestDto(
        domain.candidate,
        domain.interview
      );

      return Result.ok(candidateCvAnalysisRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CANDIDATE_INTERVIEW_LOGS))
      );
    }
  }
}
