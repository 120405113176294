import { BaseDomain } from "@/core/base/BaseDomain";

export class UpdateUserModel extends BaseDomain {
  name?: string;
  surname?: string;
  email?: string;
  tenantName?: string;
  ok?: boolean;
  countryCode?: number;

  constructor(
    name?: string,
    surname?: string,
    email?: string,
    tenantName?: string,
    ok?: boolean,
    countryCode?: number,
  ) {
    super();
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.tenantName = tenantName;
    this.ok = ok;
    this.countryCode = countryCode;
  }
}
