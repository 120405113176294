import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { PollListRequestDto } from "@/domain/pollster/poll-list/dto/PollListRequestDto";
import { IPollListService } from "@/domain/pollster/poll-list/service/IPollListService";
import { PollListResponseDto } from "@/domain/pollster/poll-list/dto/PollListResponseDto";

export default class PollListService implements IPollListService {
  async getList(
    request: PollListRequestDto
  ): Promise<Result<PollListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.SURVEYS + request.toQueryString())
        .then((response) => {
          return Result.ok<PollListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<PollListResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_POLL_LIST))
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.POLLSTER.POLL_LIST))
      );
    }
  }
}
