import { BaseDomain } from "@/core/base/BaseDomain";

export class EmotionAnalysisModel extends BaseDomain {
  data: {
    angry?: number;
    disgust?: number;
    fear?: number;
    happy?: number;
    sad?: number;
    surprise?: number;
    neutral?: number;
  };

  constructor(
    angry?: number,
    disgust?: number,
    fear?: number,
    happy?: number,
    sad?: number,
    surprise?: number,
    neutral?: number
  ) {
    super();
    this.data = {
      angry,
      disgust,
      fear,
      happy,
      sad,
      surprise,
      neutral,
    };
  }
}
