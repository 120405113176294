import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { AddQuestionOptionModel } from "@/domain/interviewer/update-interview/addQuestionOption/model/AddQuestionOptionModel";
import { AddQuestionOptionRequestDto } from "@/domain/interviewer/update-interview/addQuestionOption/dto/AddQuestionOptionRequestDto";

export class AddQuestionOptionMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<AddQuestionOptionModel> {
    try {
      const addQuestionOptionModel: AddQuestionOptionModel = {
        ok: dto.ok,
      };

      return Result.ok(addQuestionOptionModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_INTERVIEW_QUESTION_OPTION))
      );
    }
  }

  mapToDTO(domain: AddQuestionOptionModel): Result<AddQuestionOptionRequestDto> {
    try {

      const addQuestionOptionRequest = new AddQuestionOptionRequestDto(
        domain.interviewQuestion,
        domain.optionText,
      );

      return Result.ok(addQuestionOptionRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_INTERVIEW_QUESTION_OPTION))
      );
    }
  }
}
