import { BaseDomain } from "@/core/base/BaseDomain";

export class UpdateQuestionModel extends BaseDomain {
  id?: number;
  questionText?: string;
  ok?: boolean;

  constructor(
    id?: number,
    questionText?: string,
    ok?: boolean,
  ) {
    super();
    this.id = id;
    this.questionText = questionText;
    this.ok = ok;
  }
}
