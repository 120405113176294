
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IInterviewDetailUseCase } from "@/domain/interviewer/interview-detail/usecase/IInterviewDetailUseCase";
import { InterviewDetailRepository } from "../repository/InterviewDetailRepository";
import { IInterviewDetailRepository } from "@/domain/interviewer/interview-detail/repository/IInterviewDetailRepository";
import { InterviewDetailMapper } from "../mapper/InterviewDetailMapper";
import { InterviewDetailModel } from "@/domain/interviewer/interview-detail/model/InterviewDetailModel";

export class InterviewDetailUseCase implements IInterviewDetailUseCase {
  getInterviewDetailRepository : IInterviewDetailRepository = new InterviewDetailRepository();
  getInterviewDetailMapper = new InterviewDetailMapper ();

  constructor() {
  }

  async execute(model: InterviewDetailModel): Promise<Result<InterviewDetailModel>> {
    try {
      const dto = this.getInterviewDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<InterviewDetailModel>(dto.getError());
      } else {
        const response = await this.getInterviewDetailRepository.getInterviewDetail(dto);

        if (response.isSuccess) {
          const domain = this.getInterviewDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<InterviewDetailModel>(domain.getValue());
        } else {
          return Result.fail<InterviewDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<InterviewDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.INTERVIEW_DETAIL))
      );
    }
  }
}
