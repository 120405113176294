import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import VerifyOTPService from "../service/VerifyOTPService";
import { IVerifyOTPRepository } from "@/domain/auth/verify-otp/repository/IVerifyOTPRepository";
import { VerifyOTPRequestDto } from "@/domain/auth/verify-otp/dto/VerifyOTPRequestDto";
import { VerifyOTPResponseDto } from "@/domain/auth/verify-otp/dto/VerifyOTPResponseDto";

const verifyOTPService = new VerifyOTPService();

export class VerifyOTPRepository implements IVerifyOTPRepository {
  async verifyOTP(
    request: VerifyOTPRequestDto
  ): Promise<Result<VerifyOTPResponseDto>> {
    try {
      return await verifyOTPService
        .verify(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<VerifyOTPResponseDto>(response.getValue());
          } else {
            return Result.fail<VerifyOTPResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<VerifyOTPResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<VerifyOTPResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.VERIFY_OTP))
      );
    }
  }
}
