import { BaseDomain } from "@/core/base/BaseDomain";
import { PaginationResponseDto } from "@/domain/base/pagination/dto/PaginationResponseDto";
import { ProductPackageModel } from "./ProductPackageModel";
import { ProductFAQModel } from "./ProductFAQModel";

export class ProductListModel extends BaseDomain {
  product: {
    id?: number;
    shortCode?: string;
    name?: string;
    description?: string;
    productPackages: ProductPackageModel[];
    productFAQs?: ProductFAQModel[]
  };
  locale?: string;
  page?: number;
  pageSize?: number;
  pagination?: PaginationResponseDto;
  
  constructor(
    productPackages: ProductPackageModel[],
    id?: number,
    shortCode?: string,
    name?: string,
    description?: string,
    locale?: string,
    page?: number,
    pageSize?: number,
    pagination?: PaginationResponseDto
  ) {
    super();
    this.product = {
      id,
      shortCode,
      name,
      description,
      productPackages,
    };
    this.locale = locale;
    this.page = page;
    this.pageSize = pageSize;
    this.pagination = pagination;
  }
}
