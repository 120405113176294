import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { TenantEstateModel } from "@/domain/user/tenant-estate/model/TenantEstateModel";
import { ActiveTenantEstateResponseDto } from "@/domain/user/tenant-estate/dto/ActiveTenantEstateResponseDto";
import { TenantEstatesResponseDto } from "@/domain/user/tenant-estate/dto/TenantEstatesResponseDto";

export class TenantEstateMapper implements IMapper<BaseDomain, BaseDTO> {
  public mapToDomain(
    dto: TenantEstatesResponseDto | ActiveTenantEstateResponseDto
  ): TenantEstateModel | TenantEstateModel[] {
    if (dto.data && Array.isArray(dto.data)) {
      const tenantEstateModel: TenantEstateModel[] = [];
      dto.data.forEach((item) => {
        const tenantEstate = new TenantEstateModel(
          item.id,
          item.attributes.name, // değiştirilecek. 
          item.attributes.shortCode,
          item.attributes.tenantRole
        );
        tenantEstateModel.push(tenantEstate);
      });
      return tenantEstateModel;
    } else {
      const tenantEstateModel: TenantEstateModel = {
        id: dto.data.id,
        name: dto.data.attributes.name, // değiştirilecek. 
        shortCode: dto.data.attributes.shortCode,
        tenantRole: dto.data.attributes.tenantRole,
      };
      return tenantEstateModel;
    }
  }

  public mapToDTO(domain: TenantEstateModel): Result<any> {
    return Result.ok(domain);
  }
}
