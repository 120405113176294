import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { CreatePollModel } from "@/domain/pollster/create-poll/model/CreatePollModel";
import { CreatePollRequestDto } from "@/domain/pollster/create-poll/dto/CreatePollRequestDto";

export class CreatePollMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<CreatePollModel> {
    try {
      const createPollsterModel: CreatePollModel = {
        surveyQuestions: [],
        ok: dto.ok
      };

      return Result.ok(createPollsterModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.CREATE_POLL))
      );
    }
  }

  mapToDTO(domain: CreatePollModel): Result<CreatePollRequestDto> {
    try {
      const createPollsterRequest = new CreatePollRequestDto(
        domain.title,
        domain.description,
        domain.startDate,
        domain.endDate,
        domain.surveyQuestions,
        domain.isPrivate
      );

      return Result.ok(createPollsterRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.CREATE_POLL))
      );
    }
  }
}
