import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IRemoveQualificationService } from "@/domain/interviewer/qualification/remove-qualification/service/IRemoveQualificationService";
import { RemoveQualificationRequestDto } from "@/domain/interviewer/qualification/remove-qualification/dto/RemoveQualificationRequestDto";

export default class RemoveQualificationService implements IRemoveQualificationService {
  async delete(
    request: RemoveQualificationRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .delete<any>(API_ROUTE.JOB_POSTINGS + request.jobPostingId + API_ROUTE.QUALIFICATIONS + request.qualificationId)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_REMOVE_QUALIFICATION)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.REMOVE_QUALIFICATION))
      );
    }
  }
}
