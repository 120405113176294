import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { UnknownCause } from "@/core/cause/UnknownCause";
import InterviewInviteService from "../service/InterviewInviteService";
import { IInterviewInviteRepository } from "@/domain/interviewer/interview-invite/repository/IInterviewInviteRepository";
import { InterviewInviteRequestDto } from "@/domain/interviewer/interview-invite/dto/InterviewInviteRequestDto";

const sendLinkService = new InterviewInviteService();

export class InterviewInviteRepository implements IInterviewInviteRepository {
  async interviewInvite(
    request: InterviewInviteRequestDto
  ): Promise<Result<any>> {
    try {
      return await sendLinkService
        .invite(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          console.log(error)
          return Result.fail<any>((error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.INTERVIEW_INVITE))
      );
    }
  }
}
