export default {
  project: "İş Asistan",
  year: "2025",
  common: {
    pleaseWait: "Lütfen bekleyiniz...",
    requiredInputs: "Yıldızlı alanları doldurmak zorunludur.",
    process: "İşlem",
    loading: "Yükleniyor...",
    pleaseChoose: "Seçiniz...",
    loaded: "Yüklendi",
    or: "YA DA",
    layout: {
      contact: "Bize Ulaşın",
      profile: "Profilim",
      language: "Dil",
      signOut: "Çıkış Yap",
    },
    button: {
      create: "Oluştur",
      add: "Ekle",
      start: "Başla",
      save: "Kaydet",
      cancel: "İptal",
      edit: "Düzenle",
      verify: "Doğrula",
      ok: "Tamam",
      continue: "Devam Et",
      send: "Gönder",
      install: "Yükle",
      delete: "Sil",
      yes: "Evet",
      no: "Hayır",
      invite: "Davet Et",
      sendInvite: "Davet Gönder",
      close: "Kapat",
      review: "İncele",
      copy: "Kopyala",
      update: "Güncelle",
      setPassive: "Pasif Yap",
      setActive: "Aktif Yap",
      giveUp: "Vazgeç",
      back: "Geri",
    },
    table: {
      process: "İşlem",
      notProcess: "Varsayılan olduğu için işlem yapılamaz",
      pagination: {
        desc: " sonuç arasından ",
        desc1: "sonuç gösteriliyor.",
        desc2: "sayfa başı öğe sayısı",
      },
    },
    times: {
      minuteAgo: "dakika önce",
      hourAgo: "saat önce",
      dayAgo: "gün önce",
      monthAgo: "ay önce",
    },
    dontLiked: "Beğenmedim",
    continues: "Devam ediyor",
    active: "Aktif",
    inactive: "Pasif",
    waiting: "Beklemede",
    completed: "Tamamlandı",
    ended: "Sonlandı",
    general: "Genel",
    special: "Özel",
  },
  asideMenu: {
    modules: {
      header: "Modüller",
      activeModules: "Aktif Modüller",
      notFoundActiveModule: "Aktif modül bulunamadı.",
      hotelManagement: {
        title: "AI Otel Asistanı",
        assistant: "AI Asistan",
        history: "Konuşma Arşivleri",
        staffList: {
          title: "Personeller",
          desc: "Şirketinize kayıtlı olan personelleri bu sayfada görüntüleyebilir, geçmişteki AI asistan konuşma kayıtlarını inceleyebilirsiniz.",
        },
      },
      pollster: {
        title: "Anketör",
        polls: "Anket Listesi",
        userAssignment: "Anket Ataması Yap",
        userPolls: "Kullanıcı Anketleri",
        myPolls: "Anketlerim",
        create: "Anket Oluştur",
        emailAddresses: "E-posta Adresleri",
      },
      interviewer: {
        title: "Mülakatör",
        create: "Mülakat Oluştur",
        createJobPosting: "İş İlanı Oluştur",
        historyList: "Geçmiş Mülakatlar",
        list: "Mülakat Listesi",
        jobPostingList: "İş İlanları",
        userAssignment: "Aday Davet Et",
        candidateList: "Aday Listesi",
      },
    },
    admin: {
      header: "Admin",
      modules: "Tüm Modüller",
      accountTypes: "Hesap Türleri",
      accountStatus: "Hesap Durumu",
      staff: "Personeller",
      inviteStaff: "Personel Davet Et",
    },
    ogza: {
      header: "Ogzatech Yönetim",
      modules: {
        title: "Modüller",
        allModules: "Tüm Modüller",
        createModul: "Modül Oluştur",
      },
      companies: "Kayıtlı Şirketler",
      createCompany: "Şirket Oluştur",
      chatgptSettings: "ChatGpt Ayarları",
    },
    user: "Kullanıcı",
  },
  landingPage: {
    menu: {
      home: "Anasayfa",
      title: "İş Asistan",
      assistant: "İş Asistanı",
      login: "Giriş Yap",
    },
    headerBanner: {
      title: "Yapay Zeka ile İş Sürecinizi Kolaylaştırın!",
      desc: "Yapay zeka ile iş süreçlerinizi bir üst seviyeye taşıyın! Verimliliği artıran, zaman kazandıran ve maliyetleri düşüren akıllı otomasyon çözümlerimizle tanışın. İş yükünüzü hafifletin, önemli işlere odaklanın ve başarınızı katlayın.",
    },
    container: {
      mainTitle: "AI İş Asistanı",
      mainHeader: "Yapay Zeka Destekli İş Asistanı",
      mainDesc:
        " Yapay zeka desteğiyle oluşturduğumuz asistan modülleri ile işinizi büyük oranda kolaylaştırın. Personel ve müşterilerinize kısa yoldan daha hızlı ve verimli hizmet sunun. İş süreçlerinizi optimize eden bu modüller, tekrarlayan görevleri otomatikleştirir, müşteri taleplerini anında yanıtlar ve veri analizleri ile stratejik kararlar almanıza yardımcı olur.",
      title: "Yapay Zekalı Otel Asistanı",
      desc: "Otelinize yapılan müşteri yorumlarını yapay zeka desteğiyle gerçeğe yakın şekilde duygu analizi ile birlikte değerlendirilmesini sağlar.",
      title2: "Yapay Zeka Destekli Anketör",
      desc2:
        "Şirket içi ve şirket dışı anketlerinizi yapay zeka yardımıyla yaparak kısa sürede ve verimli şekilde gerçekleştirin. Duygu analiziyle cevapların doğruluğunu saptayın ve kaydedin.",
      title3: "Yapay Zeka ile Canlı Mülakat",
      desc3:
        "İşe alım süreçlerinizi yapay zeka ile daha verimli hale getirin. Adaylarla canlı mülakat yaparak performanslarını analiz edin ve en uygun adayları belirleyin. Böylece daha hızlı ve doğru işe alım kararları alın.",
    },
    footer: {
      followUs: "Bizi Takip Et!",
      socialMedias: "Bize sosyal medya hesaplarımız üzerinden ulaşabilirsin!",
      copyrightText: "Ogzatech, Tüm hakları saklıdır.",
    },
  },
  login: {
    title: "Giriş Yap",
    desc: "Lütfen giriş yapabilmek için telefon numaranızı giriniz.",
    desc2: "Lütfen giriş yapabilmek için hesap bilgilerinizi giriniz.",
    email: "E-posta",
    enterEmail: "Lütfen e-posta adresinizi giriniz...",
    password: "Şifre",
    enterPassword: "Lütfen şifrenizi giriniz...",
    phone: "Telefon Numarası",
    country: "Ülke",
    forgotPassword: "Şifremi Unuttum",
    button: "Giriş",
  },
  verifyOTP: {
    title: "SMS Doğrulama",
    desc: "Lütfen telefonunuza gelen kısa mesajda yer alan doğrulama kodunu giriniz.",
    code: "Doğrulama Kodu",
    enterCode: "Doğrulama kodunu giriniz...",
  },
  profileSet: {
    title: "Hesap Bilgileri",
    title2: "Profil Oluştur",
    desc: "Lütfen hesap bilgilerinizi güncelleyiniz...",
    desc2: "Lütfen hesabınızı oluşturunuz...",
    name: "Ad",
    enterName: "Adınızı giriniz...",
    surname: "Soyad",
    enterSurname: "Soyadınızı giriniz...",
    email: "E-posta",
    enterEmail: "E posta adresinizi giriniz...",
    company: "Şirket",
    enterCompany: "Şirket adı giriniz...",
    button: "Kaydet ve Giriş Yap",
  },
  acceptInvite: {
    title: "Daveti Kabul Et",
    desc: "Daveti kabul etmek için aşağıdaki butona tıklayınız.",
    button: "Kabul Et",
  },
  companies: {
    createCompany: "Yeni Şirket Oluştur",
    createCompanyDesc:
      "Kendi şirketinizde yapay zeka modüllerini kullanın ve iş akışınızı kolaylaştırın.",
  },
  createCompany: {
    title: "Şirket Oluştur",
    desc: "Lütfen şirketinizin bilgilerini giriniz...",
    companyName: "Şirket Adı",
    enterCompanyName: "Şirket adı giriniz...",
    companyDesc: "Şirket Açıklaması",
    enterCompanyDesc: "Şirketinizi tanımlayınız...",
    sector: "Sektör",
    enterSector: "Sektör giriniz...",
  },
  dashboard: {
    title: "Anasayfa",
    chart: {
      title: "Toplam İşlem Sayısı",
      desc: "Son 7 günlük toplam işlem grafiği",
    },
    sections: {
      totalProcessCount: "Toplam İşlem Sayısı",
      modalCount: "Modül Sayısı",
      staffCount: "Personel Sayısı",
      todaysprocessCount: "Bugünkü İşlem Sayısı",
    },
    lastProcess: {
      title: "Son İşlemler",
      type: "İŞLEM TÜRÜ",
      nameSurname: "AD SOYAD",
      email: "E-POSTA",
      time: "TAMAMLANMA ZAMANI",
    },
  },
  profile: {
    title: "Profil",
    personelInfo: "Kişisel Bilgiler",
    name: "Ad",
    enterName: "Adınızı giriniz",
    surname: "Soyad",
    enterSurname: "Soyadınızı giriniz",
    company: "Şirket",
    enterCompany: "Şirket adı giriniz",
    phone: "Telefon",
    enterPhone: "Telefon numarası giriniz...",
    email: "E-posta",
    enterEmail: "E posta adresinizi giriniz...",
  },
  modals: {
    detail: {
      pageTitle: "Modül Detayları",
      current: "Güncel",
      modalDescription: "Modül Açıklaması",
      packages: "Paketler",
      numberOfUses: "Kullanım Hakkı",
      dayCount: "Gün Sayısı",
      purchased: "Satın Alındı",
      notPurchased: "Satın Alınabilir",
      free: "Ücretsiz",
      FAQS: "Sıkça Sorulan Sorular",
      settings: "Modül Ayarları",
      activeAiModel: "Aktif Yapay Zeka Modeli",
      details: {
        title: "Detaylar",
        update: "Güncelleme",
        techSupport: "Teknik Destek",
        licence: "Lisans",
        installation: "Kurulum",
        lifeLong: "Ömür Boyu",
        full: "Full",
        oneClick: "Tek Tıklama",
      },
    },
    hotel: {
      title: "AI Otel Asistanı",
      search: "Arama...",
      startNewSession: "Yeni Konuşma Başlat",
      newSession: "Yeni Konuşma",
      sessionCount: "Kalan Oturum Sayısı",
      expiryDate: "Son Kullanım Tarihi",
      zeroSession: "Konuşma geçmişi bulunamadı.",
      enterMessage: "Mesajınız...",
      emptyMessage: "Lütfen boş mesaj göndermeyiniz.",
      staffChatHistory: "Şu anda personel sohbet geçmişini görüntülüyorsunuz.",
      staffHistories: "Personel Konuşma Geçmişleri",
      staffHistoriesDesc:
        "Lütfen incelemek istediğiniz konuşma geçmişini seçiniz...",
      newChatDesc:
        "Otel asistanına hoşgeldiniz! Oteliniz ile alakalı danışmak istediğiniz konuyu veya müşteri yorumunu iletmek için lütfen ileti gönderiniz. Yapay zeka, size sıra dışı yetenekleriyle gerçeğe yakın cevaplar sunacak ve müşterilerinizin memnuniyeti sağlayacaktır.",
      closedSession:
        "Bu sohbet tamamlanmıştır. Yeni sohbet başlamak için lütfen sol menüde yer alan 'Yeni Konuşma Başlat' butonuna tıklayınız.",
      staffList: {
        pageTitle: "Personeller",
        title: "Personel Listesi",
        desc: "Şirketinize kayıtlı olan personelleri bu sayfada görüntüleyebilir, geçmiş ai asistan modül sohbet kayıtlarını inceleyebilirsiniz.",
        inviteStaff: {
          title: "Personel Davet Et",
          createStaff: "Personel Davet Et",
          name: "Personel Adı",
          enterName: "Adı giriniz...",
          surname: "Personel Soyadı",
          enterSurname: "Soyadı giriniz...",
          email: "Personel E-posta",
          enterEmail: "E-posta adresi giriniz...",
          phone: "Personel Telefonu",
          enterPhone: "Telefon numarası giriniz...",
          birthDate: "Doğum Tarihi",
          enterBirthDate: "Doğum tarihini giriniz...",
          department: "Departman",
          enterDepartment: "Departmanı yazınız...",
          position: "Pozisyon",
          enterPosition: "Pozisyonu yazınız...",
        },
        name: "Ad",
        surname: "Soyad",
        phone: "Telefon Numarası",
        email: "E-posta",
        department: "Departman",
        mission: "Görevi",
        sessionCount: "Konuşma Sayısı",
        zeroCount: "Personel arşivi bulunamadı.",
      },
    },
    pollster: {
      title: "Anketör",
      poll: "Anket",
      createPollster: "Anket Oluştur",
      pollDetails: "Anket Detayları",
      userAssignment: "Kullanıcı Anket Ataması",
      createLink: "Link Oluştur",
      staffPoll: "Personel Anketi",
      singlePollType: "Özel Anket",
      singlePollTypeDesc:
        "Özel anketler, her kullanıcı için bireysel şekilde tek kullanımlık olarak gönderilmek üzere oluşturulur.",
      addQuestion: "Yeni Soru Ekle",
      openEnded: "Açık Uçlu",
      singleChoice: "Tek Seçenekli",
      multipleChoice: "Çok Seçenekli",
      responseOptions: "Cevap Seçenekleri",
      updateEndDate: "Bitiş Tarihi Güncelle",
      updateEndDateDesc:
        " Anketin bitiş tarihini güncellemek için lütfen yeni tarih giriniz.",
      addOption: "Seçenek Ekle",
      startPoll: "Anketi Başlat",
      emailPoll: "Kayıtlı E-posta Anketi",
      name: "Ad",
      enterName: "Adı giriniz...",
      surname: "Soyad",
      enterSurname: "Soyadı giriniz...",
      email: "E-posta",
      enterEmail: "E-posta adresi giriniz...",
      enterEmailDesc:
        "                         NOT: Birden fazla E-posta adresi girmek için lütfen virgül ile ayırınız.",
      selectPoll: "Anket seçiniz...",
      pollDesc: "Anket Açıklaması",
      link: "Anket Linki",
      type: "Anket Türü",
      staff: "Personel",
      sessionCode: "Oturum Kodu",
      enterSessionCode: "Oturum Kodu giriniz...",
      pollsHistorys: "Geçmiş Anketler",
      polls: "Anketler",
      pollsDesc:
        "Anketör uygulamasında önceden tanımladığınız anketleri bu sayfada görüntüleyebilirsiniz.",
      userPolls: {
        title: "Kullanıcı Anketleri",
        desc: "Anketör uygulamasında kullanıcıların yaptığı anketleri bu sayfada görüntüleyebilirsiniz.",
        poll: "Anket",
        status: "Durum",
        duration: "Süre",
        pollType: "Anket Türü",
        endDate: "Bitiş Tarihi",
        detail: "Detay",
      },
      userPollDetail: {
        header: "Kullanıcı Anket Detayları",
        tabs: {
          one: "Anket Bilgileri",
          two: "Kullanıcı Yanıtları",
          three: "Konuşma Logları",
        },
      },
      isStaff: "PERSONEL MI?",
      nameSurname: "AD SOYAD",
      date: "Tamamlanma Zamanı",
      pollTitle: "Anket Başlığı",
      enterTitle: "Başlık giriniz...",
      desc: "Açıklama",
      enterDesc: "Açıklama giriniz...",
      startDate: "Başlangıç Tarihi",
      enterStartDate: "Tarih seçiniz...",
      endDate: "Bitiş Tarihi",
      enterEndDate: "Tarih seçiniz...",
      duration: "Süre",
      percentComplete: "Tamamlanma Yüzdesi",
      status: "Durum",
      questionCount: "Soru Sayısı",
      sessionCount: "Oturum Sayısı",
      question: "Soru",
      userAnswer: "Kullanıcı Cevabı",
      enterQuestion: "Soruyu yazınız...",
      questionType: "Soru Tipi",
      enterQuestionType: "Soru tipi seçiniz...",
      emails: {
        title: "E-posta Adresleri",
        desc: "Anketör uygulamasına kayıtlı e-posta adreslerini bu sayfada yönetebilirsiniz.",
        createNewEmail: "Yeni E-posta Ekle",
        createEmailTitle: "E-posta Ekle",
        createEmailDesc: "Modüle eklemek istediğiniz e-posta adresini giriniz",
        email: "E-posta",
        enterEmail: "E-posta adresini giriniz...",
        pollCount: "Anket Sayısı",
      },
      firstAiMessage:
        "Merhaba ben Pelin, lütfen size soracak olduğum sorulara doğrulukla cevap verin, yapacağınız tüm geri dönüşler ürünlerimizi iyileştirmeye yönelik kayıt altına alınıp değerlendirilecektir.",
      pushToTalk: "Bas konuş...",
      aiPollster: "Yapay Zeka Anketörü",
      active: "Aktif",
      deActive: "Pasif",
      endConversation: "Görüşmeyi Sonlandır",
      time: "Süre",
      zeroCount: "Anket bulunamadı. Lütfen yeni bir anket ekleyiniz. ",
    },
    interview: {
      title: "Mülakatör",
      createJobPosting: "İş İlanı Oluştur",
      createJobPostingWizard: {
        chooseJobPosting: "İş İlanı Seç",
        chooseJobPostingDesc: "İş ilanını seçin",
        defineJobPosting:
          "Hangi pozisyon için mülakat oluşturmak istediğinizi tanımlayın.",
        step1Desc:
          "Mülakatını oluşturmak istediğin iş ilanını seç ya da yenisini oluştur.",
        step1NewJobposting: "Yeni İlan Oluştur",
        createInterview: "Mülakat Oluştur",
        createInterviewDesc: "Mülakat türünü belirleyip oluşturun",
        defineInterview:
          "Lütfen oluşturmak istediğiniz mülakatın bilgilerini doldurunuz. ",
        setQuestions: "Soruları Tanımla",
        setQuestionsDesc: "Mülakat sorularını oluşturup düzenleyin",
        defineQuestions:
          "Mülakatı oluşturabilmek için lütfen soruları ekleyiniz ve pozisyona göre özelleştiriniz.",
        min3Questions: "Lütfen mülakatı oluşturmak için en az 3 soru giriniz.",
        addQuestionToInterview: "Mülakata Soru Ekle",
        addQuestion: "Yeni Soru Ekle",
        addThisQuestion: "Soruyu Ekle",
        addedQuestions: "Eklenen Sorular",
        sortInterviews: "Mülakatı Sırala",
        step4Desc:
          "Seçmiş olduğun iş ilanındaki mülakatlara göre öncelik sırasını belirle.",
        sortInterviewsDesc: "Öncelik sırasını belirleyin",
        createdInterview: "Mülakat Oluşturuldu!",
        successCreatedInterview: " Mülakat başarıyla oluşturuldu.",
        step5Desc:
          "Tebrikler! Mülakatı başarıyla oluşturuldu. Mülakatı yaptırmak istediğiniz adaylara davet gönderdikten sonra mülakatı bitirdiklerinde sistem üzerinden kontrol edebilirsiniz.",
        seeAllJobpostings: "Tüm iş ilanlarını görmek için buraya tıklayın.",
        createdInterviewDesc: "Mülakatı yapmaya başlayın",
      },
      notFoundJobPosting: "İş İlanı bulunamadı.",
      createJobPostingToolTip: "Açık pozisyon için iş ilanı oluşturun",
      interview: "Mülakat",
      deleteInterview: "Mülakatı Sil",
      allInterviews: "Tüm Mülakatlar",
      sortInterviews: "Mülakatları Sırala",
      sortInterviewsDesc: "Mülakatların öncelik sırasını belirle.",
      interviewCount: "Mülakat Sayısı",
      totalInterviewCount: "Toplam Mülakat",
      completedInterview: "Tamamlanan",
      ongoingInterview: "Devam Eden",
      canceledInterview: "İptal Olan",
      updateInterview: "Mülakatı Güncelle",
      updateQuestions: "Soruları Güncelle",
      interviewQuestions: "Mülakat Soruları",
      interviewDetails: "Mülakat Detayları",
      updateInterviewToolTip:
        "Mülakatı güncellemek için lütfen aşağıdaki bilgileri doldurunuz.",
      jobPosting: "İş İlanı",
      deleteJobPosting: "İş İlanını Sil",
      editJobPosting: "İlanı Düzenle",
      jobPostingDetails: "İlan Detayları",
      jobPostingCandidates: "İş İlanına Başvuran Adaylar",
      jobPostings: "İş İlanları",
      allJobPostings: "Tüm İş İlanları",
      activeJobPostings: "Aktif İş İlanları",
      inactiveJobPostings: "Pasif İş İlanları",
      pendingJobPostings: "Bekleyen İlanlar",
      completedJobPostings: "Tamamlanan İş İlanları",
      candidateList: "Aday Listesi",
      candidateCount: "Aday Sayısı",
      editCandidate: "Adayı Düzenle",
      morePieces: "adet daha..",
      inviteCandidate: "Aday Davet Et",
      jobPostingTitle: "İlan Başlığı",
      enterJobPostingTitle: "Başlık giriniz...",
      createInterview: "Mülakat Oluştur",
      jobPostingDesc: "İlan Açıklaması",
      viewDetail: "Detay Görüntüle",
      qualifications: "Aranılan Özellikler",
      forAddQualification: "Opsiyon eklemek için yazın ve Enter'a basın",
      pleaseWriteQuestion: "Lütfen soru yazınız.",
      pleaseAddOptions: "Lütfen seçenek ekleyiniz.",
      min3Options: "Lütfen en az 3 tane seçenek giriniz.",
      min3Qualification: "Lütfen en az 3 tane özellik giriniz.",
      name: "Ad",
      enterName: "Adı giriniz...",
      surname: "Soyad",
      enterSurname: "Soyadı giriniz...",
      email: "E-posta",
      enterEmail: "E-posta adresi giriniz...",
      link: "Mülakat Linki",
      interviewsHistorys: "Geçmiş Mülakatlar",
      nameSurname: "AD SOYAD",
      date: "Tamamlanma Zamanı",
      status: "Durum",
      questionCount: "Soru Sayısı",
      sessionCount: "Oturum Sayısı",
      interviewTitle: "Mülakat Başlığı",
      titlePlaceholder:
        "Ör: Teknik Değerlendirme Mülakatı / ilk Mülakat Aşaması",
      enterInterviewTitle: "Mülakat başlığı giriniz...",
      position: "Pozisyon",
      enterPosition: "Pozisyonu giriniz...",
      description: "Açıklama",
      descriptionPlaceholder:
        "Ör: Bu mülakat, yazılım geliştirme pozisyonu için adayların teknik becerilerini ve deneyimlerini değerlendirir.",
      enterDescription: "Açıklama giriniz...",
      interviewDetail: "Mülakat Detayları",
      startDate: "Başlangıç Tarihi",
      enterStartDate: "Tarih seçiniz...",
      endDate: "Bitiş Tarihi",
      enterEndDate: "Tarih seçiniz...",
      addQuestion: "Soru Ekle",
      question: "Soru",
      writeQuestionPressEnter: "Sorunuzu yazın ve Enter'a basın",
      questionType: "Soru Tipi",
      enterQuestion: "Soruyu yazınız...",
      enterQuestionType: "Soru tipi seçiniz...",
      openEnded: "Açık Uçlu",
      singleChoice: "Tek Seçenekli",
      multipleChoice: "Çok Seçenekli",
      responseOptions: "Cevap Seçenekleri",
      addOption: "Seçenek Ekle",
      deleteQuestion: "Soru Sil",
      areYouSureAboutDeleteOption:
        "Bu şıkkı silmek istediğinizden emin misiniz?",
      areYouSureAboutDeleteQuestion:
        "Bu soruyu silmek istediğinizden emin misiniz?",
      zeroQuestion: "Henüz soru eklenmedi.",
      startInterview: {
        title: "Mülakata Başla",
        jobPosting: "Başvurulan İş İlanı",
        desc: "Değerli adayımız, mülakata başlamadan önce lütfen aşağıdakileri okuyun.",
        longDesc:
          "Değerli adayımız, iş ilanımıza başvurduğunuz için teşekkür ederiz. Lütfen mülakata başvurmadan önce mülakat detaylarını kontrol edip başvurduğunuz pozisyona ait olup olmadığından emin olduktan sonra mülakata giriş yapınız.",
        interviewType: "Mülakat Türü",
        questionCount: "Soru Sayısı",
        estimatedTime: "Tahmini Süre",
        importantInfoAndAgreements: "Önemli Bilgiler ve Onaylar",
        info1:
          "Mülakat esnasında pencereyi değiştirmemeniz gerekmektedir. Aksi takdirde bu durum skorunuza etki edebilir.",
        info2:
          "Mülakat sesli ve görüntülü olarak yapılacaktır. Kamera ve mikrofonunuzun çalıştığından emin olun.",
        info3:
          "Mülakat sırasında sorular açık uçlu, çoktan seçmeli veya tek seçenekli olabilir. Tüm soruların doğru bir şekilde yanıtlanması beklenmektedir.",
        info4:
          "Mülakat esnasında ses ve görüntü kaydı alınacaktır. Tüm veriler kayıt altına alınacaktır.",
        kvkk: "Kişisel verilerimin işlenmesi için açık rızamı veriyorum. Bu onay, mülakat süreci boyunca verilerimin toplanması ve kullanılmasına izin verildiğini kabul ettiğim anlamına gelir.",
      },
      finishInterview: "Mülakatı Sonlandır",
      interviewResults: {
        title: "Mülakat Sonuçları",
        thankYouMessage: "Mülakata katıldığınız için teşekkür ederiz.",
        resultsHeader: "Teşekkürler!",
        followUpMessage:
          "Mülakat sonucu ve gelişmeleri e-posta üzerinden takip edebilirsiniz.",
        answeredQuestions: "Cevaplanan Soru Sayısı",
        timeElapsed: "Geçen Süre",
        remainingTime: "Kalan Süre",
        backToHome: "Ana Sayfaya Dön",
      },
      addCandidate: {
        title: "Mülakat Daveti Oluştur",
        interview: "Mülakat",
        selectInterview: "Mülakat seçiniz...",
        name: "Ad",
        enterName: "Adı giriniz...",
        surname: "Soyad",
        enterSurname: "Soyadı giriniz...",
        email: "E-posta",
        enterEmail: "E-posta giriniz...",
      },
      userInterviewDetail: {
        header: "Kullanıcı Mülakat Detayları",
        tabs: {
          one: "Mülakat Bilgileri",
          two: "Kullanıcı Yanıtları",
          three: "Konuşma Logları",
        },
      },
      candidate: {
        candidateInfo: "Aday Bilgileri",
        name: "Ad",
        surname: "Soyad",
        email: "E-posta",
        cv: "CV",
        candidateStatus: "Aday Durumu",
        activeCandidate: "Aktif Aday",
        deActiveCandidate: "Pasif Aday",
        eliminated: "Elendi",
        eliminatedCandidate: "Elendi",
        uploadCV: "CV Yükle",
        uploadCVDesc: " Sadece pdf, doc ve docx uzantılı dosyalar (maks 2mb)",
        deleteCandidate: "Adayı Sil",
        positionEligibility: "Pozisyon Uygunluğu",
        cvAnalysis: "CV Analizi",
        downloadCV: "CV İndir",
        sendInvite: "Davet Gönder",
        sendInterviewInvite: "Mülakat Daveti Gönder",
        appliedJobPosting: "Başvurulan İlan",
        expirationDate: "Son Geçerlilik Tarihi",
        jobPostingCandidateList: "İş İlanı Aday Listesi",
        candidateSelected: "Aday Seçildi",
        deleteCandidates: "Adayları Sil",
        inviteCandidate: "Aday Davet Et",
        downloadTemplateDesc:
          "Çoklu aday ekleme işlemini gerçekleştirebilmek için öncelikle aşağıdaki şablonu indirmelisiniz. Şablonu aday bilgileri ile doldurduktan sonra tekrar yükleyerek adayları sisteme ekleyebilirsiniz.",
        downloadTemplate: "Şablonu İndir",
        uploadCandidateList: "Aday Listesi Yükle",
        addMultiCandidates: "Çoklu Aday Ekle",
        selectedFile: "Seçilen Dosya",
        fileByte: "Boyut",
        fileValidity: "Dosya Geçerliliği",
        addMultiCandidatesDesc:
          "Adayları excel dosyasıyla dosyayla çoklu olarak ekleyin.",
        addSingleCandidate: "Tekil Aday Ekle",
        addSingleCandidateDesc:
          "Eklemek istediğiniz adayın bilgilerini girip iş ilanına aday davet edin.",
        inviteCandidateInterviewJobpostingDate:
          "Bu iş ilanının geçerlilik tarihi {startDate} ve {endDate} tarihleri arasıdır.",
        zeroCount: "Henüz bir aday bulunamadı.",
        pleaseAddCandidate: "Lütfen yeni bir aday ekleyiniz.",
        noPositionSuitability: "Mevcut değil",
        noInterviewSession: "Henüz girmedi",
      },
      firstAiMessage:
        "Merhaba, ben yapay zeka mülakatörü. Sizi tanımak için buradayım. Lütfen kendinizi rahat hissedin ve doğal bir şekilde sorularımı yanıtlayın.",
      pushToTalk: "Basılı tut ve konuş...",
      percentComplete: "Görüşme Tamamlanma Yüzdesi: ",
      aiInterviewer: "Yapay Zeka Mülakatatörü",
      active: "Aktif",
      deActive: "Pasif",
      endConversation: "Görüşmeyi Sonlandır",
      time: "Süre",
      zeroCount: "Mülakat bulunamadı. Lütfen yeni bir mülakat ekleyiniz. ",
      createNewInterview: "Yeni Mülakat Oluştur",
      latestMovements: "Son Hareketler",
      processDate: "İşlem Tarihi",
      lastprocessDate: "Son İşlem Tarihi",
      noRecord: "Kayıt bulunamadı.",
    },
  },
  modalList: {
    title: "Modül Listesi",
    allModules: "Tüm",
    myPurchases: "Satın Aldıklarım",
    freeOnes: "Ücretsiz Olanlar",
    availableForPurchase: "Satın Alınabilir",
    purchased: "Satın Alınmış",
    notFound: "Modül bulunamadı.",
  },
  ogza: {
    staffList: {
      pageTitle: "Personeller",
      title: "Personel Listesi",
      desc: "Şirketinize kayıtlı olan personelleri bu sayfada görüntüleyebilir, geçmiş kayıtlarını inceleyebilirsiniz.",
      inviteStaff: {
        title: "Personel Davet Et",
        createStaff: "Personel Davet Et",
        name: "Personel Adı",
        enterName: "Adı giriniz...",
        surname: "Personel Soyadı",
        enterSurname: "Soyadı giriniz...",
        email: "Personel E-posta",
        enterEmail: "E-posta adresi giriniz...",
        phone: "Personel Telefonu",
        enterPhone: "Telefon numarası giriniz...",
        birthDate: "Doğum Tarihi",
        enterBirthDate: "Doğum tarihini giriniz...",
        department: "Departman",
        enterDepartment: "Departmanı yazınız...",
        position: "Pozisyon",
        enterPosition: "Pozisyonu yazınız...",
      },
      name: "Ad",
      surname: "Soyad",
      phone: "Telefon Numarası",
      email: "E-posta",
      department: "Departman",
      mission: "Görevi",
      status: "Durum",
      zeroCount: "Personel kaydı bulunamadı.",
    },
    chatGptSettings: {
      title: "ChatGpt Ayarları",
      activeVersion: "Aktif ChatGpt Sürümü",
      selectVersion: "Versiyon seçiniz...",
      apiKey: "Api Key",
      enterApiKey: "Api Key giriniz...",
    },
    createModal: {
      title: "Modül Oluştur",
      modalIcon: "Modül İkonu",
      modalIconDesc: "İzin verilen dosya türleri: png, jpg, jpeg..",
      modalName: "Modül Adı",
      enterModalName: "Modül Adı giriniz...",
      modalDesc: "Modül Tanımı",
      enterModalDesc: "Modül Tanımı giriniz...",
      price: "Fiyat",
      enterPrice: "Fiyatı giriniz...",
      detailedDesc: "Detaylı Açıklama",
      enterDetailedDesc: "Açıklama yazınız...",
      question1: "Soru 1",
      question2: "Soru 2",
      enterQuestion: "Soru giriniz...",
      answer: "Cevap",
      enterAnswer: "Cevap giriniz...",
      adminPropmt: "Modül Admin Promptu",
      enterPrompt: "Promptu giriniz...",
      userSessionLimit: "Kullanıcı Session Sınırı",
      enterLimit: "Miktar giriniz...",
      details: "Detaylar",
      speakOutLoud: "Sesli konuşma",
      videoCall: "Görüntülü görüşme",
      guestUser: "Dışarıdan misafir kullanıcılar erişebilir mi?",
    },
    createCompany: {
      title: "Şirket Oluştur",
      companyName: "Şirket Adı",
      enterCompanyName: "Şirket Adı giriniz...",
      companyCategory: "İş Kategorisi",
      enterCompanyCategory: "Kategori giriniz...",
      managerName: "Yetkili Adı",
      enterManagerName: "Yetkili Adı giriniz...",
      managerSurname: "Yetkili Soyadı",
      enterManagerSurname: "Yetkili Soyadı giriniz...",
      email: "E-posta Adresi",
      enterEmail: "E-posta adresini giriniz...",
      country: "Ülke",
      phone: "Telefon Numarası",
      enterPhone: "Telefon numarası giriniz...",
      department: "Departman",
      enterDepartment: "Departmanı giriniz...",
      accountType: "Hesap Türü",
      selectAccountType: "Hesap Türü seçiniz...",
    },
  },
  accountStatus: {
    pageTitle: "Hesap Durumu",
    title: "Hesap Bilgileri",
    companyName: "Şirket Adı",
    enterCompanyName: "Şirket Adı giriniz...",
    email: "E-posta",
    enterEmail: "E-posta adresi giriniz...",
    accountType: "Hesap Türü",
    activeModalCount: "Aktif Modül Sayısı",
    processCountofPeriod: "Dönem içi İşlem Sayısı",
    purchaseHistory: "Satın Alma Geçmişi",
    receivedModule: "Alınan Modül",
    price: "Fiyat",
    processDate: "İşlem Tarihi",
  },
  accountTypes: {
    title: "Hesap Türleri",
    basic: "Temel Hesap",
    yourActiveAccount: "Aktif hesabınız",
  },
  error404: {
    nothing: "Burada hiçbir şey yok gibi görünüyor",
    return: "Anasayfaya dön",
  },
  error500: {
    title: "Sistem Hatası",
    desc: "Bu sayfaya giriş yetkiniz yok!",
    return: "Anasayfaya dön",
  },
  interviewFailed: {
    title: "Geçersiz Mülakat",
    desc: "Bu mülakat geçersiz, lütfen tekrar deneyiniz.",
    return: "Anasayfaya dön",
  },
  swalMessages: {
    SUCCESSFUL: "İşlem Başarılı!",
    VALIDATED_FILE: "Dosya başarılı bir şekilde doğrulandı.",
    NOT_ACTIVE_YET:
      "Bu işlem henüz aktif değil, lütfen daha sonra tekrar deneyin.",
    FAILED: "İşlem Başarısız!",
    SESSION_TIME_OUT:
      "Oturum süresi doldu. Lütfen kullanıcı bilgilerinizle tekrar giriş yapınız.",
    SESSION_CLOSED:
      "Sohbet tamamlanmıştır. Yeni sohbet başlamak için lütfen sol menüde yer alan 'Yeni Konuşma Başlat' butonuna tıklayınız.",

    COPY_JOBPOSTING: "Bu iş ilanını kopyalamak istiyor musunuz?",
    COPY_JOBPOSTING_DESC:
      "Bu iş ilanının aynısını kopyalamak ve yeni bir tane oluşturmak istiyor musunuz?",
    DELETE_CANDIDATE: "Bu adayı silmek istediğinizden emin misiniz?",
    INVITE_INTERVIEW:
      "Bu mülakat linkini adaya göndermek istediğinizden emin misiniz?",
    REVOKE_INVITE_INTERVIEW:
      "Bu mülakat linkini iptal etmek istediğinizden emin misiniz?",
    MAKE_PASSIVE_JOBPOSTING: "İş İlanını pasif hale getirmek istiyor musunuz?",
    MAKE_PASSIVE_JOBPOSTING_DESC:
      "Bu işlemi onaylarsanız bu iş ilanı adaylar tarafından erişilemeyecek.",
    CANDIDATE_CV_ANALYSIS:
      "Bu adayın cv'sini analiz etmek istediğinizden emin misiniz?",
    CANDIDATE_CV_ANALYSIS_DESC:
      "Bu işlem sonucunda iş ilanı düzenlenemez hale gelecektir.",
    MAKE_ACTIVE_JOBPOSTING: "İş İlanını aktif hale getirmek istiyor musunuz?",
    MAKE_ACTIVE_JOBPOSTING_DESC:
      "Bu işlemi onaylarsanız bu iş ilanı adaylar tarafından erişilebilecek.",
    DELETE_JOBPOSTING: "İş İlanını silmek istediğinizden emin misiniz?",
    DELETE_INTERVIEW: "Mülakatı silmek istediğinizden emin misiniz?",
    CANNOT_BE_UNDONE: "Bu işlem geri alınamaz.",
    CANNOT_BE_RIGHT_USAGE: "Bu işlem modül haklarınızdan bir hak kullanımına sebep olacaktır.",
    CANNOT_BE_UPDATE_JOBPOSTING:
      "Bu işlem modül haklarınızdan bir hak kullanımına sebep olacaktır ve ilgili iş ilanı düzenlenemez hale gelecektir.",

    CONFIRM_BUTTON_TEXT: "Tamam",
    API_ERROR_MESSAGE:
      "Teknik hata. Sunucuya bağlanılamıyor, lütfen tekrar deneyiniz.",

    UNKNOWN_ERROR_MESSAGE: "Bilinmeyen bir hata oluştu.",
    ARE_YOU_SURE: "Emin misiniz?",
    SURE_DELETE_STAFF: "Bu personeli silmek istediğinize emin misiniz?",
    SURE_DELETE_POLL: "Bu anketi silmek istediğinize emin misiniz?",
    SURE_DELETE_INTERVIEW: "Bu mülakatı silmek istediğinize emin misiniz?",

    TIME_ERROR: "Süre doldu. Lütfen tekrar giriş yapınız.",

    ERROR: {
      ERROR_CODE: " Hata kodu: ",
      ERROR_GENERATE:
        "Doğrulama kodu gönderimi başarısız! Lütfen tekrar deneyiniz.",
      ERROR_VERIFY: "Doğrulama başarısız! Lütfen tekrar deneyiniz.",
      ERROR_VERIFY_REMAINING_ATTEMPTS:
        "Deneme sınırınızı aştınız. Lütfen daha sonra tekrar deneyiniz.",
      ERROR_UPDATE_USER:
        "Kullanıcı bilgileri güncellenemedi. Lütfen tekrar deneyiniz.",
      ERROR_CHANGE_TENANT_USER:
        "Teknik hata! Çiftlik değiştirme işlemi başarısız.",
      ERROR_ABOUT_USER: "Teknik hata! Kişisel bilgi yüklemesi başarısız.",
      ERROR_ADD_STAFF: "Teknik hata! Personel kayıt işlemi başarısız.",
      ERROR_DELETE_STAFF: "Teknik hata! Personel silme işlemi başarısız.",
      ERROR_STAFF_LIST: "Teknik hata! Personel listeleme işlemi başarısız.",
      ERROR_INVITE_STAFF: "Teknik hata! Personel davet etme işlemi başarısız.",
      ERROR_POLL_LIST: "Teknik hata! Anket listeleme işlemi başarısız.",
      ERROR_ACCEPT_INVITE:
        "Geçersiz davet linki, lütfen yöneticiniz ile iletişime geçiniz.",
      ERROR_PRODUCT_LIST: "Teknik hata! Modül listeleme işlemi başarısız.",
      ERROR_BUY_PRODUCT:
        "İşlem başarısız! Modül satın alınamadı. Lütfen yönetici ile iletişime geçin.",
      ERROR_UPDATE_PRODUCT_MODEL_SETTING:
        "İşlem başarısız! Modül modeli değiştirilemedi.",
      ERROR_DELETE_POLL: "Teknik hata! Anket silme işlemi başarısız.",
      ERROR_CREATE_POLL: "Teknik hata! Anket oluşturma işlemi başarısız.",
      ERROR_UPDATE_POLL: "Teknik hata! Anket güncelleme işlemi başarısız.",
      ERROR_INVITE_USER:
        "Teknik hata! Kullanıcıları ankete davet etme işlemi başarısız.",
      ERROR_FILE_UPLOAD: "Teknik hata! Dosya gönderme işlemi başarısız.",
      ERROR_POLL_DETAIL:
        "Teknik hata! Anket detayı görüntüleme işlemi başarısız.",
      ERROR_POLL_STATISTICS_LIST:
        "Teknik hata! Geçmiş anketleri listeleme işlemi başarısız.",
      ERROR_POLL_STATISTICS_MESSAGE_LIST:
        "Teknik hata! Anket log mesajlarını görüntüleme işlemi başarısız.",
      ERROR_POLL_STATISTICS_SESSION_DETAIL:
        "Teknik hata! Anket detayı ve istatistik görüntüleme işlemi başarısız.",
      ERROR_POLL_STATISTICS_RESPONSE_LIST:
        "Teknik hata! Anket cevapları görüntüleme işlemi başarısız.",
      ERROR_INTERVIEW_LIST: "Teknik hata! Mülakat listeleme işlemi başarısız.",
      ERROR_JOBPOSTING_LIST:
        "Teknik hata! İş ilanı listeleme işlemi başarısız.",
      ERROR_CANDIDATE_LIST: "Teknik hata! Aday listeleme işlemi başarısız.",
      ERROR_GET_MULTI_CANDIDATE_TEMPLATE:
        "Teknik hata! Şablon örneği yüklenemedi. Lütfen daha sonra tekrar deneyiniz.",
      ERROR_INTERVIEW_DETAIL:
        "Teknik hata! Mülakat detayı görüntüleme işlemi başarısız.",
      ERROR_INTERVIEW_COUNTS:
        "Teknik hata! Mülakat sayılarını görüntüleme işlemi başarısız.",
      ERROR_JOBPOSTING_DETAIL:
        "Teknik hata! İş ilanı detayı görüntüleme işlemi başarısız.",
      ERROR_CANDIDATE_DETAIL:
        "Teknik hata! Aday detayı görüntüleme işlemi başarısız.",
      ERROR_INTERVIEW_INVITE:
        "Teknik hata! Adaya mülakat linki gönderme işlemi başarısız.",
      ERROR_INVITED_INVALID_EMAIL:
        "Geçersiz e-posta! Mülakat linki gönderimi başarısız.",
      ERROR_DELETE_INTERVIEW: "Teknik hata! Mülakat silme işlemi başarısız.",
      ERROR_DELETE_JOBPOSTING: "Teknik hata! İş ilanı silme başarısız.",
      ERROR_DELETE_CANDIDATE: "Teknik hata! Aday silme başarısız.",
      ERROR_CREATE_INTERVIEW:
        "Teknik hata! Mülakat oluşturma işlemi başarısız.",
      ERROR_CREATE_JOBPOSTING:
        "Teknik hata! İş ilanı oluşturma işlemi başarısız.",
      ERROR_ADD_CANDIDATE:
        "Teknik hata! Mülakata aday davet etme işlemi başarısız.",
      ERROR_UPDATE_INTERVIEW:
        "Teknik hata! Mülakat güncelleme işlemi başarısız.",
      ERROR_UPDATE_JOBPOSTING:
        "Teknik hata! İş ilanı güncelleme işlemi başarısız.",
      ERROR_UPDATE_CANDIDATE: "Teknik hata! Aday güncelleme işlemi başarısız.",
      ERROR_REMOVE_QUALIFICATION:
        "Teknik hata! Aranılan özellik silme işlemi başarısız.",
      ERROR_ADD_QUALIFICATION:
        "Teknik hata! Aranılan özellik ekleme işlemi başarısız.",
      ERROR_ADD_QUESTION: "Teknik hata! Soru ekleme işlemi başarısız.",
      ERROR_ADD_QUESTION_OPTION:
        "Teknik hata! Soru şıkkı ekleme işlemi başarısız.",
      ERROR_DELETE_QUESTION: "Teknik hata! Soru silme işlemi başarısız.",
      ERROR_DELETE_QUESTION_OPTION: "Teknik hata! Soru şıkkı işlemi başarısız.",
      ERROR_UPDATE_QUESTION: "Teknik hata! Soru güncelleme işlemi başarısız.",
      ERROR_SEND_MESSAGE:
        " Limitiniz dolduğu için yeni oturum başlatamıyorsunuz. Lütfen hesabınızı yükseltin.",
      ERROR_GET_SESSION_LIST:
        " Teknik hata, konuşma geçmişi bilgisine ulaşılamadı! ",
      ERROR_RETRY_ANSWER:
        " Limitiniz dolduğu için cevap oluşturamıyorsunuz. Lütfen hesabınızı yükseltin.",
      ERROR_GET_STATISTICS_LIST:
        "Teknik hata! İstatistik yükleme işlemi başarısız.",
      ERROR_CV_ANALYSIS: "Teknik hata! Aday cv analizi başarısız.",
    },
    SUCCESS: {
      SUCCESS_UPDATE_USER: "Kişisel bilgileri güncelleme işlemi başarılı!",
      SUCCESS_DELETE_STAFF: "Personel başarıyla silindi!",
      SUCCESS_INVITE_STAFF: "Personel başarıyla davet edildi!",
      SUCCESS_ACCEPT_INVITE: "Erişim izni başarıyla verildi.",
      SUCCESS_BUY_PRODUCT: "Tebrikler, modülü başarıyla satın aldınız!",
      SUCCESS_UPDATE_PRODUCT_MODEL_SETTING:
        "Modül modeli başarıyla değiştirildi.",
      SUCCESS_DELETE_POLL: "Anket başarıyla silindi!",
      SUCCESS_CREATE_POLL: "Anket başarıyla oluşturuldu!",
      SUCCESS_UPDATE_POLL: "Anket başarıyla güncellendi!",
      SUCCESS_INVITE_USER: "Anket davet linki başarıyla gönderildi!",
      SUCCESS_INVITE_USERS: "Anket davet linkleri başarıyla gönderildi!",
      SUCCESS_FILE_UPLOAD: "Dosya başarıyla gönderildi!",
      SUCCESS_CREATE_INTERVIEW: "Mülakat başarıyla oluşturuldu!",
      SUCCESS_UPDATE_INTERVIEW: "Mülakat başarıyla güncellendi!",
      SUCCESS_DELETE_INTERVIEW: "Mülakat başarıyla silindi!",
      SUCCESS_CREATE_JOBPOSTING: "İş ilanı başarıyla oluşturuldu!",
      SUCCESS_UPDATE_JOBPOSTING: "İş ilanı başarıyla güncellendi!",
      SUCCESS_DELETE_JOBPOSTING: "İş ilanı başarıyla silindi!",
      SUCCESS_COPY_JOBPOSTING: "İş ilanı başarıyla kopyalandı!",
      SUCCESS_MAKE_PASSIVE_JOBPOSTING:
        "İş ilanı başarıyla pasif hale getirildi.",
      SUCCESS_MAKE_ACTIVE_JOBPOSTING:
        "İş ilanı başarıyla aktif hale getirildi.",
      SUCCESS_ADD_CANDIDATE: "Aday başarıyla iş ilanına davet edildi.",
      SUCCESS_UPDATE_CANDIDATE: "Aday başarıyla güncellendi!",
      SUCCESS_INVITE_INTERVIEW: "Adaya mülakat linki gönderildi!",
      SUCCESS_REVOKE_INVITE_INTERVIEW:
        "Adaya gönderilen mülakat linki başarıyla iptal edildi!",
      SUCCESS_DELETE_CANDIDATE: "Aday başarıyla silindi!",
      SUCCESS_CV_ANALYSIS: "Adayın cv analizi başarıyla yapıldı!",
      SUCCESS_REMOVE_QUALIFICATION: "Aranılan özellik başarıyla silindi!",
      SUCCESS_ADD_QUALIFICATION: "Aranılan özellik başarıyla eklendi!",
      SUCCESS_ADD_QUESTION: "Yeni soru başarıyla eklendi!",
      SUCCESS_ADD_QUESTION_OPTION: "Soru şıkkı başarıyla eklendi!",
      SUCCESS_DELETE_QUESTION: "Soru başarıyla silindi!",
      SUCCESS_DELETE_QUESTION_OPTION: "Soru şıkkı başarıyla silindi!",
      SUCCESS_UPDATE_QUESTION: "Soru başarıyla güncellendi!",
      SUCCESS_SEND_MESSAGE: "Tebrikler, mesaj başarıyla gönderildi.",
      SUCCESS_RETRY_ANSWER: "Tebrikler, cevap yeniden oluşturuldu.",
      SUCCESS_GENERATE: "Doğrulama kodu başarıyla gönderildi!",
      SUCCESS_VERIFY: "Doğrulama başarılı!",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Lütfen e-posta adresinizi giriniz.",
      invalid: "Geçerli bir e-posta adresi giriniz.",
    },
    password: {
      required: "Lütfen şifrenizi giriniz.",
      min: "Şifre en az 6 karakter olmalıdır.",
      match: "Şifreleriniz birbiriyle aynı olmalıdır.",
    },
    identity: {
      required: "Lütfen tc kimlik numaranızı giriniz.",
      length: "Tc kimlik numarası 11 haneden oluşmalıdır.",
    },
    credential: {
      required: "Lütfen e-posta adresinizi ve şifrenizi giriniz.",
      credential: "Geçersiz bir e-posta adresi veya şifre girdiniz.",
    },
  },
  validators_error: {
    title: "Hata!",
    required: "Lütfen boş bırakmayınız.",
    typeError: "Lütfen uygun tipte veri giriniz.",
    email: "Geçerli bir e-posta adresi giriniz.",
    sameEmails: "Bu e-posta adresini daha önce girdiniz.",
    minlength: " karakterden az olamaz.",
    maxlength: " karakterden fazla olamaz.",
    zeroValue: "Değer 0'dan az olamaz.",
    phoneNumberFull: "Lütfen telefon numaranızın tamamını giriniz.",
    requiredCode: "Lütfen doğrulama kodunu boş bırakmayınız.",
    requiredDesc: "Lütfen açıklamayı boş bırakmayınız.",
    requiredMin3Features: "Lütfen en az 3 özellik giriniz.",
    codeInvalid: "Lütfen 6 haneli doğrulama kodunu giriniz.",
    min18age: "18 yaşından küçük olamaz.",
    startDateBiggerEndDate: "Bitiş tarihi, başlangıç tarihinden erken olamaz.",
    max2MBFileByte: "Dosya boyutu 2MB'ı geçemez.",
    requiredCV: "Lütfen CV yükleyiniz.",
    requiredFullXlsx: "Lütfen dolu bir xlsx dosyası yükleyiniz.",
    requiredTemplateXlsx:
      "Geçersiz dosya formatı! Lütfen xlsx uzantılı şablonu doldurup yükleyiniz.",
    max5MBFileByte: "Dosya boyutu 5MB'ı geçemez.",
    invalidFileType: "Geçersiz dosya formatı.",
    onlyPDFandDOCX: "Sadece PDF veya DOCX dosyaları yüklenebilir.",
    requiredFileName: "Dosya adı alınamadı. Lütfen tekrar deneyin.",
    addCvCandidate: "Lütfen {index}. sıradaki adayın CV'sini ekleyiniz.",
    min3Qualification: "Özellik sayısı 3'ten az olamaz.",
    max20Qualification: "Özellik sayısı 20'den fazla olamaz.",
    pleaseWaitForCV:
      "Lütfen CV dosyası yükleme işleminin bitmesini bekleyiniz.",
    addRequiredEmailCandidate:
      "Lütfen {index}. sıradaki aday için geçerli bir e-posta adresi giriniz.",
    existCandidateEmail:
      "Lütfen {index}. sıradaki aday için farklı bir e-posta adresi giriniz (E-posta zaten mevcut).",
    requiredCandidateInfo:
      "Lütfen {index}. sıradaki adayın tüm bilgilerini doğru şekilde doldurun.",
  },
};
