import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICreateInterviewRepository } from "@/domain/interviewer/create-interview/repository/ICreateInterviewRepository";
import CreateInterviewService from "../service/CreateInterviewService";
import { CreateInterviewRequestDto } from "@/domain/interviewer/create-interview/dto/CreateInterviewRequestDto";

const createInterviewService = new CreateInterviewService();

export class CreateInterviewRepository implements ICreateInterviewRepository {
  async createInterview(
    request: CreateInterviewRequestDto
  ): Promise<Result<any>> {
    try {
      return await createInterviewService
        .create(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.CREATE_INTERVIEW))
      );
    }
  }
}
