import { BaseDomain } from "@/core/base/BaseDomain";

export class ChangeTenantEstateModel extends BaseDomain {
  jwt?: string;
  tenantEstateId: number;
  constructor(tenantEstateId: number, jwt?: string) {
    super();
    this.tenantEstateId = tenantEstateId;
    this.jwt = jwt;
  }
}
