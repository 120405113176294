import { BaseDomain } from "@/core/base/BaseDomain";
import { VoiceResponse } from "@/domain/pollster/model/voice/VoiceResponse";

export class PollMessageListModel extends BaseDomain {
  poll: {
    id?: number;
    owner?: string;
    text?: string;
    isLiked?: string;
    createdAt?: Date;
    video?: VoiceResponse;
    voice?: VoiceResponse;
  };
  
  constructor(
    id?: number,
    owner?: string,
    text?: string,
    isLiked?: string,
    createdAt?: Date,
  ) {
    super();
    this.poll = {
      id,
      owner,
      text,
      isLiked,
      createdAt,
    };
  }
}
