import { BaseDomain } from "@/core/base/BaseDomain";

export class AddQualificationModel extends BaseDomain {
  id?: number;
  text?: string;
  ok?: boolean;

  constructor(
    id?: number,
    text?: string,
    ok?: boolean, 
  ) {
    super();
    this.id = id;
    this.text = text;
    this.ok = ok;
  }
}
