import { BaseDTO } from "@/core/base/BaseDTO";

export class InterviewInviteRequestDto extends BaseDTO {
  data: {
    interview?: number;
    candidate?: number;
    expirationDate?: Date;
  }
  locale?: string;

  constructor(
    interview?: number,
    candidate?: number,
    expirationDate?: Date,
    locale?: string,
  ) {
    super();
    this.data = {
      interview,
      candidate,
      expirationDate,
    }
    this.locale = locale;
  }

  toQueryString(): string {
    const queryString = `?locale=${this.locale}`;

    return queryString;
  }
  
}
