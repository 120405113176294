import { BaseDTO } from "@/core/base/BaseDTO";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";

export class TenantUsersSessionListRequestDto extends BaseDTO {
  pagination: PaginationRequestDto;
  query?: string;
  id: number

  constructor(id: number, pagination: PaginationRequestDto, query?: string,
  ) {
    super();
    this.id = id;
    this.pagination = pagination;
    this.query = query;
  }

  toQueryString(): string {
    let queryString = `?pagination[page]=${this.pagination.pagination.page}&pagination[pageSize]=${this.pagination.pagination.pageSize}`;

    if (this.query) {
      queryString += `&query=${this.query}`;
    }

    return queryString;
  }
}
