import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import PollMessageListService from "../service/PollMessageListService";
import { IPollMessageListRepository } from "@/domain/pollster/poll-statistic/poll-message-list/repository/IPollMessageListRepository";
import { PollMessageListRequestDto } from "@/domain/pollster/poll-statistic/poll-message-list/dto/PollMessageListRequestDto";
import { PollMessageListResponseDto } from "@/domain/pollster/poll-statistic/poll-message-list/dto/PollMessageListResponseDto";

const pollMessageListService = new PollMessageListService();

export class PollMessageListRepository implements IPollMessageListRepository {
  async getPollMessageList(
    request: PollMessageListRequestDto
  ): Promise<Result<PollMessageListResponseDto>> {
    try {
      return await pollMessageListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<PollMessageListResponseDto>(response.getValue());
          } else {
            return Result.fail<PollMessageListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<PollMessageListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.STATISTIC.POLL_MESSAGE_LIST))
      );
    }
  }
}
