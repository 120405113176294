import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { DeleteCandidateModel } from "@/domain/interviewer/delete-candidate/model/DeleteCandidateModel";
import { DeleteCandidateRequestDto } from "@/domain/interviewer/delete-candidate/dto/DeleteCandidateRequestDto";

export class DeleteCandidateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeleteCandidateModel> {
    try {
        const deleteCandidateModel: DeleteCandidateModel = {
          ok: dto.ok,
        };
        return Result.ok(deleteCandidateModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_CANDIDATE))
      );
    }
  }

  mapToDTO(domain: DeleteCandidateModel): Result<DeleteCandidateRequestDto> {
    try {
      const deleteCandidateRequest: DeleteCandidateRequestDto = {
        id: domain.id,
      };

      return Result.ok(deleteCandidateRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_CANDIDATE))
      );
    }
  }
}
