import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AddQuestionOptionService from "../service/AddQuestionOptionService";
import { IAddQuestionOptionRepository } from "@/domain/interviewer/update-interview/addQuestionOption/repository/IAddQuestionOptionRepository";
import { AddQuestionOptionRequestDto } from "@/domain/interviewer/update-interview/addQuestionOption/dto/AddQuestionOptionRequestDto";

const addQuestionOptionService = new AddQuestionOptionService();

export class AddQuestionOptionRepository implements IAddQuestionOptionRepository {
  async addQuestionOption(
    request: AddQuestionOptionRequestDto
  ): Promise<Result<any>> {
    try {
      return await addQuestionOptionService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.ADD_INTERVIEW_QUESTION_OPTION))
      );
    }
  }
}
