import { BaseDTO } from "@/core/base/BaseDTO";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";

export class StaffListRequestDto extends BaseDTO {
  pagination: PaginationRequestDto;
  
  constructor(
    pagination: PaginationRequestDto,
  ) {
    super();
    this.pagination = pagination;
  }

  toQueryString(): string {
    let queryString = `?pagination[page]=${this.pagination.pagination.page}&pagination[pageSize]=${this.pagination.pagination.pageSize}`;

    return queryString;
  }
}
