import { BaseDomain } from "@/core/base/BaseDomain";

export class GenerateOTPModel extends BaseDomain {
  phoneNumber?: string;
  countryCode?: number;
  expireTime?: Date;
  remainingAttempts?: number;
  locale?: string;

  constructor(
    phoneNumber?: string,
    countryCode?: number,
    expireTime?: Date,
    remainingAttempts?: number,
    locale?: string,
  ) {
    super();
    this.phoneNumber = phoneNumber;
    this.countryCode = countryCode;
    this.expireTime = expireTime;
    this.remainingAttempts = remainingAttempts;
    this.locale = locale;
  }
}
