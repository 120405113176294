import { BaseDomain } from "@/core/base/BaseDomain";

export class CreateJobPostingModel extends BaseDomain {
  title?: string;
  description?: string;
  jobPostingQualifications?: string[];
  startDate?: Date;
  endDate?: Date;
  ok?: boolean;

  constructor(
    title?: string,
    description?: string,
    jobPostingQualifications?: string[],
    startDate?: Date,
    endDate?: Date,
    ok?: boolean,
  ) {
    super();
    this.title = title;
    this.description = description;
    this.jobPostingQualifications = jobPostingQualifications;
    this.startDate = startDate;
    this.endDate = endDate;
    this.ok = ok;
  }
}
