import { BaseDTO } from "@/core/base/BaseDTO";

export class UpdateUserRequestDto extends BaseDTO {
  data: {
    name?: string;
    surname?: string;
    email?: string;
    tenantName?: string;
  };

  constructor(
    name?: string,
    surname?: string,
    email?: string,
    tenantName?: string
  ) {
    super();
    this.data = {
      name,
      surname,
      email,
      tenantName,
    };
  }
}
