export const SERVICE = {
  LOGIN: "LoginService",
  RESET_PASSWORD: "ResetPasswordService",
  FORGOT_PASSWORD: "ForgotPasswordService",
  GENERATE_OTP: "GenerateOTPService",
  VERIFY_OTP: "VerifyOTPService",
  COUNTRY_LIST: "CountryListService",
  UPDATE_USER: "UpdateUserService",
  ABOUT_USER: "AboutUserService",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateService",
  SEND_MESSAGE: "SendMessageService",
  RETRY_ANSWER: "RetryAnswerService",
  SESSION_LIST: "SessionListService",
  TENANT_USERS_SESSION_LIST: "TenantUsersSessionListService",
  FILE_UPLOAD: "FileUploadService",

  STAFF: {
    STAFF_LIST: "StaffListService",
    ADD_STAFF: "AddStaffService",
    DELETE_STAFF: "DeleteStaffService",
    INVITE_STAFF: "InviteStaffService",
    ACCEPT_INVITE: "AcceptInviteService",
  },
  PRODUCT: {
    PRODUCT_LIST: "ProductListService",
    BUY_PRODUCT: "BuyProductService",
    UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingService",
    GET_PRODUCT: "GetProductService",
    GET_PURCHASED_PRODUCT: "GetPurchasedProductService",
  },
  STATISTIC: "StatisticService",
  POLLSTER: {
    CREATE_POLL: "CreatePollService",
    POLL_LIST: "PollListService",
    STATISTIC: {
      POLL_LIST: "StatisticPollListService",
      POLL_MESSAGE_LIST: "StatisticPollMessageListService",
      POLL_RESPONSE_LIST: "StatisticPollResponseListService",
      POLL_SESSION_DETAIL: "StatisticPollSessionDetailService",
    },
    POLL_DETAIL: "PollDetailService",
    DELETE_POLL: "DeletePollService",
    UPDATE_POLL: "UpdatePollService",
    INVITE_USER: "InviteUserService",
  },
  INTERVIEW: {
    CREATE_INTERVIEW: "CreateJobPostingService",
    CREATE_JOBPOSTING: "CreateJobPostingService",
    JOBPOSTING_LIST: "JobPostingListService",
    INTERVIEW_LIST: "InterviewListService",
    CANDIDATE_LIST: "CandidateListService",
    CANDIDATE_ACTION_LIST: "CandidateActionListService",
    JOBPOSTING_DETAIL: "JobPostingDetailService",
    INTERVIEW_DETAIL: "InterviewDetailService",
    CANDIDATE_DETAIL: "CandidateDetailService",
    DELETE_JOBPOSTING: "DeleteJobPostingService",
    DELETE_INTERVIEW: "DeleteInterviewService",
    UPDATE_JOBPOSTING: "UpdateJobPostingService",
    UPDATE_JOBPOSTING_STATUS: "UpdateJobPostingStatusService",
    UPDATE_INTERVIEW: "UpdateInterviewService",
    ADD_CANDIDATE: "AddCandidateService",
    DELETE_CANDIDATE: "DeleteCandidateService",
    UPDATE_CANDIDATE: "UpdateCandidateService",
    GET_MULTI_CANDIDATE_TEMPLATE: "GetMultipleCandidateTemplateService",
    ADD_QUALIFICATION: "AddQualificationService",
    REMOVE_QUALIFICATION: "RemoveQualificationService",
    ADD_INTERVIEW_QUESTION: "AddQuestionService",
    ADD_INTERVIEW_QUESTION_OPTION: "AddQuestionOptionService",
    UPDATE_INTERVIEW_QUESTION: "UpdateQuestionService",
    DELETE_INTERVIEW_QUESTION: "DeleteQuestionService",
    DELETE_INTERVIEW_QUESTION_OPTION: "DeleteQuestionOptionService",
    INTERVIEW_COUNTS: "InterviewCountService",
    INTERVIEW_INVITE: "InterviewInviteService",
    REVOKE_INTERVIEW_INVITE: "RevokeInterviewInviteService",
    CANDIDATE_LIST_BY_INTERVIEW: "CandidateListByInterviewService",
    JOBPOSTING_DETAIL_BY_INVITE: "JobPostingDetailByInviteKeyService",
    CANDIDATE_CV_ANALYSIS: "CandidateCvAnalysisService",
    CANDIDATE_INTERVIEW_RESPONSES: "CandidateInterviewResponsesService",
    CANDIDATE_INTERVIEW_LOGS: "CandidateInterviewLogService",
  }
};
