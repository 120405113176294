import { BaseDomain } from "@/core/base/BaseDomain";

export class PollResponseListModel extends BaseDomain {
  id?: number;
  questionText?: string;
  questionType?: string;
  surveyQuestionOptions?: { optionText: string }[];
  surveyQuestionResponse?: string
  
  constructor(
    id?: number,
    questionText?: string,
    questionType?: string,
    surveyQuestionOptions?: { optionText: string }[],
    surveyQuestionResponse?: string
  ) {
    super();
    this.id = id;
    this.questionText = questionText;
    this.questionType = questionType;
    if (surveyQuestionOptions)
      this.surveyQuestionOptions = surveyQuestionOptions;
    else this.surveyQuestionOptions = [];
    this.surveyQuestionResponse = surveyQuestionResponse;
  }
}
