import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { IDeletePollRepository } from "@/domain/pollster/delete-poll/repository/IDeletePollRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import DeletePollService from "../service/DeletePollService";
import { DeletePollRequestDto } from "@/domain/pollster/delete-poll/dto/DeletePollRequestDto";

const deletePollService = new DeletePollService();

export class DeletePollRepository implements IDeletePollRepository {
  async deletePoll(request: DeletePollRequestDto): Promise<Result<any>> {
    try {
      return await deletePollService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>((error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.DELETE_POLL)));
    }
  }
}
