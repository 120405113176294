import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { CreateInterviewModel } from "@/domain/interviewer/create-interview/model/CreateInterviewModel";
import { CreateInterviewRequestDto } from "@/domain/interviewer/create-interview/dto/CreateInterviewRequestDto";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";

export class CreateInterviewMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<CreateInterviewModel> {
    try {
      const createInterviewModel: CreateInterviewModel = {
        ok: dto.ok,
      };

      return Result.ok(createInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CREATE_INTERVIEW))
      );
    }
  }

  mapToDTO(domain: CreateInterviewModel): Result<CreateInterviewRequestDto> {
    try {
      let updatedInterviewSequences: InterviewSequenceModel[] = [];
      if (domain.interviewSequences) {
        updatedInterviewSequences = domain.interviewSequences.map(
          (sequence) => {
            return new InterviewSequenceModel(
              sequence.interview ?? null,
              sequence.sequence
            );
          }
        );
      }

      const createInterviewRequest = new CreateInterviewRequestDto(
        domain.jobPosting,
        domain.title,
        domain.description,
        domain.interviewQuestions,
        updatedInterviewSequences
      );

      return Result.ok(createInterviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.CREATE_INTERVIEW))
      );
    }
  }
}
