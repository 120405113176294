import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import CandidateListService from "../service/CandidateListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICandidateListRepository } from "@/domain/interviewer/candidate-list/repository/ICandidateListRepository";
import { CandidateListRequestDto } from "@/domain/interviewer/candidate-list/dto/CandidateListRequestDto";
import { CandidateListResponseDto } from "@/domain/interviewer/candidate-list/dto/CandidateListResponseDto";

const candidateListService = new CandidateListService();

export class CandidateListRepository implements ICandidateListRepository {
  async candidateList(
    request: CandidateListRequestDto
  ): Promise<Result<CandidateListResponseDto>> {
    try {
      return await candidateListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<CandidateListResponseDto>(response.getValue());
          } else {
            return Result.fail<CandidateListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<CandidateListResponseDto>(
            (error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(
          new UnknownCause(REPOSITORY.INTERVIEW.CANDIDATE_LIST)
        )
      );
    }
  }
}
