import { BaseDTO } from "@/core/base/BaseDTO";

export class SendMessageRequestDto extends BaseDTO {
  data: {
    text?: string;
  };

  constructor(text?: string) {
    super();
    this.data = {
      text,
    };
  }
}
