import { TenantUsersSessionListRepository } from "../repository/TenantUsersSessionListRepository";
import { Result } from "@/core/utility/ResultType";
import { TenantUsersSessionListMapper } from "../mapper/TenantUsersSessionListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ITenantUsersSessionListUseCase } from "@/domain/hotel-management/tenant-users-session-list/usecase/ITenantUsersSessionListService";
import { TenantUsersSessionListModel } from "@/domain/hotel-management/tenant-users-session-list/model/TenantUsersSessionListModel";
import { ITenantUsersSessionListRepository } from "@/domain/hotel-management/tenant-users-session-list/repository/ITenantUsersSessionListRepository";

export class TenantUsersSessionListUseCase implements ITenantUsersSessionListUseCase {
  tenantUsersSessionListRepository: ITenantUsersSessionListRepository = new TenantUsersSessionListRepository();
  tenantUsersSessionListMapper = new TenantUsersSessionListMapper();

  constructor() {
  }

  async execute(model: TenantUsersSessionListModel): Promise<Result<TenantUsersSessionListModel>> {
    try {
      const dto = this.tenantUsersSessionListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<TenantUsersSessionListModel>(dto.getError());
      } else {
        const response = await this.tenantUsersSessionListRepository.tenantUsersSessionList(dto);

        if (response.isSuccess) {
          const domain = this.tenantUsersSessionListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<TenantUsersSessionListModel>(domain.getValue());
        } else {
          return Result.fail<TenantUsersSessionListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<TenantUsersSessionListModel>(
        new UseCaseException(new UnknownCause(USECASE.TENANT_USERS_SESSION_LIST))
      );
    }
  }
}
