
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IRemoveQualificationUseCase } from "@/domain/interviewer/qualification/remove-qualification/usecase/IRemoveQualificationUseCase";
import { RemoveQualificationRepository } from "../repository/RemoveQualificationRepository";
import { RemoveQualificationMapper } from "../mapper/RemoveQualificationMapper";
import { IRemoveQualificationRepository } from "@/domain/interviewer/qualification/remove-qualification/repository/IRemoveQualificationRepository";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";

export class RemoveQualificationUseCase implements IRemoveQualificationUseCase {
  removeQualificationRepository : IRemoveQualificationRepository = new RemoveQualificationRepository();
  removeQualificationMapper = new RemoveQualificationMapper();

  constructor() {
  }

  async execute(model: RemoveQualificationModel): Promise<Result<RemoveQualificationModel>> {
    try {
      const dto = this.removeQualificationMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<RemoveQualificationModel>(dto.getError());
      } else {
        const response = await this.removeQualificationRepository.removeQualification(dto);

        if (response.isSuccess) {
          const domain = this.removeQualificationMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<RemoveQualificationModel>(domain.getValue());
        } else {
          return Result.fail<RemoveQualificationModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<RemoveQualificationModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.REMOVE_QUALIFICATION))
      );
    }
  }
}
