
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { InterviewInviteMapper } from "../mapper/InterviewInviteMapper";
import { InterviewInviteRepository } from "../repository/InterviewInviteRepository";
import { IInterviewInviteUseCase } from "@/domain/interviewer/interview-invite/usecase/IInterviewInviteUsecase";
import { IInterviewInviteRepository } from "@/domain/interviewer/interview-invite/repository/IInterviewInviteRepository";
import { InterviewInviteModel } from "@/domain/interviewer/interview-invite/model/InterviewInviteModel";

export class InterviewInviteUsecase implements IInterviewInviteUseCase {
  createInterviewRepository : IInterviewInviteRepository = new InterviewInviteRepository();
  createInterviewMapper = new InterviewInviteMapper();

  constructor() {
  }

  async execute(model: InterviewInviteModel): Promise<Result<InterviewInviteModel>> {
    try {
      const dto = this.createInterviewMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<InterviewInviteModel>(dto.getError());
      } else {
        const response = await this.createInterviewRepository.interviewInvite(dto);

        if (response.isSuccess) {
          const domain = this.createInterviewMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<InterviewInviteModel>(domain.getValue());
        } else {
          return Result.fail<InterviewInviteModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<InterviewInviteModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.INTERVIEW_INVITE))
      );
    }
  }
}
