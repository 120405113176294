import UpdateProductModelSettingRepository from "../repository/UpdateProductModelSettingRepository";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { UserManager } from "@/infrastructure/helper/UserManager";
import { IUpdateProductModelSettingUseCase } from "@/domain/product/update-product-model-setting/usecase/IUpdateProductModelSettingUsecase";
import { UpdateProductModelSettingMapper } from "../mapper/UpdateProductModelSettingMapper";
import { UpdateProductModelSettingModel } from "@/domain/product/update-product-model-setting/model/UpdateProductModelSettingModel";
import { UpdateProductModelSettingResponseDto } from "@/domain/product/update-product-model-setting/dto/UpdateProductModelSettingResponseDto";
import { IUpdateProductModelSettingRepository } from "@/domain/product/update-product-model-setting/repository/IUpdateProductModelSettingRepository";

export class UpdateProductModelSettingUseCase implements IUpdateProductModelSettingUseCase {
  updateProductModelSettingRepository : IUpdateProductModelSettingRepository = new UpdateProductModelSettingRepository();
  updateProductModelSettingMapper = new UpdateProductModelSettingMapper();
  userManager = new UserManager();

  constructor(
  ) {
  }

  async execute(
    model: UpdateProductModelSettingModel
  ): Promise<Result<UpdateProductModelSettingModel>> {
    try {
      const dto = this.updateProductModelSettingMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateProductModelSettingModel>(dto.getError());
      } else {
        const response =
          await this.updateProductModelSettingRepository.updateProductModelSetting(dto);

        if (response.isSuccess) {
          const value: UpdateProductModelSettingResponseDto = response.getValue();

          const updateProductModelSettingModel =
            this.updateProductModelSettingMapper.mapToDomain(value);
          return Result.ok<UpdateProductModelSettingModel>(
            updateProductModelSettingModel.getValue()
          );
        } else {
          return Result.fail<UpdateProductModelSettingModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateProductModelSettingModel>(
        new UseCaseException(new UnknownCause(USECASE.PRODUCT.UPDATE_PRODUCT_MODEL_SETTING))
      );
    }
  }
}
