import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { DeletePollRequestDto } from "@/domain/pollster/delete-poll/dto/DeletePollRequestDto";
import { DeletePollModel } from "@/domain/pollster/delete-poll/model/DeletePollModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";

export class DeletePollMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeletePollModel> {
    try {
        const deletePollModel: DeletePollModel = {
          ok: dto.ok,
        };
        return Result.ok(deletePollModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.DELETE_POLL))
      );
    }
  }

  mapToDTO(domain: DeletePollModel): Result<DeletePollRequestDto> {
    try {
      const deletePollRequest: DeletePollRequestDto = {
        id: domain.id,
      };

      return Result.ok(deletePollRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.DELETE_POLL))
      );
    }
  }
}
