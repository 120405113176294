import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { DeleteQuestionOptionModel } from "@/domain/interviewer/update-interview/deleteQuestionOption/model/DeleteQuestionOptionModel";
import { DeleteQuestionOptionRequestDto } from "@/domain/interviewer/update-interview/deleteQuestionOption/dto/DeleteQuestionOptionRequestDto";

export class DeleteQuestionOptionMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeleteQuestionOptionModel> {
    try {
        const deleteQuestionOptionModel: DeleteQuestionOptionModel = {
          ok: dto.ok,
        };
        return Result.ok(deleteQuestionOptionModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_INTERVIEW_QUESTION_OPTION))
      );
    }
  }

  mapToDTO(domain: DeleteQuestionOptionModel): Result<DeleteQuestionOptionRequestDto> {
    try {
      const deleteQuestionOptionRequest: DeleteQuestionOptionRequestDto = {
        id: domain.id,
      };

      return Result.ok(deleteQuestionOptionRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.DELETE_INTERVIEW_QUESTION_OPTION))
      );
    }
  }
}
