import { BaseDomain } from "@/core/base/BaseDomain";

export class AcceptInviteModel extends BaseDomain {
  token?: string;
  ok?: boolean;

  constructor(
    token?: string,
    ok?: boolean
  ) {
    super();
    this.token = token;
    this.ok = ok;
  }
}
