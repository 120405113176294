import { BaseDomain } from "@/core/base/BaseDomain";

export class UpdateJobPostingStatusModel extends BaseDomain {
  id?: number;
  isActive?: boolean;
  ok?: boolean;

  constructor(
    id?: number,
    isActive?: boolean,
    ok?: boolean,
  ) {
    super();
    this.id = id;
    this.isActive = isActive;
    this.ok = ok;
  }
}
