import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IInterviewInviteService } from "@/domain/interviewer/interview-invite/service/IInterviewInviteService";
import { InterviewInviteRequestDto } from "@/domain/interviewer/interview-invite/dto/InterviewInviteRequestDto";
import { NotValidEmailCause } from "@/core/cause/NotValidEmailCause";
import { ValidationException } from "@/core/exception/ValidationException";

export default class InterviewInviteService implements IInterviewInviteService {
  async invite(
    request: InterviewInviteRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .post(API_ROUTE.INTERVIEW_INVITE + request.toQueryString(), request)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch((e) => {
          if(e.response.data.error.status == 503) {
            return Result.fail<any>(
              new ValidationException(
                new NotValidEmailCause(
                  i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INVITED_INVALID_EMAIL)
                )
              )
            );
          }
          console.log(2)
          return Result.fail<any>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INTERVIEW_INVITE)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.INTERVIEW_INVITE))
      );
    }
  }
}
