import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdatePollModel } from "@/domain/pollster/update-poll/model/UpdatePollModel";
import { UpdatePollRequestDto } from "@/domain/pollster/update-poll/dto/UpdatePollRequestDto";

export class UpdatePollMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdatePollModel> {
    try {
        const updatePollModel: UpdatePollModel = {
          ok: dto.ok,
        };
        return Result.ok(updatePollModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.UPDATE_POLL))
      );
    }
  }

  mapToDTO(domain: UpdatePollModel): Result<UpdatePollRequestDto> {
    try {
      const pollRequest: UpdatePollRequestDto = {
        id: domain.id,
        data: {
          completed: domain.completed,
          endDate: domain.endDate
        }
      };

      return Result.ok(pollRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.UPDATE_POLL))
      );
    }
  }
}
