
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { CandidateInterviewLogsMapper } from "../mapper/CandidateInterviewLogsMapper";
import { CandidateInterviewLogsRepository } from "../repository/CandidateInterviewLogsRepository";
import { ICandidateInterviewLogsUseCase } from "@/domain/interviewer/candidate-interview-logs/usecase/ICandidateInterviewLogsUsecase";
import { ICandidateInterviewLogsRepository } from "@/domain/interviewer/candidate-interview-logs/repository/ICandidateInterviewLogsRepository";
import { CandidateInterviewLogsModel } from "@/domain/interviewer/candidate-interview-logs/model/CandidateInterviewLogsModel";

export class CandidateInterviewLogsUsecase implements ICandidateInterviewLogsUseCase {
  candidateCvAnalysisRepository : ICandidateInterviewLogsRepository = new CandidateInterviewLogsRepository();
  candidateCvAnalysisMapper = new CandidateInterviewLogsMapper();

  constructor() {
  }

  async execute(model: CandidateInterviewLogsModel): Promise<Result<CandidateInterviewLogsModel>> {

    try {
      const dto = this.candidateCvAnalysisMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<CandidateInterviewLogsModel>(dto.getError());
      } else {
        const response = await this.candidateCvAnalysisRepository.candidateInterviewLogs(dto);

        if (response.isSuccess) {
          const domain = this.candidateCvAnalysisMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CandidateInterviewLogsModel>(domain.getValue());
        } else {
          return Result.fail<CandidateInterviewLogsModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CandidateInterviewLogsModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CANDIDATE_INTERVIEW_LOGS))
      );
    }
  }
}
